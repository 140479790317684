import React from 'react';
import { v4 as uuid } from 'uuid';
import CommonContext, { ApiRoutes } from '../Common';
import {
    FlexBetweenRow,
    FlexCenterRow,
    FlexColumnStart,
    FlexStartRow,
    FormDivider,
    FormGroupColumn,
    FormLabel,
    FormValidated,
    GroupedRow,
    onReactSelectChanged,
    SmallButton,
    SmallOutlineButton,
    SubHeading,
    toasty,
} from '../common/forms/FormElements';
import { Button, FormGroup, Modal, ModalHeader } from 'reactstrap';
import { util } from '../Util';
import { Address } from '../address/Address';
import { OrganizationContact } from '../organization/Organization';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import OrganizationContactForm from '../organization/OrganizationContactForm';
import AddressFormNew from '../address/AddressFormNew';

export default class JobOverrideModal extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        
        this.addressFormRef = React.createRef();
        this.contactFormRef = React.createRef();

        this.state = {
            jobOverride: props.jobOverride,
            formValidated: false,
            loading: true,
            errors: {},
            validationMessage: '',
            show: false,
            showContactsForm: false,
            contactFormIsReadOnly: false,
            allowContactTypeEdit: false,
            selectedContact: null,
            saveDisabled: false,
            addJobDayOverrideFormValidated: false,
            job: props.jobOverride.job,

            weeks: [],
            usCounties: [],
            addressEditType: null, //from object const
            selectedAddressType: '',
            addressFormTitle: '',
            showTimesheetOverview: false,
            companyContactsOptions: [],
            tableRequired: {},
            meetingLocationRequired: {},
            jobLocationRequired: {},

            //Property on the job to update with the newly created/edited contact.
            //jobOverride.requestedById or formenId.
            selectedContactIdProp: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onRemoveOverrideButtonClick = this.onRemoveOverrideButtonClick.bind(this);
    }

    componentDidMount = () => this.populateState();

    getCompanyContactsOptions = async (
        companyId,
        constrainContactTypes,
        requestedById,
        foremanId
    ) => {
        const companyContacts = await util.fetch.js(
            ApiRoutes.company.contacts(companyId)
        );

        //TODO: Define and filter server-side.
        const descs = OrganizationContactForm.filteredContactTypes;

        const contactsFiltered = companyContacts.filter((c) => {
            if (!c.isActive) return false;

            //Automatically include if we're not constraining contact types.
            //Include if the contact was already set as the "requested by" or foreman person.
            const include =
                !constrainContactTypes ||
                c.id === requestedById ||
                c.id === foremanId ||
                (c.locationContactTypes &&
                    c.locationContactTypes.some((t) => {
                        return descs.some((d) => t.description === d);
                    }));

            return include;
        });

        const contactsMapped = contactsFiltered.map((x) => ({
            label: x.contactName,
            value: x.id,
        }));

        //const contactsMapped = [...companyContacts.filter((c) => c.isActive).map(x => { return { label: x.contactName, value: x.id } })];
        return contactsMapped;
    };

    populateState = async () => {
        let { showTimesheetOverview, job } = { ...this.state };
        const { constrainContactTypes } = this.props;

        let [
            usCounties,
            tenantSettings,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.USCounties()),
            util.fetch.js(ApiRoutes.tenant.settings()),
        ]);

        const companyContactsOptions = await this.getCompanyContactsOptions(
            job.contract.companyId,
            constrainContactTypes,
            job.requestedById,
            job.foremenId
        );

        this.setState((state) => {
            return {
                loading: false,
                job: { ...job },
                showTimesheetOverview: showTimesheetOverview,
                companyContactsOptions: [...companyContactsOptions],
                usCounties,
                tenantSettings
            };
        });
    };

    onSaveAddress = (address) => {
        let {
            jobOverride,
            selectedAddressType,
        } = this.state;

        jobOverride[selectedAddressType] = { ...address };

        if (address.duplicate) {
            //if the other is blank fill it in.
            if (selectedAddressType === 'jobLocation' && !jobOverride.meetingLocation) {
                let mAddress = util.object.clone(address);
                mAddress.id = uuid();

                jobOverride.meetingLocation = mAddress;
            }

            if (selectedAddressType === 'meetingLocation' && !jobOverride.jobLocation) {
                let jAddress = util.object.clone(address);
                jAddress.id = uuid();

                jobOverride.jobLocation = jAddress;
            }
        }

        this.setState({
            jobOverride: jobOverride,
        });
    };

    onAddLocation = (type) => {
        let { jobOverride } = this.state;
        let selectedAddress = new Address(),
            addressFormTitle = `New ${
                type === 'meetingLocation' ? 'Meeting' : 'Job'
            } Location`;
        this.setState({
            selectedAddressType: type,
            addressFormTitle: addressFormTitle,
        });

        //dont show duplicate location if ones filled out already
        let show = true;
        if (jobOverride.meetingLocation || jobOverride.jobLocation) show = false;

        this.addressFormRef.current.open(
            selectedAddress,
            addressFormTitle,
            show,
            `Same as ${
                type === 'meetingLocation' ? 'Job Location' : 'Meeting Location'
            }`
        );
    };

    onEditLocation = (type) => {
        let { jobOverride } = { ...this.state };
        let selectedAddress = { ...jobOverride[type] },
            addressFormTitle = `Edit ${
                type === 'meetingLocation' ? 'Meeting' : 'Job'
            } Location`;

        this.setState({
            selectedAddress: selectedAddress,
            selectedAddressType: type,
            addressFormTitle: addressFormTitle,
        });
        this.addressFormRef.current.open(selectedAddress, addressFormTitle);
    };

    //#region CONTACTS
    onAddContact = (prop) => {
        const { job } = { ...this.state };
        const { setFormOpened } = this.props;

        setFormOpened(true);
        this.contactFormRef.current.resetForm();
        this.setState({
            selectedContact: {
                ...new OrganizationContact(),
                id: null /*new record*/,
                companyId:
                    job.contract
                        .companyId /*this will be used to attach the new contact to the location.*/,
            },
            selectedContactIdProp: prop,
            showContactsForm: true,
            allowContactTypeEdit: true,
        });
    };

    onEditContact = async (prop) => {
        const { job } = { ...this.state };
        const selectedContact = await util.fetch.js(
            ApiRoutes.locationContact.byId(job[prop])
        );
        this.contactFormRef.current.resetForm();

        await this.setState({
            selectedContact: { ...selectedContact },
            showContactsForm: true,
            selectedContactIdProp: prop,
            allowContactTypeEdit: false,
        });
    };

    onOrganizationContactSaved = async () => {
        await this.setState({ contactFormIsReadOnly: true });

        const { selectedContact, selectedContactIdProp, job } = {
            ...this.state,
        };
        const isNew = parseInt(selectedContact.id ?? 0) <= 0;
        const result = await util.fetch.andGetResponse(
            !!isNew ? util.fetch.types.post : util.fetch.types.put,
            !!isNew
                ? ApiRoutes.locationContact.create()
                : ApiRoutes.locationContact.update(selectedContact.id),
            { ...selectedContact },
            'Error Saving Contact'
        );

        if (!!result) {
            toasty.success('Contact saved.');

            //Make the contact the selected one - add returns just the Id, update returns object with id property.
            job[selectedContactIdProp] = result.id ?? result;

            //Refresh the contacts.
            const newOptions = await this.getCompanyContactsOptions(
                job.contract.companyId
            );

            //Update state.
            this.setState({
                companyContactsOptions: [...newOptions],
                job: { ...job },
                showContactsForm: false,
                saving: false,
            });
        } else {
            this.setState({ saving: false });
        }

        await this.setState({ contactFormIsReadOnly: false });
    };
    //#endregion

    getCountyName = (location) => {
        if (!location || !location.usCountyId) {
            return '';
        }

        const { usCounties } = this.state;

        const county = usCounties.find((c) => c.id == location.usCountyId);

        return Boolean(county) ? `(${county.name})` : '';
    };

    onChange = async function (e) {
        if (!e || !e.target || !e.target.name) {
            return;
        }

        const changedPropertyName = e.target.name.split('.').pop();

        let { jobOverride } = this.state;

        jobOverride[changedPropertyName] = e.target.value;

        this.setState({ jobOverride });
    };

    onSelectChanged = onReactSelectChanged;

    resetForm = () => this.setState({ formValidated: false });

    onClose = () => {
        const { onClose, setFormOpened } = this.props;

        this.resetForm();
        setFormOpened(false);
        
        onClose();
    };

    onRemoveOverrideButtonClick = () => {
        const { onClose, setFormOpened, jobOverride } = this.props;

        setFormOpened(false);
        
        if (jobOverride.id) {
            onClose({ ...jobOverride, isDeleted: true });
        } else {
            onClose();
        }
    };

    handleSubmit = () => {
        let { jobOverride } = this.state;
        const { onClose, setFormOpened } = this.props;
        
        //Revert uuid
        if (jobOverride.meetingLocation) {
            jobOverride.meetingLocation.id =
                jobOverride.meetingLocation.id.constructor === String
                ? null
                : jobOverride.meetingLocation.id;
        }

        if (jobOverride.jobLocation) {
            jobOverride.jobLocation.id =
                jobOverride.jobLocation.id.constructor === String
                ? null
                : jobOverride.jobLocation.id;
        }

        this.resetForm();
        setFormOpened(false);
        
        onClose(jobOverride);
    };

    getDayHasAssignment = () => {
        const { job, jobOverride } = this.state;
        const indexName = `${jobOverride.overrideDay.toLowerCase()}HasAssignment`;

        return !!job.resources?.find(resource => resource[indexName]);
    };

    render() {
        const {
            jobOverride,
            addJobDayOverrideFormValidated,
            companyContactsOptions,
            showContactsForm,
            contactFormIsReadOnly,
            allowContactTypeEdit,
            selectedContact,
            job,
        } = this.state;

        const { open, edit, isReadOnly } = this.props;

        return (
            <Modal
                className="full-screen-modal"
                backdrop="static"
                keyboard={false}
                isOpen={open}
            >
                {Boolean(open) && (
                    <>
                        <ModalHeader>
                            <FlexBetweenRow className="w-100">
                                <span>
                                    {isReadOnly ? 'View' : 'Edit'} Details for
                                    {' '}

                                    {jobOverride.overrideDay}
                                </span>

                                <span
                                    className="fa fa-times fa-lg cursor-pointer"
                                    onClick={this.onClose}
                                ></span>
                            </FlexBetweenRow>
                        </ModalHeader>
                        
                        <FormValidated
                            className="modal-body add-self-dispatch-time-form d-flex flex-column h-100 min-height-0"
                            id="addJobDayOverrideForm"
                            name="addJobDayOverrideForm"
                            ref={this.addTimeFormRef}
                            onSubmit={this.handleSubmit}
                            setIsValidated={(validated) =>
                                this.setState({ addJobDayOverrideFormValidated: validated })
                            }
                            isValidated={addJobDayOverrideFormValidated}
                        >
                                <SubHeading>Setup</SubHeading>
                                <GroupedRow>
                                    <FormGroupColumn>
                                    <FormGroup>
                                            <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                                <label
                                                    htmlFor="requestedBy"
                                                    className="control-label"
                                                >
                                                    Requested By
                                                </label>
                                                {!job.hasAssignments && !isReadOnly && (
                                                    <SmallButton
                                                        color="outline-primary"
                                                        className="site-button-small mr-2 ml-auto"
                                                        onClick={() =>
                                                            this.onAddContact(
                                                                'requestedById'
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-plus-circle fa-md mr-2" />
                                                        Add Contact
                                                    </SmallButton>
                                                )}
                                            </div>
                                            <ValidatedSelect
                                                id="requestedBy"
                                                name="jobOverride.requestedById"
                                                options={companyContactsOptions}
                                                value={
                                                    (companyContactsOptions ?? []).find(
                                                        (x) =>
                                                            x.value ===
                                                            jobOverride.requestedById
                                                    ) ?? ''
                                                }
                                                onChange={this.onSelectChanged}
                                                isDisabled={isReadOnly}
                                            />
                                            {!!jobOverride?.requestedById && (
                                                <div className="w-100 text-right pr-3">
                                                    <small
                                                        className="site-link"
                                                        onClick={() =>
                                                            this.onEditContact(
                                                                'requestedById'
                                                            )
                                                        }
                                                    >
                                                        Edit Requestor Details
                                                    </small>
                                                </div>
                                            )}
                                        </FormGroup>
                                    </FormGroupColumn>
                                    <FormGroupColumn>
                                        <FormGroup>
                                            <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                                <label
                                                    htmlFor="foremenId"
                                                    className="control-label"
                                                >
                                                    Foreman
                                                </label>
                                                {!job.hasAssignments && !isReadOnly && (
                                                    <SmallButton
                                                        color="outline-primary"
                                                        className="site-button-small mr-2 ml-auto"
                                                        onClick={() =>
                                                            this.onAddContact(
                                                                'foremenId'
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-plus-circle fa-md mr-2" />
                                                        Add Contact
                                                    </SmallButton>
                                                )}
                                            </div>
                                            <ValidatedSelect
                                                id="foremen"
                                                name="jobOverride.foremenId"
                                                options={companyContactsOptions}
                                                value={
                                                    (companyContactsOptions ?? []).find(
                                                        (x) => x.value === jobOverride.foremenId
                                                    ) ?? ''
                                                }
                                                onChange={this.onSelectChanged}
                                                isDisabled={isReadOnly}
                                            />
                                            {!!jobOverride?.foremenId && (
                                                <div className="w-100 text-right pr-3">
                                                    <small
                                                        className="site-link"
                                                        onClick={() =>
                                                            this.onEditContact(
                                                                'foremenId'
                                                            )
                                                        }
                                                    >
                                                        Edit Foreman Details
                                                    </small>
                                                </div>
                                            )}
                                        </FormGroup>
                                    </FormGroupColumn>
                                </GroupedRow>
                                <FormDivider />
                                <GroupedRow>
                                    <FormGroupColumn>
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="startTime"
                                                text="Start Time"
                                            />
                                            <input
                                                id="startTime"
                                                type="time"
                                                className="form-control"
                                                name="jobOverride.startTime"
                                                defaultValue={jobOverride.startTime ?? ''}
                                                onChange={this.onChange}
                                                readOnly={isReadOnly}
                                            />
                                        </FormGroup>
                                    </FormGroupColumn>
                                    {job.contract.workOrderTypeId !== 3 && (
                                        <FormGroupColumn>
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="workOrderNumber"
                                                    text="Work Order"
                                                />
                                                <input
                                                    id="workOrderNumber"
                                                    name="jobOverride.workOrderNumber"
                                                    className="form-control"
                                                    defaultValue={
                                                        jobOverride.workOrderNumber ?? ''
                                                    }
                                                    onChange={this.onChange}
                                                    readOnly={isReadOnly}
                                                />
                                            </FormGroup>
                                        </FormGroupColumn>
                                    )}
                                </GroupedRow>

                                <GroupedRow>
                                    <FormGroupColumn>
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="meetingLocation"
                                                text="Meeting Location"
                                            />
                                            {!!jobOverride.meetingLocation ? (
                                                <FlexStartRow>
                                                    <SmallOutlineButton
                                                        onClick={() => {
                                                            this.onEditLocation(
                                                                'meetingLocation'
                                                            );
                                                        }}
                                                        text="Edit"
                                                        disabled={isReadOnly}
                                                    />
                                                    <input
                                                        name="schedule"
                                                        readOnly
                                                        className="form-control-plaintext"
                                                        value={`${
                                                            jobOverride.meetingLocation.address1
                                                        } ${
                                                            jobOverride.meetingLocation.city
                                                        }, ${
                                                            jobOverride.meetingLocation.state
                                                        }, ${
                                                            jobOverride.meetingLocation.zip
                                                        } ${this.getCountyName(
                                                            jobOverride.meetingLocation
                                                        )}`}
                                                    />
                                                </FlexStartRow>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    size="sm"
                                                    disabled={isReadOnly}
                                                    onClick={() =>
                                                        this.onAddLocation(
                                                            'meetingLocation'
                                                        )
                                                    }
                                                >
                                                    Add Meeting Location
                                                </Button>
                                            )}
                                        </FormGroup>
                                    </FormGroupColumn>
                                    <FormGroupColumn>
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="jobLocation"
                                                text="Job Location"
                                            />
                                            {!!jobOverride.jobLocation ? (
                                                <FlexStartRow>
                                                    <SmallOutlineButton
                                                        onClick={() => {
                                                            this.onEditLocation(
                                                                'jobLocation'
                                                            );
                                                        }}
                                                        text="Edit"
                                                        disabled={isReadOnly}
                                                    />
                                                    <input
                                                        name="schedule"
                                                        readOnly
                                                        className="form-control-plaintext"
                                                        value={`${
                                                            jobOverride.jobLocation.address1
                                                        } ${jobOverride.jobLocation.city}, ${
                                                            jobOverride.jobLocation.state
                                                        }, ${
                                                            jobOverride.jobLocation.zip
                                                        } ${this.getCountyName(
                                                            jobOverride.jobLocation
                                                        )}`}
                                                    />
                                                </FlexStartRow>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    size="sm"
                                                    disabled={isReadOnly}
                                                    onClick={() => {
                                                        this.onAddLocation(
                                                            'jobLocation'
                                                        );
                                                    }}
                                                >
                                                    Add Job Location
                                                </Button>
                                            )}
                                        </FormGroup>
                                    </FormGroupColumn>
                                </GroupedRow>

                                <GroupedRow>
                                    <div className="col-md-auto col-sm-12 col-lg-auto col-xl-auto w-100">
                                        <FormGroup className="w-100">
                                            <FormLabel htmlFor="notes" text="Notes" />
                                            <textarea
                                                id="notes"
                                                name="jobOverride.notes"
                                                className="form-control"
                                                defaultValue={jobOverride.notes ?? ''}
                                                onChange={this.onChange}
                                                placeholder="Enter notes to field staff here."
                                                type="text"
                                                maxLength="500"
                                                rows="5"
                                                readOnly={isReadOnly}
                                            />
                                        </FormGroup>
                                    </div>
                                </GroupedRow>

                                {this.getDayHasAssignment() && (
                                    <GroupedRow>
                                        <div className="col-md-auto col-sm-12 col-lg-auto col-xl-auto w-100">
                                            <FormGroup className="w-100">
                                                <FormLabel htmlFor="notes" text="Reason for this change?" required />
                                                <textarea
                                                    id="notes"
                                                    name="jobOverride.reason"
                                                    className="form-control"
                                                    defaultValue={jobOverride.reason ?? ''}
                                                    onChange={this.onChange}
                                                    placeholder="Enter a reason for making this change. Assigned crew will be notified that changes have been made."
                                                    type="text"
                                                    maxLength="500"
                                                    rows="5"
                                                    readOnly={isReadOnly}
                                                    required
                                                />
                                                <small className="invalid-feedback text-danger">
                                                    A reason is required.
                                                </small>
                                            </FormGroup>
                                        </div>
                                    </GroupedRow>
                                )}

                                <FlexColumnStart className="w-100">
                                    {isReadOnly ? (
                                        <FlexCenterRow className="w-100">
                                            <Button
                                                type="button"
                                                className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center"
                                                color="primary"
                                                outline
                                            >
                                                Close
                                            </Button>
                                        </FlexCenterRow>
                                    ) : (
                                        <>
                                            <FlexCenterRow className="w-100">
                                                <Button
                                                    type="submit"
                                                    name="addJobDayOverrideForm"
                                                    className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center"
                                                    color="primary"
                                                >
                                                    Save Override
                                                </Button>
                                            </FlexCenterRow>
                                            
                                            {Boolean(jobOverride.jobId) && (
                                                <FlexCenterRow className="w-100">
                                                    <Button
                                                        title="Click to remove the override for this day"
                                                        color="outline-danger"
                                                        className="mt-3 cursor-pointer w-100"
                                                        onClick={this.onRemoveOverrideButtonClick}
                                                    >
                                                        <i className="fa fa-trash mr-1"></i>
                                                        Delete Override
                                                    </Button>
                                                </FlexCenterRow>
                                            )}
                                        </>
                                    )}
                                </FlexColumnStart>
                        </FormValidated>

                        <AddressFormNew
                            id="jobAddressForm"
                            ref={this.addressFormRef}
                            contractUsesPrevailingWage={job.contract.prevailingWage}
                            onSaveCallback={(address) =>
                                this.onSaveAddress(address)
                            }
                        />
                        <OrganizationContactForm
                            ref={this.contactFormRef}
                            show={showContactsForm}
                            readOnly={contactFormIsReadOnly}
                            allowContactTypeEdit={allowContactTypeEdit}
                            contact={selectedContact}
                            onClose={() => {
                                this.setState({ showContactsForm: false });
                            }}
                            onChange={(e) => {
                                let { selectedContact } = { ...this.state };
                                selectedContact[e.target.name] = e.target.value;
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onCheckedChanged={(e) => {
                                let { selectedContact } = { ...this.state };
                                selectedContact[e.target.name] = e.target.checked;
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onContactTypeChanged={(items) => {
                                let { selectedContact } = this.state;
                                selectedContact.contactTypes = items.map(
                                    (x) => x.value
                                );
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onSaveCallback={this.onOrganizationContactSaved}
                        />
                    </>
                )}
            </Modal>
        );
    }
}

