import CommonContext from '../Common';
import SlideForm from '../common/forms/SlideForm';
import React from 'react';
import { Col, Container, Row, FormGroup } from 'reactstrap';
import { util } from '../Util';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FormLabel } from '../common/forms/FormElements';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { NotAuthorizedPage } from '../status/StatusCodes';

export default class BillingRateHistoryForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            errors: {},
            formValidated: false,
            validationMessage: '',
            isValidated: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {

        this.setState(state => {
            return {
                loading: false
            }
        });
    }

    resetForm = () => this.setState({ formValidated: false, errors: {} });

    onSubmit = e => {
        this.context.setFormOpened(false);
        this.props.onSaveCallback();
    }

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onRemove = e => {
        this.context.setFormOpened(false);
        this.props.onRemoveCallback();
    }

    render() {

        const { tenantSettings } = ((this.context ?? {}).tenant ?? {});

        if (!tenantSettings.billingRatesEnabled) return <NotAuthorizedPage />

        if (!this.props.entity) return '';
        let entity = this.props.entity,
            name = (this.props.entityName ? `${this.props.entityName}.` : '')
        
        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id={"billingRateHistoryForm"}
                formIcon={faMinusCircle}
                formTitle={"Rate History"}
                ref={this.formRef}
                setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                isValidated={this.state.formValidated}
                
                onClose={this.onClose}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={this.state.validationMessage}
                showDelete={this.props.showRemove === true}
                onDelete={this.onRemove}
            >
                <>
                    {!!this.props.showChargeType == true &&
                        <FormGroup>
                        <FormLabel
                                className="ml-3"
                                htmlFor="chargeTypesDropdown"
                                text="Charge"
                                required={!entity.chargeTypeId} />
                            <ValidatedSelect
                                className="ml-3 w-auto"
                                isClearable={false}
                                id={`${name}chargeTypeId`}
                                name={`${name}chargeTypeId`}
                                required={true}
                                value={this.props.chargeTypes.find(i => i.value == entity.chargeTypeId) ?? ''}
                                options={this.props.chargeTypes ?? []}
                                onChange={this.props.onSelectChange}
                                getOptionLabel={o => o.label}
                                getOptionValue={o => o.value}
                                validationMessage="A charge is required."
                            />
                        </FormGroup>
                    }
                    <Container fluid className="p-2">
                        <Row>
                            <Col className="ml-0 mr-0">
                                <table className="table table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            {(this.props.showChargeType ?? {}) == true &&
                                                <th style={{ width: '15%' }}>Charge Type</th>
                                            }
                                            <th style={{ width: '15%' }}>Rate</th>
                                            <th style={{ width: '15%' }}>Overtime Rate</th>
                                            <th style={{ width: '15%' }}>Alternative Rate</th>
                                            <th style={{ width: '20%' }}>Effective Date</th>
                                            <th style={{ width: '20%' }}>Expiration Date</th>                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!(entity ?? []).length
                                            ? <tr><td colSpan="9" className="text-center">No rates found.</td></tr>
                                            : entity.map((rate, i) => {
                                                return (
                                                    <tr key={rate.billingRateId}>
                                                        {(this.props.showChargeType ?? {}) == true &&
                                                            <td>
                                                                {rate.chargeTypeName}
                                                            </td>
                                                        }
                                                        <td>
                                                            {!util.isEmpty(rate.billingRate.rate) && util.number.formatCurrency(rate.billingRate.rate)}
                                                        </td>
                                                        <td>
                                                            {!util.isEmpty(rate.billingRate.otRate) && util.number.formatCurrency(rate.billingRate.otRate)}
                                                        </td>
                                                        <td>
                                                            {!util.isEmpty(rate.billingRate.altRate) && util.number.formatCurrency(rate.billingRate.altRate)}
                                                        </td>
                                                        <td>
                                                            {!util.isEmpty(rate.billingRate.effectiveDate) && util.date.getShort(rate.billingRate.effectiveDate)}
                                                        </td>
                                                        <td>
                                                            {!util.isEmpty(rate.billingRate.expirationDate) && util.date.getShort(rate.billingRate.expirationDate)}
                                                        </td>                                                        
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Container>
                </>
            </SlideForm>
        );
    }
}