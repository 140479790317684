import React from 'react';
import {
    createDataSource, createGridOptions, DataGrid,
    indexCellRenderer, TextFilterDefaults, LinkCellRenderer,
    ButtonCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { withRouter } from 'react-router-dom';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import authService from '../api-authorization/AuthorizeService';
import * as moment from 'moment';

const ActiveStatuses = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
];

class ContractTemplateIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            selectedRow: null,
        }
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = () => this.setState = (state, callback) => { return };

    async populateState() {

        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            const { userPermissions } = await getTenantUserProfile();
            const canEditContractTemplates = userPermissions.includes(ApplicationPermissions.contractTemplate_edit);

            let gridOptions = createGridOptions(this);

            gridOptions.components = {
                statusFilter: DataGridSelectFilter,
                statusFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                buttonRenderer: ButtonCellRenderer
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 80,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellRenderer: indexCellRenderer
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    sortable: true,
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: !!canEditContractTemplates ? 'nameRenderer' : null,
                    cellRendererParams: {
                        clicked: (id) => {
                            this.props.history.push(`${AppNavPaths.ContractTemplate}/${id}`);
                        },
                        nameField: 'description',
                        idField: 'id',
                        title: 'View this Contract Template'
                    },
                },
                {
                    colId: 'CreatedOn',
                    headerName: 'Date Added',
                    field: 'createdOn',
                    cellRenderer: (params) => {
                        if (!!params.value && moment(params.value).isValid()) {
                            return moment(params.value).format('M/D/YYYY');
                        } else {
                            return ' - ';
                        }
                    },
                    maxWidth: 150
                },
                {
                    colId: 'Active',
                    sortable: true,
                    headerName: 'Status',
                    field: 'active',
                    filter: 'statusFilter',
                    filterParams: {
                        suppressMenu: true,
                        floatingFilter: true,
                        labelText: 'Filter by Status',
                        options: [...ActiveStatuses],
                        optionsLabel: 'label',
                        optionsValue: 'value',
                    },
                    floatingFilterComponent: 'statusFloatingFilter',
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                        options: [...ActiveStatuses],
                        optionsLabel: 'label',
                        optionsValue: 'value'
                    },
                    cellRenderer: (params) => {
                        if (params.value === true) {
                            return (
                                <span class="text-success">Active</span>
                            );
                        } else {
                            return (
                                <span class="text-danger">Inactive</span>
                            );
                        }
                    },
                    maxWidth: 150
                },
            ];

            let dataSource = createDataSource(ApiRoutes.contractTemplate.search(), gridOptions);

            this.setState({
                loading: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            });
        }
    }

    onEdit = (id) => {
        this.props.history.push(`${AppNavPaths.ContractTemplate}/${id}`);
    }

    render() {

        let { rowData, gridOptions } = { ...this.state };
        if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)


        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faAddressCard} className="mr-2 text-muted" />
                    <span>Contract Templates</span>
                </PageHeading>
                <DataGridToolbar
                    hideExcelButton={ true }
                    entity="contract_template"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={() => this.props.history.push(`${AppNavPaths.ContractTemplateNew}`)}
                    addLabel="Add Contract Template"
                />
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(ContractTemplateIndex);