export class EmployeeCompliance {
    id = 0;

    effectiveDate = new Date();
    expirationDate = null;

    notes = '';

    employeeId = 0;
    employee = null;

    complianceId = 0;
    compliance = null;
}
