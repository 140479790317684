import React from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { ApiRoutes } from '../Common';
import DateRange from '../common/forms/DateRange';
import CompanySubSelects from '../chargeView/CompanySubSelects';
import { util } from '../Util';
import Select from 'react-select';

export default class HoursReport extends React.Component {
    constructor(props) {
        super(props);

        this.dateSet = this.dateSet.bind(this);
        this.companiesLoading = this.companiesLoading.bind(this);
        this.subcontractorsChanged = this.subcontractorsChanged.bind(this);
        this.generateButtonClick = this.generateButtonClick.bind(this);

        this.state = {
            areCompaniesLoading: true,
            startDate: null,
            endDate: null,
            hideLargeDataMessage: true,
            selectedCompanies: null,
            selectedSubcontractors: null,
            selectedReportTemplateId: 1,
            templates: [
                { label: 'Standard', value: 1 },
                { label: 'Duke', value: 2 },
            ],
        };

        this.generateButton = null;
    }

    componentDidMount = () => {};
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    companiesLoading(isLoading) {
        this.setState({ areCompaniesLoading: isLoading });
    }

    checkDateRange() {
        const { startDate, endDate } = this.state;

        const daySpan =
            startDate && endDate
                ? Math.abs(moment(endDate).diff(moment(startDate), 'days'))
                : 0;

        const doHide = daySpan <= 30;

        this.setState({ hideLargeDataMessage: doHide });
    }

    dateSet(date, isStartDate) {
        if (isStartDate) {
            this.setState({ startDate: date }, this.checkDateRange);
        } else {
            this.setState({ endDate: date }, this.checkDateRange);
        }
    }

    async submitReport() {
        const {
            startDate,
            endDate,
            selectedCompanies,
            selectedSubcontractors,
            selectedReportTemplateId,
        } = this.state;

        try {
            const cIds = selectedCompanies
                ? selectedCompanies.map((x) => x.value)
                : null;
            const sIds = selectedSubcontractors
                ? selectedSubcontractors.map((x) => x.value)
                : null;
            const fileName = `HoursReport_${moment().format(
                'yyyyMMDD_Hmmss'
            )}.xlsx`;

            const data = {
                startDate: startDate,
                endDate: endDate,
                companyIds: cIds,
                subContractorIds: sIds,
                reportTemplateId: selectedReportTemplateId,
            };

            const url = ApiRoutes.chargeView.hoursReportExport();

            await util.fetch.downloadFile(url, data, fileName);
        } catch (err) {
            if (this.props.onGenerateError) this.props.onGenerateError(err);
        }
    }

    async generateButtonClick() {
        const g = this.props.onGenerateStatusChange;

        if (g) g(true);

        await this.submitReport();

        if (g) g(false);

        console.log('Submit report');
    }

    checkGenerateButton(button) {
        if (!this.generateButton) {
            this.generateButton = button;
            this.generateButton.addEventListener(
                'click',
                this.generateButtonClick
            );
            this.generateButton.disabled = this.state.areCompaniesLoading;
        }
    }

    subcontractorsChanged(subs) {
        this.setState({ selectedSubcontractors: subs });

        if (this.generateButton) {
            const l = subs ? subs.length : 0;
            this.generateButton.disabled = l === 0;
        }
    }

    render() {
        const c = this.props.generateRef.current;
        if (c) this.checkGenerateButton(c);

        const {
            areCompaniesLoading,
            hideLargeDataMessage,
            templates,
            selectedReportTemplateId,
        } = this.state;

        return (
            <>
                <div className="container">
                    <div className="row">
                        <Spinner
                            animation="border"
                            hidden={!areCompaniesLoading}
                        />

                        <div
                            className="alert alert-warning"
                            hidden={hideLargeDataMessage}
                        >
                            You are requesting a large amount of data (over 30
                            days). This operation could potentially take a long
                            time.
                        </div>
                    </div>
                    <div className="row">
                        <DateRange
                            onSetStartDate={(date) => this.dateSet(date, true)}
                            onSetEndDate={(date) => this.dateSet(date, false)}
                        />
                    </div>

                    <div className="row mt-4">
                        <CompanySubSelects
                            onLoading={this.companiesLoading}
                            onCompaniesChange={(cs) =>
                                this.setState({ selectedCompanies: cs })
                            }
                            onSubcontractorsChange={this.subcontractorsChanged}
                        />
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <label className="control-label">
                                Report Template
                            </label>
                            <Select
                                id="selectedReportTemplateId"
                                name="selectedReportTemplateId"
                                options={templates}
                                value={
                                    (templates ?? []).find(
                                        (x) =>
                                            x.value === selectedReportTemplateId
                                    ) ?? ''
                                }
                                onChange={(evt) => {
                                    let value = evt.value;
                                    this.setState({
                                        selectedReportTemplateId: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
