import React from 'react';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    LocalizationKeys as l,
} from './components/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMoneyCheck,
    faFileInvoice,
    faFileInvoiceDollar,
    faFileContract,
    faUser,
    faToolbox,
    faWarehouse,
    faPhoneVolume,
    faTasks,
    faAddressBook,
    faComments,
    faWaveSquare,
} from '@fortawesome/free-solid-svg-icons';
import {
    Row,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Container,
    NavLink,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { Can } from './components/Can';
import { faCalendar } from '@fortawesome/fontawesome-free-solid';
import { CircularProgress } from '@material-ui/core';
import authService from './components/api-authorization/AuthorizeService';
import { util } from './components/Util';
import { getTenantUserProfile } from './components/common/TenantUserProfile';

class Dashboard extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            notification: null,
        };
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        let hasSelfDispatchingAccess = false;
        let hasNonFlaggingAccess = false;
        //let hasMyTimesheetsAccess = false;
        //let hasBillingAccess = false;
        var notifications;

        if (!!isAuthenticated) {
            const [access, userProfile, strings] = await Promise.all([
                util.fetch.js(ApiRoutes.contract.userHasSelfDispatching()),
                getTenantUserProfile(),
                util.l10n.getStrings([l.billing]),
            ]);

            hasSelfDispatchingAccess =
                !!access.hasSelfDispatchingAccess &&
                !!userProfile.currentUser.canCreateSelfDispatchTimesheet;
            hasNonFlaggingAccess =
                !!access.hasNonFlaggingAccess &&
                !!userProfile.currentUser.canCreateNonFlagTimesheet;

            const [
                selfDispatchTimesheetNotifications,
                nonFlagTimesheetNotifications,
                selfDispatchTimesheetCount,
            ] = await Promise.all([
                !!hasSelfDispatchingAccess
                    ? util.fetch.js(
                          ApiRoutes.selfDispatchTimesheet.selfDispatchNotifications()
                      )
                    : { count: 0 },
                !!hasNonFlaggingAccess
                    ? util.fetch.js(
                          ApiRoutes.selfDispatchTimesheet.nonFlagNotifications()
                      )
                    : { count: 0 },

                !!userProfile.userTenant.tenantSettings
                    .enableSelfDispatchTimesheets
                    ? util.fetch.js(ApiRoutes.selfDispatchTimesheet.count())
                    : 0,
            ]);

            notifications = {
                selfDispatch: selfDispatchTimesheetNotifications.count,
                nonFlagging: nonFlagTimesheetNotifications.count,
            };

            this.setState({
                loading: false,
                hasSelfDispatchingAccess,
                hasNonFlaggingAccess,
                notifications,
                hasMyTimesheetsAccess: Boolean(selfDispatchTimesheetCount > 0),
                strings: strings,
                userProfile: userProfile,
            });
        }
    }

    render = () => {
        const {
            loading,
            hasSelfDispatchingAccess,
            hasNonFlaggingAccess,
            notifications,
            hasMyTimesheetsAccess,
            strings,
            userProfile,
        } = { ...this.state };
        const tenantSettings = this.context?.tenant?.tenantSettings;
        const timesheetNotificationCount =
            (notifications?.nonFlagging ?? 0) + notifications?.selfDispatch;

        const billing = strings ? strings[l.billing] : '';
        const hasIndiv = !!userProfile?.userPermissions?.includes(
            'payroll.viewindividual'
        );
        const hasEdit =
            !!userProfile?.userPermissions?.includes('payroll.edit');

        if (!!loading || !tenantSettings) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CircularProgress
                        color="secondary"
                        variant="indeterminate"
                    />
                </div>
            );
        }

        let areas = [
            {
                icon: faFileContract,
                url: AppNavPaths.Contracts,
                name: 'Contracts',
                permission: 'contract',
                action: 'view',
            },
            {
                iconStack: {
                    primary: 'fa-calendar-day',
                    secondary: 'fa-file-contract',
                },
                url: AppNavPaths.JobView,
                name: 'Daily Jobs View',
                permission: 'job_daily_view',
                action: 'view',
            },
            {
                iconStack: {
                    primary: 'fa-calendar-day',
                    secondary: 'fa-portrait',
                },
                url: AppNavPaths.EmployeeDailyRoster,
                name: 'Roster View',
                permission: 'employee_daily_roster',
                action: 'view',
            },
            {
                icon: faPhoneVolume,
                url: AppNavPaths.Dispatch,
                name: 'Dispatch',
                permission: 'dispatch',
                action: 'edit',
            },
            {
                icon: faUser,
                url: AppNavPaths.Employees,
                name: 'Employees',
                permission: 'employee',
                action: 'view',
            },
            {
                icon: faToolbox,
                url: AppNavPaths.Equipment,
                name: 'Equipment',
                permission: 'equipment',
                action: 'view',
            },
            {
                icon: faWarehouse,
                url: AppNavPaths.Organizations,
                name: 'Organizations',
                permission: 'company',
                action: 'view',
            },
            {
                icon: faCalendar,
                url: AppNavPaths.AssignmentCalendar,
                name: 'Calendar',
                permission: 'calendar',
                action: 'view',
            },
        ];

        //{ icon: faClock, url: AppNavPaths.TimeCharges, name: "My Time Charges", permission: "timesheet", action: "view" },
        !!tenantSettings.timesheetDashboardEnabled &&
            areas.push({
                icon: faWaveSquare,
                url: AppNavPaths.TimeSheetDashboard,
                name: 'Timesheet Dashboard',
                permission: 'timesheet_dashboard',
                action: 'view',
            });

        areas.push(
            ...[
                {
                    icon: faTasks,
                    url: AppNavPaths.TimesheetManagement,
                    name: 'Timesheet Management',
                    permission: 'dispatch_review',
                    action: 'view',
                },
                {
                    icon: faTasks,
                    url: AppNavPaths.ChargeView,
                    name: 'Charge View',
                    permission: 'charge_view',
                    action: 'view',
                },
                //{ icon: faBusinessTime, url: AppNavPaths.TimeOffRequests, name: "Time Off Requests", permission: "timeoff", action: "view" },
            ]
        );

        !!tenantSettings.contactScreenEnabled &&
            areas.push({
                icon: faAddressBook,
                url: AppNavPaths.Contacts,
                name: 'Contacts',
                permission: 'location_contact',
                action: 'view',
            });

        !!tenantSettings.contactActivitiesEnabled &&
            areas.push({
                icon: faComments,
                url: AppNavPaths.ContactActivities,
                name: 'Contact Activities',
                permission: 'contactactivity',
                action: 'view',
            });

        !!tenantSettings.payrollEnabled &&
            hasEdit &&
            areas.push({
                icon: faMoneyCheck,
                url: AppNavPaths.PayrollManagement,
                name: 'Payroll: Charges',
                permission: 'payroll',
                action: 'view',
            });

        //2022-10-10 - M. Nicol - Done to prevent multiple "Payroll: Pay Categories" tiles
        //from being displayed.  If the user has "viewinidividual" permissions, use that in the "Can" check
        //in the render method.  Otherwise, use "view".  If the user has neither, "view" will be used, and the
        //"Can" check will be false, resulting in the expected behavior of not showing the tile.
        //There may be a better way using more sophisticated CASL conditions like $in:
        //https://casl.js.org/v5/en/guide/conditions-in-depth

        if (!!tenantSettings.payrollEnabled) {
            const payCatUrl = hasIndiv
                ? AppNavPaths.PayrollIndividual
                : AppNavPaths.Payroll;
            const payCatAction = hasIndiv ? 'viewindividual' : 'view';

            areas.push({
                icon: faMoneyCheck,
                url: payCatUrl,
                name: 'Payroll: Pay Categories',
                permission: 'payroll',
                action: payCatAction,
            });
        }

        !!tenantSettings.billingEnabled &&
            areas.push({
                icon: faFileInvoice,
                url: AppNavPaths.Billing,
                name: billing,
                permission: 'billing',
                action: 'view',
            });

        if (
            !!tenantSettings.billingEnabled &&
            !!tenantSettings.invoicingEnabled
        ) {
            areas.push({
                icon: faComments,
                url: AppNavPaths.BillingIssues,
                name: 'Billing: Issues',
                permission: 'billing_issues',
                action: 'view',
            });

            areas.push({
                icon: faFileInvoice,
                url: AppNavPaths.BillingCharge,
                name: 'Billing: Charges',
                permission: 'billing',
                action: 'view',
            });

            areas.push({
                icon: faFileInvoice,
                url: AppNavPaths.BillingChargeCategory,
                name: 'Billing: Charge Categories',
                permission: 'billing',
                action: 'view',
            });

            areas.push({
                icon: faFileInvoice,
                url: AppNavPaths.InvoiceGeneration,
                name: 'Invoice Generation',
                permission: 'billing',
                action: 'view',
            });

            areas.push({
                icon: faFileInvoiceDollar,
                url: AppNavPaths.Invoice,
                name: 'Invoices',
                permission: 'invoice',
                action: 'view',
            });
        }

        !!hasSelfDispatchingAccess &&
            areas.push({
                iconStack: {
                    primary: 'fa-clock',
                    secondary: 'fa-plus-circle',
                    rounded: true,
                },
                url: AppNavPaths.SelfDispatchTimesheetNew,
                name: 'Self-Dispatch Timesheet',
                permission: 'selfdispatchtimesheet',
                action: 'create',
            });
        !!hasNonFlaggingAccess &&
            areas.push({
                iconStack: {
                    primary: 'fa-clock',
                    secondary: 'fa-plus-circle',
                    rounded: true,
                },
                url: AppNavPaths.NonFlaggingTimesheetNew,
                name: 'Non-Flagging Timesheet',
                permission: 'nonflaggingtimesheet',
                action: 'create',
            });
        (!!hasSelfDispatchingAccess ||
            !!hasNonFlaggingAccess ||
            !!hasMyTimesheetsAccess) &&
            areas.push({
                iconStack: {
                    primary: 'fa-clock',
                    count:
                        timesheetNotificationCount > 0
                            ? timesheetNotificationCount
                            : null,
                    rounded: true,
                },
                url: AppNavPaths.EmployeeTimesheets,
                name: 'My Timesheets',
                permission: 'timesheet',
                action: 'view',
            });

        return (
            <Container className="h-100 w-100 overflow-x-hidden overflow-y-auto background-white">
                <Row className="dashboard-row">
                    {areas.map((a) => (
                        <Can key={a.name} do={a.action} on={a.permission}>
                            <NavLink
                                className="dashboard-link"
                                tag={Link}
                                to={a.url}
                            >
                                <Card>
                                    <CardBody>
                                        <CardTitle className="d-flex flex-column align-items-center">
                                            {!a.iconStack && (
                                                <FontAwesomeIcon
                                                    className="text-muted"
                                                    size="4x"
                                                    icon={a.icon}
                                                />
                                            )}
                                            {!!a.iconStack && (
                                                <span className="fa-stack fa-2x text-muted">
                                                    <i
                                                        className={`fas ${a.iconStack.primary} fa-stack-2x`}
                                                        data-count={
                                                            a.iconStack?.count
                                                        }
                                                    ></i>
                                                    <i
                                                        className={`dashboard-stacked-icon fas ${
                                                            a.iconStack
                                                                .secondary
                                                        } fa-stack-1x ml-3 mt-3${
                                                            !!a.iconStack
                                                                ?.rounded
                                                                ? ' rounded'
                                                                : ''
                                                        }`}
                                                    ></i>
                                                </span>
                                            )}
                                        </CardTitle>
                                        <CardSubtitle className="d-flex flex-column align-items-center">
                                            <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">
                                                {a.name}
                                            </h4>
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </NavLink>
                        </Can>
                    ))}
                </Row>
            </Container>
        );
    };
}
export default withRouter(Dashboard);
