import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, {
    agGridConstants,
    ApiRoutes,
    AppNavPaths,
} from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { util } from '../Util';
import { withRouter } from 'react-router-dom';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';

class OrganizationIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });
    onViewOrganization = (id) =>
        this.props.history.push(`${AppNavPaths.Organization}/${id}`);

    populateState = async () => {
        var [usStates, companyTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
            util.fetch.js(ApiRoutes.typeAheads.companyTypes()),
        ]);

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                minWidth: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'CompanyName',
                sortable: true,
                headerName: 'Name',
                field: 'name',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onViewOrganization,
                    nameField: 'name',
                    idField: 'id',
                    title: 'View this Organization',
                },
            },
            {
                colId: 'Type',
                sortable: false,
                headerName: 'Types',
                field: 'typesFlat',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter by type',
                    options: companyTypes,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: companyTypes,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
            },
            {
                colId: 'Location.Address1',
                sortable: false,
                headerName: 'Address',
                field: 'address1',
                cellRenderer: (params) => {
                    if (!params.data) return '';
                    return `${params.data.address1}${
                        !!params.data.address2
                            ? ', ' + params.data.address2
                            : ''
                    }`;
                },
                filter: agGridConstants.columnFilterTypes.text,
                filterParams: TextFilterDefaults,
                floatingFilter: true,
            },
            {
                colId: 'Location.City',
                sortable: true,
                headerName: 'City',
                field: 'city',
                filter: agGridConstants.columnFilterTypes.text,
                filterParams: TextFilterDefaults,
                floatingFilter: true,
            },
            {
                flex: 0,
                width: 140,
                colId: 'Location.State',
                sortable: true,
                headerName: 'State',
                field: 'state',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter by State',
                    options: usStates,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: usStates,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
            },
            {
                flex: 0,
                width: 160,
                colId: 'Location.Zip',
                sortable: true,
                headerName: 'Zip',
                field: 'zip',
                filter: agGridConstants.columnFilterTypes.text,
                filterParams: TextFilterDefaults,
                floatingFilter: true,
            },
            {
                colId: 'UseInvoicing',
                sortable: false,
                headerName: 'Invoicing',
                field: 'useInvoicing',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter',
                    options: [
                        { label: 'Yes', value: 1 },
                        { label: 'No', value: 2 },
                    ],
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: [
                        { label: 'Yes', value: 1 },
                        { label: 'No', value: 2 },
                    ],
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                valueGetter: (row) => {
                    if (!!row.data) {
                        return row.data.useInvoicing ? 'Yes' : 'No';
                    }

                    return '';
                },
            },
            {
                colId: 'CompanyStatusId',
                sortable: true,
                headerName: 'Status',
                field: 'companyStatusId',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: {
                    labelText: 'Filter',
                    options: [
                        { label: 'Active', value: 1 },
                        { label: 'Inactive', value: 2 },
                    ],
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    options: [
                        { label: 'Active', value: 1 },
                        { label: 'Inactive', value: 2 },
                    ],
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                valueGetter: (row) => {
                    if (!!row.data) {
                        return row.data.companyStatusId == 1
                            ? 'Active'
                            : row.data.companyStatusId == 2
                            ? 'Inactive'
                            : '';
                    }

                    return '';
                },
            },
        ];

        let ds = createDataSource(ApiRoutes.company.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: ds,
        });
    };

    render() {
        if (!!this.state.loading)
            return <LinearProgress variant="indeterminate" color="secondary" />;
        let { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faWarehouse}
                        className="mr-2 text-muted"
                    />
                    <span>Organizations</span>
                </PageHeading>
                <DataGridToolbar
                    entity="company"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={() =>
                        this.props.history.push(
                            `${AppNavPaths.OrganizationNew}`
                        )
                    }
                    addLabel="Add Organization"
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    serverExport={{
                        apiPath: ApiRoutes.company.excelExport(),
                        filePrefix: 'OrganizationSearch',
                    }}
                    serverExportDisabled={
                        !!this.state.saving ||
                        !!this.state.loading ||
                        !!this.state.loadingData
                    }
                />
                <DataGrid
                    domLayout={'normal'}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                ></DataGrid>
            </PageWrap>
        );
    }
}
export default withRouter(OrganizationIndex);
