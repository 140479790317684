import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    EditLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes } from '../Common';
import { LinearProgress } from '@material-ui/core';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { util } from '../Util';
import { withRouter } from 'react-router-dom';
import EquipmentComplianceForm from './EquipmentComplianceForm';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { isComplianceAssociatedWithEquipmentType } from '../complianceType/ComplianceType';

class EquipmentComplianceIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.eqComplianceForm = React.createRef();

        this.state = {
            loading: true,
            roles: [],
            showEquipmentComplianceForm: false,
        };
        this.onAddEquipmentCompliance =
            this.onAddEquipmentCompliance.bind(this);
        this.onEditEquipmentCompliance =
            this.onEditEquipmentCompliance.bind(this);
        this.onEquipmentComplianceFormClosed =
            this.onEquipmentComplianceFormClosed.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    getEquipmentDisplayName = (equipmentComplianceParam) => {
        const { allKnownEquipment } = this.state;

        if (!equipmentComplianceParam.data) {
            return '';
        }

        const equipmentId = equipmentComplianceParam.data.equipmentId;

        const equipment = allKnownEquipment.find((e) => e.id == equipmentId);

        return equipment.displayName;
    };

    async populateState() {
        const { equipmentId } = { ...this.props };
        const [
            equipmentComplianceTypeaheads,
            equipmentComplianceTypes,
            allEquipment,
            allEquipmentTypes,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.equipmentComplianceTypes()),
            util.fetch.js(
                ApiRoutes.complianceTypes.allEquipmentComplianceTypes()
            ),
            util.fetch.js(ApiRoutes.equipment.all()),
            util.fetch.js(ApiRoutes.equipmentType.all()),
        ]);

        const allKnownEquipment = allEquipment.filter((e) =>
            equipmentComplianceTypes.some((ec) =>
                isComplianceAssociatedWithEquipmentType(ec, e.equipmentTypeId)
            )
        );

        allKnownEquipment.forEach((e) => {
            const equipmentType = allEquipmentTypes.find(
                (et) => et.id == e.equipmentTypeId
            );

            let displayName = `${e.description} (${equipmentType.description})`;

            if (
                allKnownEquipment.find((ke) => ke.displayName === displayName)
            ) {
                displayName += `, #${e.id}`;
            }

            e.displayName = displayName;
        });

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: EditLinkCellRenderer,
            getEquipmentDisplayName: this.getEquipmentDisplayName,
        };

        let equipmentComplianceFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Compliance Type',
            options: equipmentComplianceTypeaheads,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        let equipmentFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Equipment',
            options: allKnownEquipment,
            optionsLabel: 'displayName',
            optionsValue: 'id',
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'equipmentId',
                headerName: 'Equipment',
                sortable: false,
                field: 'equipmentId',
                filter: 'selectFilter',
                cellRenderer: this.getEquipmentDisplayName,
                filterParams: equipmentFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: equipmentFilterParams,
            },
            {
                colId: 'complianceTypeId',
                headerName: 'Compliance Type Name',
                sortable: true,
                field: 'complianceType.name',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: equipmentComplianceFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: equipmentComplianceFilterParams,
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    entity: 'equipmentcompliance',
                    clicked: this.onEditEquipmentCompliance,
                    idField: 'id',
                    nameField: 'complianceType.name',
                    title: 'View this Equipment Compliance',
                },
            },
            {
                colId: 'effectiveDate',
                sortable: true,
                headerName: 'Effective Date',
                field: 'effectiveDate',
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                valueFormatter: this.dateFormatter,
            },
            {
                colId: 'expirationDate',
                sortable: true,
                headerName: 'Expiration Date',
                field: 'expirationDate',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                valueFormatter: this.dateFormatter,
            },
            {
                colId: 'notes',
                sortable: true,
                headerName: 'Notes',
                field: 'notes',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
            },
        ];

        let dataSource = createDataSource(
            ApiRoutes.equipmentCompliances.search(),
            gridOptions
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            allKnownEquipment,
            equipmentId,
            equipmentComplianceTypes,
        });

        const { search } = { ...this.props.location };

        if (search) {
            const equipmentId = new URLSearchParams(search).get('equipmentId');

            if (equipmentId) {
                this.onEditEquipment(equipmentId);
            }
        }
    }

    toggleEquipmentComplianceForm = (show) =>
        this.setState(
            (state) => ((state.showEquipmentComplianceForm = show), state)
        );

    onAddEquipmentCompliance = () => {
        this.context.setFormOpened(true);
        this.eqComplianceForm.current.open();
    };

    onEditEquipment = (equipmentId) => {
        this.context.setFormOpened(true);
        this.eqComplianceForm.current.openEquipment(equipmentId);
    };

    onEditEquipmentCompliance = (id) => {
        this.context.setFormOpened(true);
        this.eqComplianceForm.current.openEquipmentCompliance(id);
    };

    onEquipmentComplianceFormClosed = () => {
        //doesnt fire the form closed hanlder in datagridtoolbar for some reason
        this.context.setFormOpened(false);
        this.state.gridOptions.refresh();
    };

    toggleComplianceForm = (show) =>
        this.setState(
            (state) => ((state.showEquipmentComplianceForm = show), state)
        );

    dateFormatter = (params) => util.date.getShort(params.value);

    render() {
        if (!!this.state.loading) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        }

        let {
            rowData,
            gridOptions,
            showEquipmentComplianceForm,
            equipmentComplianceTypes,
            allKnownEquipment,
        } = this.state;

        return (
            <>
                <DataGridToolbar
                    entity="equipmentcompliance"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddEquipmentCompliance}
                    addLabel="Add Equipment Compliance"
                ></DataGridToolbar>
                <DataGrid
                    domLayout={'normal'}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    onNameCellClicked={this.onEditEquipmentCompliance}
                    gridStatus={this.state.gridStatus}
                />
                <EquipmentComplianceForm
                    show={
                        showEquipmentComplianceForm /* controls form open state */
                    }
                    toggleShow={
                        this
                            .toggleEquipmentComplianceForm /* getter/setter for form open state */
                    }
                    ref={this.eqComplianceForm}
                    onClose={this.onEquipmentComplianceFormClosed}
                    allKnownEquipment={allKnownEquipment}
                    allEquipmentComplianceTypes={equipmentComplianceTypes}
                />
            </>
        );
    }
}

export default withRouter(EquipmentComplianceIndex);
