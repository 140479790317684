import React, { Fragment } from 'react';
import { QBIIChargeTypeGrid } from './QBIIChargeTypeGrid';
import { QBIIContractChargeTypeGrid } from './QBIIContractChargeTypeGrid';
import { QBIICompanyChargeTypeGrid } from './QBIICompanyChargeTypeGrid';
import {
    QuickBooksInventoryItemDTO
} from './QuickBooksInventoryItem'
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    onFieldChange,
    onReactSelectChanged,
    SubHeading,
    ToastMessage,
    ValidationErrorMessage
} from '../common/forms/FormElements';
import {
    Button,
    FormGroup,
    Input
} from 'reactstrap';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { Prompt, withRouter } from "react-router-dom";
import { isEqual } from 'lodash-es';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { toast } from 'react-toastify';
import {
    faSave,
    faFileInvoiceDollar,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class QuickBooksInventoryItemForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            originalData: new QuickBooksInventoryItemDTO(),
            inventoryItem: new QuickBooksInventoryItemDTO(),
            salesAccounts: [],
            loading: true,
            saving: false
        };
        this.handlers.change = this.handlers.change.bind(this);
        this.handlers.select = this.handlers.select.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {

        const id = this.props.match.params.id;

        var [salesAccnts, inventoryItem] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.quickBooksSalesAccounts()),
            (!!id ? util.fetch.js(ApiRoutes.quickbooks.edit(id)) : new QuickBooksInventoryItemDTO())
        ]);

        let originalData = util.object.clone(inventoryItem);

        this.setState(state => {
            return {
                originalData: originalData,
                inventoryItem: originalData,
                salesAccounts: salesAccnts.map(x => { return { label: x.name, value: x.id } }),
                loading: false,
                saving: false
            }
        });
    }

    onClearErrors = () => this.setState((state) => { return { errors: {} }; });
    setSaving = (b) => this.setState({ saving: b });
    handleSaveError = (err) => handleFormSaveError(this, err);

    handlers = {
        change: onFieldChange,
        select: onReactSelectChanged
    }

    onSubmit = e => {
        this.onClearErrors();
        this.setSaving(true);

        let cloned = util.object.clone(this.state.inventoryItem);

        this.props.location.pathname == AppNavPaths.QuickBooksInventoryItemNew ? this.create(cloned) : this.update(cloned);
    }

    update = async (inventoryItem) => {
        let response = await util.fetch.put(ApiRoutes.quickbooks.update(inventoryItem.id), inventoryItem)
            .catch(this.handleSaveError);

        if (response && parseInt(response.id ?? 0) >= 0) {

            this.setState({
                inventoryItem: response,
                originalData: response, /* also restore this to reset Prompt */
                saving: false,
                formValidated: false
            });

            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={'Inventory Item saved.'}
            />);
        }

        this.setSaving(false);
    }

    create = async (inventoryItem) => {
        inventoryItem.id = 0;

        let response = await util.fetch.post(ApiRoutes.quickbooks.create(), inventoryItem)
            .catch(this.handleSaveError);

        if (response && !isNaN(response.id)) {

            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`Saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.QuickBooksInventoryItem}/${response.id}`);
        }

        this.setSaving(false);
    }

    renderForm() {
        const {
            salesAccounts,
            inventoryItem,
            originalData,
            saving
        } = this.state;

        let newInventoryItem = ((parseInt(inventoryItem.id ?? 0)) <= 0);

        if (!inventoryItem) return '';

        return (
            <Fragment>
                <Prompt
                    when={!saving && !isEqual(originalData, inventoryItem)}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={"quickBooksInventoryItemForm"}
                    formHeadingIcon={faFileInvoiceDollar}
                    formHeading={newInventoryItem ? 'New Inventory Item' : 'Edit Inventory Item'}
                    formName={"quickBooksInventoryItemForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={this.state.formValidated}
                    saving={this.state.saving}
                    errors={this.state.errors}
                    loading={this.state.loading}
                >
                    <SubHeading first={true}>Details</SubHeading>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="name"
                                    text="Inventory Item Name"
                                    required={true} />
                                <Input id="name"
                                    name="inventoryItem.name"
                                    value={inventoryItem.name ?? ''}
                                    onChange={this.handlers.change}
                                    placeholder="Enter Name"
                                    type="text"
                                    required
                                />
                                <ValidationErrorMessage>Name is required.</ValidationErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="salesAccountId"
                                    text="Sales Account"
                                    required={true} />
                                <ValidatedSelect
                                    id="salesAccountId"
                                    name="inventoryItem.salesAccountId"
                                    required
                                    options={salesAccounts}
                                    value={(salesAccounts ?? []).find(s => s.value === inventoryItem.salesAccountId) ?? ''}
                                    onChange={this.handlers.select.bind(this)}
                                    validationMessage="A Sales Account selection is required."
                                />
                            </FormGroup>
                        </FormGroupColumn>
                    </GroupedRow>
                    {!!inventoryItem.id && <>
                        <SubHeading>Charge Types</SubHeading>
                        <GroupedRow>
                            <QBIIChargeTypeGrid chargeTypes={inventoryItem.chargeTypeBillingRates} />
                        </GroupedRow>
                        <SubHeading>Contract Charge Types</SubHeading>
                        <GroupedRow>
                            <QBIIContractChargeTypeGrid contractChargeTypes={inventoryItem.contractChargeTypeBillingRates} />
                        </GroupedRow>
                        <SubHeading>Company Charge Types</SubHeading>
                        <GroupedRow>
                            <QBIICompanyChargeTypeGrid companyChargeTypes={inventoryItem.companyChargeTypeBillingRates} />
                        </GroupedRow>
                        </>
                    }
                    <FlexCenterRow className="mb-3">
                        <Button
                            size="sm"
                            type="submit"
                            color="primary"
                            name="quickBooksInventoryItemForm">
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave} />
                            {!!newInventoryItem ? 'Save New Inventory Item' : 'Save'}
                        </Button>
                    </FlexCenterRow>
                </AppPageForm>
            </Fragment>
        );
    }

    render() {
        if (!!this.state.loading)
            return (
                <LinearProgress variant="indeterminate" color="secondary" />
            )
        return this.renderForm();
    }

}
export default withRouter(QuickBooksInventoryItemForm);