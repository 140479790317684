import React from 'react';
import { faCheckCircle, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { Badge, FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    ToastMessage,
    ValidationErrorMessage,
    FormCheckbox,
} from '../common/forms/FormElements';
import { JobAssignmentCancellationType } from './JobAssignmentCancellationType';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { toast } from 'react-toastify';
import { handleFormSaveError } from '../common/forms/ValidationError';

export class JobAssignmentCancellationTypeForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = Object.assign(
            {
                jobAssignmentCancellationType:
                    this.props.jobAssignmentCancellationType ??
                    new JobAssignmentCancellationType(),

                show: false,
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {}

    open = async (id) => {
        this.resetForm();
        this.setState({ show: true, loading: true });
        this.context.setFormOpened(true);

        const [jobAssignmentCancellationType] = await Promise.all([
            !!id
                ? util.fetch.js(
                      ApiRoutes.jobAssignmentCancellationType.byId(id)
                  )
                : new JobAssignmentCancellationType(),
        ]);

        this.setState({
            jobAssignmentCancellationType,
            loading: false,
        });
    };

    onChange = onFieldChange.bind(this);
    onSelectChange = onReactSelectChanged.bind(this);
    handleSaveError = handleFormSaveError.bind(this);

    onSubmit = async (e) => {
        const { jobAssignmentCancellationType } = this.state;

        //Clear any fluent api errors
        this.setState({ errors: {}, saving: true });

        //Is this POST or PUT?
        const url = jobAssignmentCancellationType.id
            ? ApiRoutes.jobAssignmentCancellationType.update()
            : ApiRoutes.jobAssignmentCancellationType.create();

        const response = await util.fetch.andGetResponse(
            util.fetch.types.post,
            url,
            jobAssignmentCancellationType,
            'Error Saving'
        );

        if (response) {
            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header={'Save Successful'}
                    message={`Job Assignment Cancellation Type [${jobAssignmentCancellationType.description}] saved.`}
                />
            );
            this.setState({ show: false, saving: false });
            this.context.setFormOpened(false);
            this.resetForm();
            this.context.setFormOpened(false);
            this.props.onSaveCallback(response);
        }
    };

    resetForm() {
        this.setState({ formValidated: false });
    }

    onClose(response) {
        this.setState({ show: false });
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async (e) => {
        const { jobAssignmentCancellationType } = this.state;
        const response = await util.fetch
            .delete(
                ApiRoutes.jobAssignmentCancellationType.delete(
                    jobAssignmentCancellationType.id
                )
            )
            .catch(this.handleSaveError);

        if (response) {
            this.onClose(response);
        }
    };

    getBadgeColor = (value) => (Boolean(value) ? 'success' : 'secondary');

    toYesNo = (value) => (Boolean(value) ? 'Yes' : 'No');

    render() {
        let {
            formValidated,
            validationMessage,
            jobAssignmentCancellationType,
            loading,
            show,
        } = this.state;

        const showDelete = Boolean(
            jobAssignmentCancellationType.id &&
                jobAssignmentCancellationType.canBeDeleted &&
                !jobAssignmentCancellationType.isDeleted
        );

        return (
            <SlideForm
                loading={loading}
                show={show}
                id={'jobAssignmentCancellationTypeForm'}
                formIcon={faPhoneSlash}
                formTitle={
                    !!parseInt(jobAssignmentCancellationType.id ?? 0) > 0
                        ? 'Edit Job Assignment Cancellation Type'
                        : 'Add Job Assignment Cancellation Type'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                readOnly={!jobAssignmentCancellationType.canBeModified}
                showDelete={showDelete}
            >
                <FormGroup>
                    <FormLabel
                        htmlFor="description"
                        text="Description"
                        required={true}
                    />
                    <Input
                        id="description"
                        name="jobAssignmentCancellationType.description"
                        value={jobAssignmentCancellationType.description ?? ''}
                        onChange={this.onChange}
                        placeholder="Enter description"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
                        Description is required.
                    </ValidationErrorMessage>
                </FormGroup>

                {jobAssignmentCancellationType.canBeModified && (
                    <>
                        <FormGroup>
                            <FormCheckbox
                                id="jobAssignmentCancellationType.preventsReassignment"
                                name="jobAssignmentCancellationType.preventsReassignment"
                                checked={
                                    jobAssignmentCancellationType.preventsReassignment ??
                                    ''
                                }
                                onChange={this.onChange}
                                labelText={
                                    'Prevents Reassignment For The Rest Of The Day'
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                id="jobAssignmentCancellationType.includeOnRosterView"
                                name="jobAssignmentCancellationType.includeOnRosterView"
                                checked={
                                    jobAssignmentCancellationType.includeOnRosterView ??
                                    ''
                                }
                                onChange={this.onChange}
                                labelText={'Include On Roster View'}
                            />
                        </FormGroup>
                        {Boolean(
                            jobAssignmentCancellationType.includeOnRosterView &&
                                !jobAssignmentCancellationType.preventsReassignment
                        ) && (
                            <FormGroup>
                                <FormCheckbox
                                    id="jobAssignmentCancellationType.treatAsUnassigned"
                                    name="jobAssignmentCancellationType.treatAsUnassigned"
                                    checked={
                                        jobAssignmentCancellationType.treatAsUnassigned ??
                                        ''
                                    }
                                    onChange={this.onChange}
                                    labelText={'Treat as Unassigned'}
                                />
                            </FormGroup>
                        )}
                    </>
                )}

                {!jobAssignmentCancellationType.canBeModified && (
                    <>
                        <FormGroup>
                            <FormLabel text="Prevents Reassignment" />
                            <h3>
                                <Badge
                                    className="fifty-px"
                                    color={this.getBadgeColor(
                                        jobAssignmentCancellationType.preventsReassignment
                                    )}
                                >
                                    {this.toYesNo(
                                        jobAssignmentCancellationType.preventsReassignment
                                    )}
                                </Badge>
                            </h3>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel text="Include On Roster View" />
                            <h3>
                                <Badge
                                    className="fifty-px"
                                    color={this.getBadgeColor(
                                        jobAssignmentCancellationType.includeOnRosterView
                                    )}
                                >
                                    {this.toYesNo(
                                        jobAssignmentCancellationType.includeOnRosterView
                                    )}
                                </Badge>
                            </h3>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel text="Treat as Unassigned" />
                            <h3>
                                <Badge
                                    className="fifty-px"
                                    color={this.getBadgeColor(
                                        jobAssignmentCancellationType.treatAsUnassigned
                                    )}
                                >
                                    {this.toYesNo(
                                        jobAssignmentCancellationType.treatAsUnassigned
                                    )}
                                </Badge>
                            </h3>
                        </FormGroup>
                    </>
                )}

                <FormGroup>
                    <FormLabel text="Can Be Modified" />
                    <h3>
                        <Badge
                            className="fifty-px"
                            color={this.getBadgeColor(
                                jobAssignmentCancellationType.canBeModified
                            )}
                        >
                            {this.toYesNo(
                                jobAssignmentCancellationType.canBeModified
                            )}
                        </Badge>
                    </h3>
                </FormGroup>
                <FormGroup>
                    <FormLabel text="Can Be Deleted" />
                    <h3>
                        <Badge
                            className="fifty-px"
                            color={this.getBadgeColor(
                                jobAssignmentCancellationType.canBeDeleted
                            )}
                        >
                            {this.toYesNo(
                                jobAssignmentCancellationType.canBeDeleted
                            )}
                        </Badge>
                    </h3>
                </FormGroup>
                <FormGroup>
                    <FormLabel text="Is Deleted" />
                    <h3>
                        <Badge
                            className="fifty-px"
                            color={this.getBadgeColor(
                                jobAssignmentCancellationType.isDeleted
                            )}
                        >
                            {this.toYesNo(
                                jobAssignmentCancellationType.isDeleted
                            )}
                        </Badge>
                    </h3>
                </FormGroup>
            </SlideForm>
        );
    }
}
