export const TenantSettings = {
    AllowCrossDispatching: 'AllowCrossDispatching',
    TimesheetResponsibility: 'TimesheetResponsibilityType',
    AssignmentCancellationThreshold: 'AssignmentCancellationThreshold',
    AssignmentCancellationMinimumCharge: 'AssignmentCancellationMinimumCharge',
    PayPeriodStartDay: 'PayPeriodBeginDayOfWeek',
    PayPeriodEndDay: 'PayPeriodBeginEndOfWeek',
    CrewsEnabled: 'CrewsEnabled',
    DispatchHoursEnabled: 'DispatchHoursEnabled',
    RollUpDriveTime: 'RollUpDriveTime',
    DefaultChargeEnabled: 'DefaultChargeEnabled',
    JobUploadsEnabled: 'JobUploadsEnabled',
    PayrollEnabled: 'PayrollEnabled',
    BillingEnabled: 'BillingEnabled',
    BillingRatesEnabled: 'BillingRatesEnabled',
    TimesheetAdjustmentsEnabled: 'TimesheetAdjustmentsEnabled',
    EmployeeOnboardingEnabled: 'EmployeeOnboardingEnabled',
    EmployeeOnboardingDefaultGroup: 'EmployeeOnboardingDefaultGroup',
    TimesheetEmergencyEnabled: 'TimesheetEmergencyEnabled',
    TimesheetEmergencyStart: 'TimesheetEmergencyStart',
    TimesheetEmergencyEnd: 'TimesheetEmergencyEnd',
    TimesheetEmailEnabled: 'TimesheetEmailEnabled',
    TimesheetDashboardEnabled: 'TimesheetDashboardEnabled',
    TimesheetExportEnabled: 'TimesheetExportEnabled',
    ComplianceEnabled: 'ComplianceEnabled',
    UseLimitedApprovals: 'UseLimitedApprovals',
    BlockBillingCancelEnabled: 'BlockBillingCancelEnabled',
    BlockPayrollCancelEnabled: 'BlockPayrollCancelEnabled',
    InvoicingEnabled: 'InvoicingEnabled',
    ChargeTypeGroupsEnabled: 'ChargeTypeGroupsEnabled',
    EnablePredefinedTimesheetMissingSigReason:
        'EnablePredefinedTimesheetMissingSigReason',
    EnableSelfDispatchTimesheets: 'EnableSelfDispatchTimesheets',
    ContactScreenEnabled: 'ContactScreenEnabled',
    ContactActivitiesEnabled: 'ContactActivitiesEnabled',
    UseStateMinimum: 'UseStateMinimum',
    EnableAlternateBillTo: 'EnableAlternateBillTo',
    TimesheetUseJobCity: 'TimesheetUseJobCity',
    CaptureCounty: 'CaptureCounty',
    GoogleMapsApiKey: 'GoogleMapsApiKey',
    IncludeSchedulingOnDailyJobExport: 'IncludeSchedulingOnDailyJobExport',
    EmployeeLongTermStatusManagementEnabled:
        'EmployeeLongTermStatusManagementEnabled',
    AttendanceEventsEnabled: 'AttendanceEventsEnabled',
    DispatchStatusFilterEnabled: 'DispatchStatusFilterEnabled',
    ShowLongTermStatusCountOnRoster: 'ShowLongTermStatusCountOnRoster',
    EnableContractDateRange: 'EnableContractDateRange',
    ContractExpiryDistributionList: 'ContractExpiryDistributionList',
    ContractExpiryCompanyName: 'ContractExpiryCompanyName',
    AllowDefaultChargeTypeValues: 'AllowDefaultChargeTypeValues',
    AllowTimesheetStatusRollbacks: 'AllowTimesheetStatusRollbacks',
    BannerMessage: 'BannerMessage',
    BannerMessageExternallyVisible: 'BannerMessageExternallyVisible',
    EnableAutoEscalatingContractRates: 'EnableAutoEscalatingContractRates',
    EnablePrevailingWageContracts: 'EnablePrevailingWageContracts',
    GLStringsEnabled: 'GLStringsEnabled',
    ShowContractDescriptionInTimesheetView:
        'ShowContractDescriptionInTimesheetView',
    ActiveEmployeeListEnabled: 'ActiveEmployeeListEnabled',
    EnableStaffingReport: 'EnableStaffingReport',
    StaffingReportDistributionList: 'StaffingReportDistributionList',
    EnableNewCustomerBillableHoursReport:
        'EnableNewCustomerBillableHoursReport',
    NewCustomerBillableHoursReportDistributionList:
        'NewCustomerBillableHoursReportDistributionList',
    EnableRequestedPersonnel: 'EnableRequestedPersonnel',
    EnableDriverReportingInTimesheet: 'EnableDriverReportingInTimesheet',
    EnableAFADChargeTypeGrouping: 'EnableAFADChargeTypeGrouping',
    EnableJobOverrides: 'EnableJobOverrides',
};

export const TimesheetResponsibilityType = {
    CrewLead: 1,
    Individual: 2,
    Contract: 3,
};

export const ContractTimesheetResponsibilityType = {
    CrewLead: 1,
    Individual: 2,
};

export const CountyCaptureType = {
    None: 1,
    AllTimesheets: 2,
    PrevailingWageTimesheets: 3,
};

//2022-08-16 - M. Nicol - Moved to TenantForm.js > getTimesheetResponsibilityTypeOptions method to account for localized strings.
//export const TimesheetResponsibilityTypeOptions = [
//    {
//        id: 1,
//        name: 'Crew Lead',
//        description: 'Crew Leads are responsible for all timesheet entry.',
//    },
//    {
//        id: 2,
//        name: 'Individual',
//        description: 'Individual employees enter timesheets.',
//    },
//    {
//        id: 3,
//        name: 'Contract',
//        description:
//            'Timesheet responsibility is decided between individual/Crew Lead on a contract-by-contract basis.',
//    },
//];

//2022-08-16 - M. Nicol - Moved to TenantForm.js > getTimesheetResponsibilityTypeOptions method to account for localized strings.
//export const ContractTimesheetResponsibilityTypeOptions = [
//    {
//        id: 1,
//        name: 'Crew Lead',
//        description: 'Crew Leads are responsible for all timesheet entry.',
//    },
//    {
//        id: 2,
//        name: 'Individual',
//        description: 'Individual employees enter timesheets.',
//    },
//];

export function isCountyCaptureEnabled(tenantSettings) {
    return Boolean(
        (tenantSettings.captureCounty == CountyCaptureType.AllTimesheets ||
            tenantSettings.captureCounty ==
                CountyCaptureType.PrevailingWageTimesheets) &&
            tenantSettings.googleMapsApiKey &&
            tenantSettings.googleMapsApiKey.length > 0
    );
}
