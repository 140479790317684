import React from 'react';
import { FormGroup } from 'reactstrap';
import { FormLabel } from '../common/forms/FormElements';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { Answer, InputTypeIds } from './FormsAndSurveys';
import cls from 'classnames';

export default class SurveyQuestion extends React.Component {

    render() {

        const {
            question, //the question data to be rendered
            questionIndex, //index of the question from the component
            answers //answer bank where answers are to be retrieved from your form data.
        } = { ...this.props };

        const questionId = question.id;
        const questionIdIndex = `question_${questionId}_${questionIndex}`;
        const answersForQuestion = (answers.filter(x => x.questionId === question.id) ?? []);

        //Text Answers should only have one question option.  We can use the first answer.
        const firstAnswer = (answersForQuestion ?? []).find(x => x.questionOptionId === question.questionOptions[0].id);

        //Multiselect/Select answers
        const selectedAnswerOptions = answersForQuestion.map(x => x.questionOptionId);

        let textLength = (firstAnswer?.answerText ?? '').length;
        return (
            <FormGroup className="mt-2" key={questionIndex}>
                <FormLabel
                    required={!!question.answerRequired && !this.props.disabled}
                    htmlFor={questionIdIndex}
                    text={question.name} />
                {
                    //Text Inputs
                    (question.inputTypeId === InputTypeIds.Text || question.inputTypeId === InputTypeIds.SingleLineText) &&
                    <>
                        <textarea
                            disabled={!!this.props.disabled}
                            required={!!question.answerRequired}
                            placeholder={`(${!!question.answerRequired ? 'Required' : 'Optional'}, limit ${question.inputTypeId === InputTypeIds.SingleLineText ? '150' : '2000'} characters)`}
                            maxLength={question.inputTypeId === InputTypeIds.SingleLineText ? "150" : "2000"}
                            id={questionIdIndex}
                            rows={question.inputTypeId === InputTypeIds.SingleLineText ? "1" : "6"}
                            name={questionIdIndex}
                            className="form-control form-control-sm"
                            value={firstAnswer?.answerText ?? ''}
                            onChange={this.props.onTextAnswerChanged}
                        />
                        {
                            !!question.answerRequired &&
                            <small className={cls('text-right', { 'text-danger': textLength < 0, 'text-success': textLength >= 0 })}>
                                {`${((question.inputTypeId === InputTypeIds.SingleLineText ? 150 : 2000) - textLength).toString()} remaining`}
                            </small>
                        }
                    </>
                }
                {
                    //Number Inputs
                    (question.inputTypeId === InputTypeIds.Number || question.inputTypeId === InputTypeIds.Decimal) &&
                    <>
                        <input
                            type="number"
                            disabled={!!this.props.disabled}
                            required={!!question.answerRequired}
                            placeholder={`(${!!question.answerRequired ? 'Required' : 'Optional'})`}
                            id={questionIdIndex}
                            name={questionIdIndex}
                            className="form-control form-control-sm"
                            value={firstAnswer?.answerText ?? ''}
                            onChange={this.props.onTextAnswerChanged}
                        />
                    </>
                }
                {
                    //Multiselect
                    (question.inputTypeId === InputTypeIds.MultiSelect) &&
                    <ValidatedSelect
                        isDisabled={!!this.props.disabled}
                        id={questionIdIndex}
                        name={questionIdIndex}
                        menuShouldScrollIntoView={true}
                        isMulti
                        required={!!question.answerRequired}
                        options={question.questionOptions}
                        value={question.questionOptions.filter(x => selectedAnswerOptions.includes(x.id))}
                        getOptionLabel={option => option.choiceText}
                        getOptionValue={option => option.id}
                        onChange={this.props.onSelectAnswerChanged}
                        validationMessage="At least one selection is required."
                    />
                }
                {
                    //Single select
                    (question.inputTypeId === InputTypeIds.Select) &&
                    <ValidatedSelect
                        disabled={!!this.props.disabled}
                        id={questionIdIndex}
                        required={!!question.answerRequired}
                        menuShouldScrollIntoView={true}
                        isMulti={false}
                        name={questionIdIndex}
                        options={question.questionOptions}
                        value={question.questionOptions.filter(x => selectedAnswerOptions.includes(x.id))}
                        getOptionLabel={option => option.choiceText}
                        getOptionValue={option => option.id}
                        onChange={this.props.onSelectChanged}
                        validationMessage="Selection is required."
                    />
                }
            </FormGroup>
        );
    }


}

//RLC: The below methods are 'universal' handlers for the different input types.  You may bind them
//to your component and use them, or of course you can write your own at the Component level
//where you are utilizing the SurveyQuestion component.

//Handles text input responses
export function onTextAnswerChanged(
    event, //Input change event.
    question, //The question details.
    stateKey, //The key name of the object in state that we will update.  This can/will vary from component to component.  
    answerKey //The name of the answer bank
) {

    // If this text field only allows number, remove extraneous characters
    if (question.inputTypeId == InputTypeIds.Number)
        event.target.value = parseInt(event.target.value);
    if (question.inputTypeId == InputTypeIds.Decimal)
        event.target.value = Math.abs(event.target.value);

    const questionOptionId = question.questionOptions[0].id;
    const questionName = question.name;
    const value = event.target.value;
    let stateObj = { ...this.state[stateKey] };
    let answers = stateObj[answerKey];
    let answer = answers.find(x => x.questionOptionId === questionOptionId);

    if (!!answer) {
        const ansInx = answers.findIndex(x => x.questionOptionId === answer.questionOptionId);
        answers[ansInx].answerText = value;
    } else {
        answers.push({
            ...new Answer(),
            questionId: question.id,
            questionOptionId: questionOptionId,
            answerText: value,
            questionName: questionName,
            timesheetDetailId: question.timesheetDetailId //This will very often be null or undefined
        });
    }

    //RLC: This is ES6 syntax used to update the state by a key, rather than allowing React to merge an explicit object.
    //When we do this, we can handle multiple controlled input elements with some flexibility, and reuse this piece.
    this.setState({ [stateKey]: stateObj }); 
}

//Handles multiselect responses
export function onSelectAnswerChanged(selection, question, stateKey, answerKey) {
    let stateObj = { ...this.state[stateKey] };
    let answers = stateObj[answerKey];

    //remove all previous answers tied to this question, for cleanliness' sake.
    var updatedAnswers = answers.filter(x => x.questionId !== question.id) ?? [];

    //If we have a selection, add them to the list of answers.
    if (!!selection) {
        let selectionsMapped = selection.map(x => ({
            ...new Answer(),
            questionId: x.questionId,
            questionName: question.name,
            questionOptionId: x.id,
            questionOptionChoiceText: x.choiceText, //remember the label of the multiple choice answer, for display later.
            timesheetDetailId: question.timesheetDetailId //This will very often be null or undefined
        }));
        updatedAnswers = updatedAnswers.concat(selectionsMapped);
    }

    answers.length = 0;
    answers.push(...updatedAnswers);

    this.setState({ [stateKey]: stateObj });
}

//Handles single select responses
export function onSelectChanged(selection, question, stateKey, answerKey) {
    let stateObj = { ...this.state[stateKey] };
    let answers = stateObj[answerKey];

    //remove all previous answers tied to this question, for cleanliness' sake.
    var updatedAnswers = answers.filter(x => x.questionId !== question.id) ?? [];

    //If we have a selection, add them to the list of answers.
    if (!!selection) {
        let selectionMapped = {
            ...new Answer(),
            questionId: selection.questionId,
            questionName: question.name,
            questionOptionId: selection.id,
            questionOptionChoiceText: selection.choiceText, //remember the label of the multiple choice answer, for display later.
            timesheetDetailId: question.timesheetDetailId //This will very often be null or undefined
        };
        updatedAnswers = updatedAnswers.concat([selectionMapped]);
    }

    answers.length = 0;
    answers.push(...updatedAnswers);

    this.setState({ [stateKey]: stateObj });
}

