import { faTasks } from '@fortawesome/free-solid-svg-icons';
import './TimesheetManagementIndex.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';

import CommonContext, { ApiRoutes, LocalizationKeys as l } from '../Common';
import {
    getFilterModel,
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    getServerSideRowRequest,
    TimesheetNumberCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    PageHeading,
    PageWrap,
    onFieldChange,
    toasty,
    SmallButton,
    FlexCenterRow,
} from '../common/forms/FormElements';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import * as moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { Can } from '../Can';

class TimesheetManagementIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            timesheetDetailsOpen: false,
            selectedDetails: [],
            currentTimesheetId: null,
            showConfirmExportLargeDataset: false,
            isExcelExporting: false,
            showPDFExportModal: false,
            isPDFExporting: false,
            pdfExportModalMessage: null,
            isPDFExportAllowed: false,
            loadingPDFReport: false,
        };

        //this.onRowClicked = this.onRowClicked.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => {
            return;
        };
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            var [strings, dispatchLocations, timesheetStatuses] =
                await Promise.all([
                    util.l10n.getStrings([l.crewLead]),
                    util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
                    util.fetch.js(ApiRoutes.typeAheads.timesheetStatuses()),
                ]);

            const gridOptions = createGridOptions(this);
            let context = this;

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: IconCellRenderer,
                timesheetNumberRenderer: TimesheetNumberCellRenderer,
                variableLinkRenderer: VariableLinkCellRenderer,
            };

            //this allows row colors to be set
            gridOptions.rowClassRules = {
                'ag-row-warning': (params) => {
                    return (params.data ?? {}).isClientCancelled === true;
                },
                'ag-row-danger': (params) => {
                    return (
                        (params.data ?? {}).isEmergency === true ||
                        ((params.data ?? {}).isZeroHours === true &&
                            params?.data?.status !== 'New')
                    );
                },
            };

            let dispatchFilterParams = {
                    suppressFilterButton: true,
                    options: dispatchLocations,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                },
                timesheetStatusFilterParams = {
                    suppressFilterButton: true,
                    options: timesheetStatuses,
                    optionsLabel: 'label',
                    optionsValue: 'value',
                };

            const statusValueGetter = (row) => {
                if (!!row.data) {
                    let isAddStatus =
                        !!row.data.isZeroHours ||
                        !!row.data.isEmergency ||
                        !!row.data.isClientCancelled;
                    let addStatus = '';
                    if (!!row.data.isEmergency) addStatus += ' - Emergency';
                    if (!!row.data.isZeroHours) addStatus += ' - Zero Hours';
                    if (!!row.data.isClientCancelled)
                        addStatus += ' - Client Cancel';
                    if (!!row.data.isNoDetails) addStatus += ' - No Charges';

                    let status = `${row.data.status}${
                        isAddStatus ? addStatus : ''
                    }`;
                    return status;
                }

                return '';
            };

            gridOptions.columnDefs = [
                {
                    headerName: '',
                    valueGetter: 'node.id',
                    sortable: false,
                    hide: true,
                    flex: 1,
                    maxWidth: 35,
                    minWidth: 35,
                    cellRenderer: indexCellRenderer,
                },
                {
                    colId: 'Date',
                    headerName: 'Date',
                    field: 'date',
                    sortable: true,
                    maxWidth: 120,
                    minWidth: 120,
                    sort: { direction: 'desc', priority: 0 },
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults,
                },
                {
                    colId: 'CustomerName',
                    headerName: 'Customer Name',
                    field: 'customerName',
                    sortable: true,
                    flex: 2,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    valueGetter: (row) => {
                        if (!!row.data) {
                            const customerName = row.data.customerName;
                            const subcontractorName = Boolean(
                                row.data.subcontractorName
                            )
                                ? ` - ${row.data.subcontractorName}`
                                : '';
                            const operationsCenterCompanyName = Boolean(
                                row.data.operationsCenterCompanyName
                            )
                                ? ` (${row.data.operationsCenterCompanyName})`
                                : '';

                            return `${customerName}${subcontractorName}${operationsCenterCompanyName}`;
                        }

                        return '';
                    },
                },
                {
                    colId: 'CrewLead',
                    headerName: `${strings[l.crewLead]} // Employee`,
                    field: 'crewLead',
                    sortable: true,
                    flex: 2,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellStyle: (q) => {
                        var empNumber = (q.data ?? {}).crewLeadEmployeeNumber;
                        if (empNumber?.length == 0) {
                            return { color: 'red', fontWeight: 'bold' };
                        }

                        return {};
                    },
                },
                {
                    colId: 'TimesheetNumber', //leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                    headerName: 'Timesheet #',
                    field: 'timesheetNumber',
                    sortable: true,
                    flex: 1.5,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'timesheetNumberRenderer',
                },
                {
                    colId: 'WorkOrderNumber',
                    headerName: 'Work Order',
                    field: 'workOrderNumber',
                    sortable: true,
                    flex: 1.5,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'DispatchLocation',
                    headerName: 'Dispatching',
                    sortable: true,
                    flex: 1.5,
                    field: 'dispatchOfficeName',
                    tooltipField: 'dispatchOfficeName',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: dispatchFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: dispatchFilterParams,
                },
                {
                    colId: 'Status',
                    headerName: 'Status',
                    field: 'status',
                    sortable: true,
                    flex: 2,
                    tooltipField: 'status',
                    cellRenderer: 'variableLinkRenderer',
                    cellRendererParams: (r) => {
                        return {
                            clicked: this.downloadPDFExport,
                            isLink: !!r.data?.timesheetExportId,
                            title: 'Download Export',
                            idField: 'timesheetExportId',
                            valueGetter: statusValueGetter,
                        };
                    },
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: timesheetStatusFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: timesheetStatusFilterParams,
                    //2022-09-09 - M. Nicol - uncomment below and comment out
                    //cellRenderer and cellRenderedParams.
                    //,valueGetter: statusValueGetter
                },
                {
                    colId: 'Id',
                    minWidth: 65,
                    headerName: 'View',
                    sortable: false,
                    cellRenderer: 'iconRenderer',
                    cellRendererParams: {
                        clicked: this.openTimesheetDetails,
                        idField: 'id',
                        iconClass: 'fa-eye',
                    },
                },
                {
                    colId: 'Id',
                    minWidth: 65,
                    headerName: 'Print',
                    sortable: false,
                    cellRenderer: 'iconRenderer',
                    cellRendererParams: {
                        clicked: this.printTimesheet,
                        idField: 'id',
                        iconClass: 'fa-print',
                    },
                },
            ];

            //API returns an object with counts as well as rowData for the grid.
            //Save counts to the state, and return the rowData to the grid.
            gridOptions.postProcessData = (data) => {
                context.setState({
                    rowData: data,
                    scoreboardData: { ...data.counts } ?? null,
                });

                return { ...data.result };
            };

            //On the grid's first load, we want to apply the fsilters immediately from the state population,
            //before the API call is made.
            gridOptions.onGridReady = (params) => {
                // Calc past 61 days
                let start = moment(moment().subtract(61, 'days')).format(
                    'YYYY-MM-DD'
                );
                let end = moment(moment().subtract(1, 'days')).format(
                    'YYYY-MM-DD'
                );

                const filterInstance =
                    gridOptions.api.getFilterInstance('Date');
                filterInstance.setModel({
                    type: 'inRange',
                    dateFrom: start,
                    dateTo: end,
                });
                gridOptions.api.onFilterChanged();

                params.api.setDatasource(context.state.dataSource);
                context.setState({ gridApi: params.api });
            };

            const dataSource = createDataSource(
                ApiRoutes.timesheetManagement.search(),
                gridOptions
            );

            this.setState({
                loading: false,
                gridOptions: gridOptions,
                dataSource: dataSource,
                isRejectingTimesheet: false,
            });
        }
    };

    onChange = onFieldChange;
    handleSaveError = (err) => handleFormSaveError(this, err);

    toggleRejectTimesheetModal = () => {
        let { showRejectTimesheetModal } = this.state;
        this.setState({ showRejectTimesheetModal: !showRejectTimesheetModal });
    };

    openTimesheetDetails = async (id) => {
        if (!!id) {
            this.setState({ currentTimesheetId: id });
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    };

    printTimesheet = (id) => {
        if (!!id) {
            window.open(ApiRoutes.report.timesheet(id), '_self');
        }
    };

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        await this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    onNavBack = async () => {
        const { currentTimesheetId } = { ...this.state };
        var rows = [];
        this.state.gridOptions.api.forEachNode((node) => rows.push(node.data));
        var navArray = rows.map((x) => x.id);

        if ((navArray ?? []).length > 0) {
            var currentIndex = navArray.indexOf(currentTimesheetId);
            var previousIndex = --currentIndex % navArray.length;
            var id = navArray[previousIndex];

            if (!!id) {
                this.setState({ currentTimesheetId: id });
                await this.context.setFormOpened(true);
                this.timesheetDetailsRef.current.open(id);
            }
        }
    };

    onNavForward = async () => {
        const { currentTimesheetId } = { ...this.state };
        var rows = [];
        this.state.gridOptions.api.forEachNode((node) => rows.push(node.data));
        var navArray = rows.map((x) => x.id);

        if ((navArray ?? []).length > 0) {
            var currentIndex = navArray.indexOf(currentTimesheetId);
            var nextIndex = ++currentIndex % navArray.length;
            var id = navArray[nextIndex];

            if (!!id) {
                this.setState({ currentTimesheetId: id });
                await this.context.setFormOpened(true);
                this.timesheetDetailsRef.current.open(id);
            }
        }
    };

    //Before allowing the excel export, check specified date range, if any, and try to
    //protect against abnormally large datasets.  We don't use the way in DataGridToolbar
    //so we can launch a popup beforehand.
    onExportToExcelClicked = async () => {
        const { gridOptions } = { ...this.state };
        const req = getServerSideRowRequest(gridOptions);

        let from = req?.filterModel['Date']?.dateFrom,
            to = req?.filterModel['Date']?.dateTo;

        if (!from || !to) {
            toasty.error(
                'Please specify a date range when exporting Timesheet search results to excel.'
            );
        } else {
            var daySpan = moment(new Date(to)).diff(
                moment(new Date(from)),
                'days'
            );
            if (daySpan > 30) {
                this.setState({ showConfirmExportLargeDataset: true });
                return false;
            } else {
                this.exportToExcel();
            }
        }
    };

    exportToExcel = async () => {
        await this.setState({
            showConfirmExportLargeDataset: false,
            isExcelExporting: true,
        });
        const { gridOptions } = { ...this.state };
        const req = getServerSideRowRequest(gridOptions);
        const fileName = `TimesheetManagementSearch_${moment().format(
            'YYYYMMDD_hhmmss'
        )}.xlsx`;
        try {
            await util.fetch.downloadFile(
                ApiRoutes.timesheetManagement.excelExport(),
                req,
                fileName
            );
        } catch (err) {
            toasty.error('Error exporting data: ' + err);
        } finally {
            await this.setState({ isExcelExporting: false });
        }
    };

    downloadPDFExport = async (id) => {
        if (!!id) {
            this.setState({ loadingPDFReport: true });

            await util.fetch
                .downloadFile(
                    ApiRoutes.timesheetManagement.download(id),
                    null,
                    'Timesheet.pdf'
                )
                .catch(this.handleSaveError);

            this.setState({ loadingPDFReport: false });
        }
    };

    togglePDFExportModal() {
        const { showPDFExportModal } = this.state;

        const newVal = !showPDFExportModal;

        //No need to update the status of the modal if we're hiding it.
        if (newVal) {
            const msgAllow = this.getPDFExportMessageAndAllow();
            this.setState({
                pdfExportModalMessage: msgAllow.message,
                isPDFExportAllowed: msgAllow.isAllowed,
            });
        }

        this.setState({ showPDFExportModal: newVal });
    }

    //2022-09-09 - M. Nicol
    //Export is allowed if the date range is <= 7 days
    //and at least has the possibility of containing Approved timesheets.
    getPDFExportMessageAndAllow() {
        const { gridOptions } = this.state;

        const agFilterModel = gridOptions.api.getFilterModel();

        //Date range must be no more than 7 days.
        //Timesheet status must be null (all) or 7 (Approved).
        //Must be for 1 dispatch location only (Removed for now - re-eanble and include in "msg" if desired.)
        //Empty message means all passed.

        const dateMessage = this.getPDFExportDateMessage(agFilterModel);
        const statusMessage = this.getPDFExportStatusMessage(agFilterModel);
        //const dispatchLocationMessage = this.getPDFExportDispatchLocationMessage(agFilterModel);

        let msg = `${dateMessage} ${statusMessage}`.trim();
        const isAllowed = msg === '';
        if (isAllowed)
            msg =
                'Export will be generated based upon search criteria entered on the timesheet screen.  If the result set is too large, the export may time out.';

        const ret = {
            message: msg,
            isAllowed: isAllowed,
        };

        return ret;
    }

    getPDFExportDateMessage(agFilterModel) {
        //Date range must be <= 7 days
        //https://momentjs.com/docs/#/displaying/difference/
        const from = moment(agFilterModel.Date.dateFrom);
        const to = moment(agFilterModel.Date.dateTo);
        const dateDiff = Math.abs(to.diff(from, 'days'));
        const isDateOK = dateDiff <= 7;

        const ret = isDateOK ? '' : 'Exports must be for no more than 7 days.';
        return ret;
    }

    getPDFExportStatusMessage(agFilterModel) {
        const isStatusOK = (agFilterModel.Status?.value ?? 7) == 7;

        const ret = isStatusOK
            ? ''
            : 'Exports must be for Approved timesheets only.';

        return ret;
    }

    getPDFExportDispatchLocationMessage(agFilterModel) {
        const isLocOK = !!agFilterModel.DispatchLocation?.value;

        const ret = isLocOK
            ? ''
            : 'Exports must be for a single Dispatching location only.';

        return ret;
    }

    async onPDFExportClick() {
        this.setState({ isPDFExporting: true });

        const { gridOptions } = this.state;

        //https://www.ag-grid.com/javascript-data-grid/grid-api/#reference-filter
        const agFilterModel = gridOptions.api.getFilterModel();
        const serverFilterModel = getFilterModel(agFilterModel);

        try {
            const response = await util.fetch.post(
                ApiRoutes.timesheetManagement.export(),
                serverFilterModel
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Timesheet Report complete');

                gridOptions.refresh();

                this.setState({
                    isPDFExporting: false,
                    showPDFExportModal: false,
                });
            }
        } catch {
            toasty.error('Error exporting timesheets');
        }
    }

    render() {
        if (this.state.loading)
            return <LinearProgress variant="indeterminate" color="secondary" />;

        const {
            rowData,
            gridOptions,
            showConfirmExportLargeDataset,
            isExcelExporting,
            scoreboardData,
            showPDFExportModal,
            isPDFExporting,
            isPDFExportAllowed,
            pdfExportModalMessage,
            loadingPDFReport,
        } = { ...this.state };

        var counts = {
            notSubmitted: 0,
            rejected: 0,
            submitted: 0,
            payrollRejected: 0,
        };
        if (!!scoreboardData) {
            counts = scoreboardData;
        }

        let countNotSubmittedClass =
            'mr-3 badge badge badge-lg ' +
            (counts.notSubmitted === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countRejectedClass =
            'mr-3 badge badge-lg ' +
            (counts.rejected === 0 ? 'badge-success p-2' : 'badge-danger p-2');
        let countSubmittedClass =
            'mr-3 badge badge-lg ' +
            (counts.submitted === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countPayrollRejectedClass =
            'mr-3 badge badge-lg ' +
            (counts.payrollRejected === 0
                ? 'badge-success p-2'
                : 'badge-danger p-2');

        const { tenant } = this.context;
        const timesheetExportEnabled =
            tenant && !!tenant.tenantSettings.timesheetExportEnabled;

        return (
            <>
                <PageWrap>
                    <PageHeading>
                        <FontAwesomeIcon
                            icon={faTasks}
                            className="mr-2 text-muted"
                        />
                        <span>Timesheet Management</span>
                        {timesheetExportEnabled && (
                            <Can I="export" a="timesheet">
                                <span
                                    style={{
                                        float: 'right',
                                        position: 'relative',
                                        top: '-5px',
                                    }}
                                >
                                    <Button
                                        size="sm"
                                        style={{ marginRight: '10px' }}
                                        color="danger"
                                        onClick={() =>
                                            this.togglePDFExportModal()
                                        }
                                    >
                                        Export
                                    </Button>
                                </span>
                            </Can>
                        )}
                    </PageHeading>

                    <FlexCenterRow
                        className="pb-3 pt-2"
                        id="timesheetWorkflowStatus"
                    >
                        <span
                            style={{ minWidth: '100px' }}
                            className="mr-2 text-muted font-weight-bold p-2"
                        >
                            Field Actions:
                        </span>
                        <span
                            style={{ minWidth: '100px' }}
                            className={countNotSubmittedClass}
                        >
                            Not Submitted
                            <span className="ml-2">{counts.notSubmitted}</span>
                        </span>
                        <span
                            style={{ minWidth: '100px' }}
                            className={countRejectedClass}
                        >
                            Rejected
                            <span className="ml-2">{counts.rejected}</span>
                        </span>

                        <span
                            style={{ minWidth: '100px' }}
                            className="mr-2 text-muted font-weight-bold p-2"
                        >
                            Office Actions:
                        </span>
                        <span
                            style={{ minWidth: '100px' }}
                            className={countSubmittedClass}
                        >
                            Submitted
                            <span className="ml-2">{counts.submitted}</span>
                        </span>
                        <span
                            style={{ minWidth: '100px' }}
                            className={countPayrollRejectedClass}
                        >
                            Billing Payroll Rejected
                            <span className="ml-2">
                                {counts.payrollRejected}
                            </span>
                        </span>
                    </FlexCenterRow>

                    <DataGridToolbar
                        entity="Timesheet Management"
                        gridApi={this.state.gridApi}
                        dataSource={this.state.dataSource}
                        hideAdd={true}
                        hideExcelButton={true}
                        gridOptions={this.state.gridOptions}
                    >
                        <SmallButton
                            disabled={!!this.state.saving}
                            onClick={this.onExportToExcelClicked}
                        >
                            <i className="fa fa-file-excel fa-lg" />
                            <span className="ml-2 small-viewport-hide">
                                Export to Excel
                            </span>
                        </SmallButton>
                    </DataGridToolbar>

                    {!!loadingPDFReport && <CustomCircularProgress />}

                    {!loadingPDFReport && (
                        <DataGrid
                            domLayout={'normal'}
                            rowData={rowData}
                            gridOptions={gridOptions}
                            gridStatus={this.state.gridStatus}
                        />
                    )}
                </PageWrap>
                <TimesheetDetails
                    ref={this.timesheetDetailsRef}
                    show={this.state.timesheetDetailsOpen}
                    toggleShow={(open) =>
                        this.setState({ timesheetDetailsOpen: open })
                    }
                    onClose={this.onTimesheetDetailsClosed}
                    onAccept={this.onAcceptTimesheet}
                    onReject={this.onRejectTimesheet}
                    isTimesheetForm={false}
                    isTimesheet={true}
                    isPayroll={false}
                    isBilling={false}
                    showNav={true}
                    onNavBack={this.onNavBack}
                    onNavForward={this.onNavForward}
                />
                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showConfirmExportLargeDataset}
                >
                    <ModalHeader>Warning: Large Dataset Request</ModalHeader>
                    <ModalBody>
                        You are requesting a large amount of Timesheet data
                        (over 30 days). This operation could potentially take a
                        long time. It is recommended that you set an appropriate
                        date range to reduce the size of the request.
                        <br />
                        <br />
                        Proceed?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="d-flex flex-row flex-nowrap align-items-center"
                            color={!!isExcelExporting ? 'secondary' : 'primary'}
                            disabled={!!isExcelExporting}
                            onClick={this.exportToExcel}
                        >
                            Yes, continue
                        </Button>
                        <Button
                            disabled={!!isExcelExporting}
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    showConfirmExportLargeDataset: false,
                                });
                            }}
                        >
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={isExcelExporting}
                >
                    <ModalBody className="pt-5 pb-5">
                        <FlexCenterRow>
                            <span className="fa fa-lg fa-spin fa-circle-notch mr-2"></span>
                            <span>Exporting data, please wait...</span>
                        </FlexCenterRow>
                    </ModalBody>
                </Modal>
                {/* 2022-08-26 - M. Nicol - Added today. Above was here previously. */}
                <Modal
                    isOpen={showPDFExportModal}
                    toggle={() => this.togglePDFExportModal()}
                >
                    <ModalHeader toggle={() => this.togglePDFExportModal()}>
                        Export Timesheets
                    </ModalHeader>
                    <ModalBody>
                        <p>{pdfExportModalMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        {isPDFExporting && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            color="primary"
                            disabled={!isPDFExportAllowed || isPDFExporting}
                            onClick={() => this.onPDFExportClick()}
                        >
                            Ok
                        </Button>{' '}
                        <Button
                            color="secondary"
                            onClick={() =>
                                this.setState({ showPDFExportModal: false })
                            }
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withRouter(TimesheetManagementIndex);
