import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { SubHeading } from '../common/forms/FormElements';
import HistoricalChangeSet from './HistoricalChangeSet';

export default function HistoricalChangeCollection(props) {
    const {
        historicalChangeCollection,
        heading,
        propertiesToIgnore,
        renderOptions,
    } = props;

    if (!historicalChangeCollection) {
        return <></>;
    }

    const anyChanges =
        historicalChangeCollection &&
        historicalChangeCollection.historicalChangeSets &&
        historicalChangeCollection.historicalChangeSets.length > 0;

    return (
        <>
            <Row>
                <Col>
                    <SubHeading>{heading}</SubHeading>
                </Col>
            </Row>

            {!anyChanges && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody>No change history found.</CardBody>
                        </Card>{' '}
                    </Col>
                </Row>
            )}

            {anyChanges &&
                historicalChangeCollection.historicalChangeSets.map(
                    (hcs, i) => (
                        <HistoricalChangeSet
                            key={`${heading}${i}${hcs.changeDate}`}
                            historicalChangeSet={hcs}
                            propertiesToIgnore={propertiesToIgnore}
                            renderOptions={renderOptions}
                            index={i}
                        />
                    )
                )}
        </>
    );
}
