import { faUserClock } from '@fortawesome/fontawesome-free-solid';
import React from 'react';
import CommonContext, { ApiRoutes } from '../Common';
import {
    Button,
    Col,
    FormGroup,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import Select from 'react-select';
import {
    FormLabel,
    FlexCenterRow,
    toasty,
    FormCheckbox
} from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import { BaseFormViewModel } from '../common/ViewModel';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { SignatureReviewTable } from './SignatureReviewTable';
import './ClientReview.scss';
import _ from 'lodash-es';
import SignatureButton from '../common/forms/SignatureButton';
import { TimesheetSignatureType } from './ClientReview';
import { NoReasonInput } from './ClientTimesheetSignature';
import SurveyQuestion, { onTextAnswerChanged, onSelectAnswerChanged, onSelectChanged } from '../formsAndSurveys/SurveyQuestion';

export default class EmployeeReview extends React.Component {
    static contextType = CommonContext;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.signatureRef = React.createRef();
        let stateBase = Object.assign(
            {
                reviews: [],
                employees: [],
                formValidated: false,
                isOpen: false,
                signatureTypes: [],
                selectedEmployeeId: null,
                selectedReview: {},
                timesheetId: null,
                displayReuse: false
            },
            new BaseFormViewModel()
        );
        this.baseState = stateBase;
        this.state = stateBase;
        this.resetForm = this.resetForm.bind(this);

        this.onReviewTextAnswerChanged = this.onReviewTextAnswerChanged.bind(this);
        this.onReviewSelectAnswerChanged = this.onReviewSelectAnswerChanged.bind(this);
        this.onReviewSelectChanged = this.onReviewSelectChanged.bind(this);
    }

    componentDidUpdate = () => {
    }

    async populateState() {

        var [signatureTypes, missingSigReasons] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.crewSignatureTypes()),
            util.fetch.js(ApiRoutes.timesheetReview.missingSignatureReasons())
        ]);

        this.setState({
            signatureTypes: signatureTypes,
            missingSignatureReasons: missingSigReasons
        });
    }

    onChangeNotes = (e) => {
        let { selectedReview } = this.state;
        selectedReview.notes = e.target.value;
        this.setState({ selectedReview: selectedReview })
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    onClear = () => {
        this.eSigPad.clear();
        this.setState({ signatureValid: true });
    }

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    }

    onDelete = async e => {

    }

    resetForm = () => this.setState({ formValidated: false, signatureValid: false });

    onSave = async () => {
        let { selectedReview, timesheetId } = { ...this.state };
        const { tenantSettings } = { ...this.props };

        let errors = [];

        if (selectedReview.signatureTypeId !== TimesheetSignatureType.EmployeeNoSignature) {
            if (!
                //Make sure there is sig data.
                ((!!selectedReview.reviewSignature.signatureData
                    //Make sure it's not just the mime type prefix.
                    && selectedReview.reviewSignature.signatureData !== "data:image/png;base64,") && (selectedReview.newEmployeeSignature || selectedReview.reuseEmployeeSignature))
            ) {
                errors.push('A signature is required.');
                this.setState({ signatureValid: false });
            }
        }
        else {
            //no signature notes or reason is required. 
            if (!tenantSettings.enablePredefinedTimesheetMissingSigReason && !selectedReview.notes) {
                errors.push('Provide a reason there was no signature.');
            }
            if (!!tenantSettings.enablePredefinedTimesheetMissingSigReason && !selectedReview.missingSigReasonId) {
                errors.push('Reason for no signature - a selection is required in the drop down.');
            }
        }

        var requiredQuestionsAnswered = true;

        if (!!selectedReview.questions.length) {

            if (selectedReview.questions.some(q => q.answerRequired)) {

                var requiredQuestions = selectedReview.questions.filter(q => q.answerRequired);

                requiredQuestions.forEach(function (rq) {
                    var ans = selectedReview.answers.filter(x => x.questionId === rq.id) ?? [];

                    if (!!!ans.length) {
                        requiredQuestionsAnswered = false;
                    }
                });
            }
        }

        if (!!selectedReview.chargeTypeQuestionGroups?.length ?? false) {
            let ctqs = selectedReview.chargeTypeQuestionGroups.flatMap(ctqg => ctqg.chargeTypeQuestions);

            if (ctqs.some(q => q.answerRequired)) {
                let requiredCTQs = ctqs.filter(q => q.answerRequired);

                requiredCTQs.forEach(function (rq) {
                    let ans = selectedReview.chargeTypeAnswers.filter(a => a.questionId === rq.id) ?? [];

                    if (!!!ans.length) {
                        requiredQuestionsAnswered = false;
                    }
                });
            }
        }

        if (!requiredQuestionsAnswered) {
            errors.push('Some required questions are missing an answer');
        }

        if (!!errors.length) {
            let errorsstring = errors.join(' ');
            toasty.error(errorsstring);
            this.setState({ formValidated: true });
            return;
        }

        try {
            //Questions are for display purpose only; they should not be passed with update data.
            selectedReview.questions = [];
            await util.fetch.post(ApiRoutes.timesheetReview.update(), selectedReview);
            toasty.success('Saved');
            this.open(timesheetId);
        } catch (err) {
            toasty.error('We were unable to save your timesheet review.  Message from the server: ' + err)
        }
    };

    setRef = (ref) => {
        // keep a reference to the dom ref as an instance property
        this.ref = ref;
        // give the dom ref to react-beautiful-dnd
        //this.props.innerRef(ref);
    };

    open = async (timesheetId) => {
        this.setState(this.baseState);
        await this.setState({ loading: true });

        let reviews = null;

        this.resetForm();

        await this.populateState();

        if (!!timesheetId) {
            reviews = await util.fetch.js(ApiRoutes.timesheetReview.employeesByTimesheetId(timesheetId));
        } else {
            reviews = null;//
        }

        //if none then close i guess as its likely complete? 
        if (!reviews.length) {
            this.onClose();
            return;
        }

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        var review = reviews.find((x) => x.employeeId == reviews[0].employeeId);

        const displayReuse = this.context?.user.isImpersonating && !!review.reviewSignature && !!review.reviewSignature.signatureData;
        // Remove "No Signature" option if this is the crewlead
        let { signatureTypes } = { ...this.state };

        var employeeReview = reviews.find((x) => x.employeeId == this.context?.user?.employeeId);
        var isCrewLead = employeeReview?.isCrewLead;
        if (isCrewLead) {
            signatureTypes = signatureTypes.filter(x => x.label !== "No Signature");
        }

        await this.setState({
            reviews: reviews,
            loading: false,
            timesheetId: timesheetId,
            employees: reviews.map((x) => { return { value: x.employeeId, label: x.employeeFullName } }),
            selectedReview: review,
            selectedEmployeeId: reviews[0].employeeId,
            signatureTypes: signatureTypes,
            displayReuse: displayReuse
        });

    }

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    }

    onDelete = async e => {

    }

    resetForm = () => {
        this.setState({
            formValidated: false
        });
    }

    onEmployeeSelected = (employee) => {
        let { reviews } = { ...this.state };
        var selectedReview = reviews.find((x) => x.employeeId == employee.value);

        this.setState({ selectedReview: selectedReview, selectedEmployeeId: employee.value });
    }

    onSignatureTypeChanged = (signatureType) => {
        let { selectedReview } = { ...this.state };

        selectedReview.signatureTypeId = signatureType.value;

        if (selectedReview.signatureTypeId === TimesheetSignatureType.EmployeeNoSignature) {
            selectedReview.reviewSignature.signatureData = null;
            selectedReview.reviewSignature.sigIsComplete = false;
        }

        this.setState({ SelectedReview: selectedReview });
    }

    onSigSavedCallback = sigData => {
        let { selectedReview } = { ...this.state };
        selectedReview.reviewSignature.signatureData = sigData;
        selectedReview.newEmployeeSignature = true;
        selectedReview.reviewSignature.sigIsComplete = true;
        this.setState({ selectedReview: selectedReview });
    }

    onReuseSignatureChanged = e => {
        let include = e.target.checked;
        let { selectedReview } = { ...this.state };
        selectedReview.reuseEmployeeSignature = include;
        this.setState({ selectedReview: selectedReview })
    }

    onMissingSigReasonChanged = reason => {
        let { selectedReview } = { ...this.state };
        selectedReview.missingSigReasonId = reason.value;
        this.setState({ selectedReview: selectedReview });
    }

    //#region Questions/Answers
    onReviewTextAnswerChanged = onTextAnswerChanged;
    onReviewSelectAnswerChanged = onSelectAnswerChanged;
    onReviewSelectChanged = onSelectChanged;

    //#endregion
    render = () => {
        const { reviews,
            employees,
            selectedReview,
            selectedEmployeeId,
            signatureTypes,
            formValidated,
            validationMessage,
            missingSignatureReasons,
            displayReuse
        } = { ...this.state };

        const {
            tenantSettings
        } = { ...this.props };

        if (!reviews)
            return null;

        return (
            <SlideForm
                size="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                loading={this.state.loading}
                show={this.props.show}
                id={"employeeReview"}
                formIcon={faUserClock}
                formTitle="Crew Review / Signature"
                ref={this.formRef}
                setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                isValidated={formValidated}
                onClose={this.onClose}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
            >
                <div>
                    <FormGroup>
                        <FormLabel
                            htmlFor="selectedEmployeeId"
                            text="Employee" />
                        <Select
                            id="selectedEmployeeId"
                            name="selectedEmployeeId"
                            options={employees}
                            value={(employees ?? []).find(s => s.value === selectedEmployeeId) ?? ''}
                            onChange={this.onEmployeeSelected}
                        />
                    </FormGroup>
                    {
                        !_.isEmpty(selectedReview) &&
                        <>
                            <FormGroup>
                                <SignatureReviewTable timeCharges={(selectedReview ?? {}).reviewTable} />
                            </FormGroup>
                            {
                                !!(selectedReview ?? {}).workOrderNumber &&
                                <FormGroup>
                                    <FormLabel text={`Work Order`} />
                                    <span className="ml-3 text-danger font-weight-bold">{`[${selectedReview.workOrderNumber}]`}</span>
                                </FormGroup>
                            }
                            {
                                !!(selectedReview?.chargeTypeQuestionGroups ?? []).length &&
                                selectedReview.chargeTypeQuestionGroups.map((qg, qgInx) =>
                                    <Card key={qgInx} >
                                        <CardBody>
                                            <CardTitle>{qg.chargeTypeName}</CardTitle>
                                            {
                                                qg.chargeTypeQuestions.map((q, qInx) =>
                                                    <SurveyQuestion
                                                        disabled={!!this.props.disabled}
                                                        key={qInx}
                                                        question={q}
                                                        questionIndex={qInx}
                                                        answers={selectedReview.chargeTypeAnswers}
                                                        onTextAnswerChanged={(event) => this.onReviewTextAnswerChanged(event, q, "selectedReview", "chargeTypeAnswers")}
                                                        onSelectAnswerChanged={(selection) => this.onReviewSelectAnswerChanged(selection, q, "selectedReview", "chargeTypeAnswers")}
                                                        onSelectChanged={(selection) => this.onReviewSelectChanged(selection, q, "selectedReview", "chargeTypeAnswers")}
                                                    />
                                                )
                                            }
                                        </CardBody>
                                    </Card>
                                )
                            }

                            {
                                !!(selectedReview?.questions ?? []).length &&
                                selectedReview.questions.map((q, qInx) =>
                                    <SurveyQuestion
                                        disabled={!!this.props.disabled}
                                        key={qInx}
                                        question={q}
                                        questionIndex={qInx}
                                        answers={selectedReview.answers}
                                        onTextAnswerChanged={(event) => this.onReviewTextAnswerChanged(q, event, "selectedReview", "answers")}
                                        onSelectAnswerChanged={(selection) => this.onReviewSelectAnswerChanged(selection, q, "selectedReview", "answers")}
                                        onSelectChanged={(selection) => this.onReviewSelectChanged(selection, q, "selectedReview", "answers")}
                                    />)
                            }

                            {
                                !!(selectedReview ?? {}).timeSheet.notes &&
                                <FormGroup className="w-100">
                                    <FormLabel
                                        htmlFor="notes"
                                        text="Notes" />
                                    <Col size="12">
                                        <textarea
                                            readOnly={true}
                                            id="timesheetNotes"
                                            rows="3"
                                            name="selectedReview.timeSheet.notes"
                                            className="form-control form-control-sm p-2"
                                            defaultValue={selectedReview.timeSheet.notes ?? ""}
                                        />
                                    </Col>
                                </FormGroup>
                            }
                            { displayReuse && (
                                <FormCheckbox
                                    labelClass="text-danger text-small"
                                    className="mt-2 pl-2 mb-3"
                                    id={"reuseEmployeeSignature"}
                                    disabled={!!selectedReview.isComplete}
                                    readOnly={false}
                                    onChange={this.onReuseSignatureChanged}
                                    labelText={"[Admin] Re-Use Signature"}
                                />
                            )}
                            {!(selectedReview.reuseEmployeeSignature) &&
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="empSignatureTypeId"
                                        text="Type"
                                        required />
                                    <ValidatedSelect
                                        id="empSignatureTypeId"
                                        name="selectedReview.signatureTypeId"
                                        required
                                        options={signatureTypes}
                                        value={(signatureTypes ?? []).find(s => s.value === selectedReview.signatureTypeId) ?? ''}
                                        onChange={this.onSignatureTypeChanged}
                                        validationMessage="An employee signature type is required."
                                        hideClear={true}
                                    />
                                </FormGroup>
                            }

                            {
                                (selectedReview ?? {}).signatureTypeId === TimesheetSignatureType.EmployeeNoSignature &&
                                <NoReasonInput
                                    tenantSettings={tenantSettings}
                                    review={selectedReview}
                                    formValidated={formValidated}
                                    missingSignatureReasons={missingSignatureReasons}
                                    onMissingSigReasonChanged={this.onMissingSigReasonChanged}
                                    onChangeNotes={this.onChangeNotes}
                                />
                            }
                            {
                                (selectedReview ?? {}).signatureTypeId !== TimesheetSignatureType.EmployeeNoSignature && !(selectedReview.reuseEmployeeSignature) &&
                                <>
                                    {
                                        (!!selectedReview.isComplete || !!selectedReview.reviewSignature.sigIsComplete) &&
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="empSigPreview"
                                                text="Employee Signature:"
                                            />
                                            <img
                                                alt="Signature Preview"
                                                id="empSigPreview"
                                                style={{ maxWidth: '100%', height: 'auto', border: '1px solid #ccc' }}
                                                src={selectedReview.reviewSignature.signatureData} />
                                        </FormGroup>
                                    }
                                    {
                                        (!selectedReview.isComplete && !selectedReview.reviewSignature.sigIsComplete) &&
                                        <SignatureButton
                                            id="employeeReviewButton"
                                            ref={(ref) => this.signatureRef = ref}
                                            onSigSavedCallback={this.onSigSavedCallback}
                                            sigData={selectedReview?.reviewSignature?.signatureData}
                                        />
                                    }


                                </>
                            }
                        </>
                    }
                </div>

                <FlexCenterRow className="mt-2">
                    <Button className="btn mr-2" color="primary" onClick={this.onSave}>Save</Button>
                </FlexCenterRow>
            </SlideForm>
        );
    }
}