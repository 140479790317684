import React from 'react';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import CommonContext from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faMoneyBillWave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FormLabel, FormValidated, SmallButton } from '../common/forms/FormElements';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import ValidationMessageDisplay from '../common/forms/ValidationMessageDisplay';

export class EmployeePayRate {
    id = null;
    payRate = null;
    startDate = null;
    endDate = null;
    rateReceivedDate = null;
}

export class EmployeePayRateForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            errors: {},
            payRate: this.props.payRate ?? new EmployeePayRate()
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => this.populateState();
    populateState = async () => this.setState({ loading: false });
    resetForm = () => this.setState({ formValidated: false });

    onSubmit = async e => {
        const { payRate } = this.state;

        if (!payRate.endDate)
            payRate.endDate = null;
        
        this.props.onSaveCallback();
    }


    onClose(response) {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose();
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex flex-row h-100 align-items-center justify-content-center">
                    <CircularProgress variant="indeterminate" color="secondary" />
                </div>                
            )
        } else {
            const { errors, validationMessage } = this.state;
            const { payRate } = this.props;
            if (!payRate) return '';

            let classNames = cls('slide-form col-xl-3 col-md-6 col-sm-12', { 'show': this.props.show })
            return (
                <div className={classNames}>
                    <FormValidated
                        ref={this.formRef}
                        setIsValidated={this.props.setIsValidated}
                        isValidated={this.props.isValidated}
                        className="m-0 w-100"
                        id="payRateForm"
                        name="payRateForm"
                        onSubmit={this.onSubmit}
                    >
                        <header className="border-bottom d-flex flex-column position-relative  pt-3 pl-3 pr-3 mb-3">
                            <span id="payRateFormClose" className="cursor-pointer site-form-close" onClick={this.onClose}>
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </span>
                            <h5>
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <FontAwesomeIcon className="mr-2 text-muted" size="lg" icon={faMoneyBillWave} />
                                    <span>{payRate.id ? 'Edit Pay Rate' : 'New Pay Rate'}</span>
                                </div>
                            </h5>
                            <div className="d-flex flex-row align-items-center text-muted">
                                <SmallButton type="submit" name="" onClick={this.onSave}>
                                    <FontAwesomeIcon className="mr-2" icon={faSave} />Save
                                </SmallButton>
                                {!!this.state.payRate.id ?
                                    <SmallButton onClick={this.onDelete}>
                                        <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />Remove
                                    </SmallButton> : ''
                                }
                            </div>
                        </header>
                        <ValidationMessageDisplay onClear={this.onClearErrorNotificationClicked} errors={errors} message={validationMessage} />
                        <Row className="overflow-y-auto pl-3 pr-3 pb-3 mr-0 ml-0">
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="payRate"
                                        text="Pay Rate"
                                        required={true} />
                                    <Input id="payRate"
                                        name="payRate"
                                        defaultValue={payRate.payRate ?? ''}
                                        onChange={this.props.onChange}
                                        placeholder="Enter Rate"
                                        type="number"
                                        step="any"
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">Pay Rate is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="startDate"
                                        text="Start Date"
                                        required
                                    />
                                    <input id="startDate"
                                        required
                                        type="date"
                                        className="form-control"
                                        name="startDate"
                                        defaultValue={payRate.startDate ?? ''}
                                        onChange={this.props.onChange}
                                    />
                                    <small className="invalid-feedback text-danger">Start Date is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="endDate"
                                        text="End Date" />
                                    <input id="endDate"
                                        type="date"
                                        className="form-control"
                                        name="endDate"
                                        defaultValue={payRate.endDate ?? ''}
                                        onChange={this.props.onChange}
                                    />
                                    <div className="ml-3 mt-1 d-flex flex-row align-items-center">
                                        <span className="pr-2">This is the current rate</span>
                                        <input type="checkbox" disabled className="disabled d-none chk-i" id="currentPayRate" checked={!this.state.payRate.endDate} />
                                        <label className="chk p-0 mb-0" htmlFor="currentPayRate"></label>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="rateReceivedDate"
                                        text="Received On"
                                        required={true}/>
                                    <input id="rateReceivedDate"
                                        type="date"
                                        className="form-control"
                                        name="rateReceivedDate"
                                        defaultValue={payRate.rateReceivedDate ?? ''}
                                        onChange={this.props.onChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormValidated>
                </div>
            );
        }
    }
}