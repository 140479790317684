import React from 'react';
import DailyAttendanceEventCard from './DailyAttendanceEventCard';
import { RemoveDailyAttendanceEvent } from './DailyAttendanceEvent';

export default class DailyAttendanceDispatchSink extends React.Component {
    render() {
        const {
            dailyAttendanceEvents,
            employeeDailyAttendanceEvents,
            selectedWeekRangeDays,
            areAttendanceEventsEnabled,
            loading,
            staffLoading,
            resourcesLoading,
            staffTabActive,
        } = this.props;

        if (
            loading ||
            staffLoading ||
            resourcesLoading ||
            !staffTabActive ||
            !areAttendanceEventsEnabled ||
            !dailyAttendanceEvents ||
            !dailyAttendanceEvents.length ||
            !selectedWeekRangeDays ||
            selectedWeekRangeDays.length != 1
        ) {
            return null;
        }

        return (
            <div className="dailyAttendanceEventsSink">
                <legend className="dailyAttendanceEventLegend">
                    Attendance
                </legend>

                <div className="dailyAttendanceEventsContainer">
                    {dailyAttendanceEvents
                        .concat([new RemoveDailyAttendanceEvent()])
                        .map((dae) => (
                            <DailyAttendanceEventCard
                                key={`dailyAttendanceEvent-${dae.id}`}
                                dailyAttendanceEvent={dae}
                                employeeDailyAttendanceEvents={
                                    employeeDailyAttendanceEvents
                                }
                            />
                        ))}
                </div>
            </div>
        );
    }
}
