import React from 'react';
//Contexts
import { Can } from '../Can'
import CommonContext, { ApiRoutes } from '../Common';
import { StatusForm } from '../common/Generic Status/StatusForm';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { createDataSource, createGridOptions, DataGrid, indexCellRenderer } from '../common/dataGrid/DataGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import { FlexRow, FlexStartRow, FlexEndRow, PageHeading, SmallButton, PageWrap } from '../common/forms/FormElements';
import { Row, Col } from 'reactstrap';
import { util } from '../Util';
import { ContractStatus } from './ContractStatus';
import { withRouter } from 'react-router-dom';

class ContractStatusIndex extends React.Component {

    static contextType = CommonContext;

    // #region [ Constructor and Overrides ]

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            contractStatus: null,
            readOnly: true,
            formValidated: false,
            errors: {},
            showContractStatusForm: false,
            selectedContractStatus: null,
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddContractStatus = this.onAddContractStatus.bind(this);
        this.onEditContractStatus = this.onEditContractStatus.bind(this);
        this.onSaveContractStatus = this.onSaveContractStatus.bind(this);
    }

    componentDidMount= () => this.populateState();

    async populateState() {
        const contractStatusId = this.props.match.params.id;

        var data = await Promise.all([
            !!contractStatusId ? util.fetch.js(ApiRoutes.contractStatus.byId(contractStatusId)) : new ContractStatus()
        ]);

        let gridOptions = createGridOptions();

        gridOptions.components = {
            statusFilter: DataGridSelectFilter,
            equipmentTypeFilter: DataGridSelectFilter,
        };

        gridOptions.columnDefs = [
            { headerName: "", valueGetter: "node.id", sortable: false, cellRenderer: indexCellRenderer },
            { colId: 'ContractStatus.Description', sortable: true, headerName: 'Description', field: 'description', sort: { direction: 'asc', priority: 0 } }
        ];

        let dataSource = createDataSource(ApiRoutes.contractStatus.search(), gridOptions)

        this.setState(state => {
            return {
                loading: false,
                contractStatus: data[0],
                readOnly: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            }
        });
    }

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState((state) => { return { errors: {} }; });
    }

    // #endregion

    async onSubmit() {

        //Clear any fluent api errors
        this.setState((state) => { return { errors: {} }; });

        let { contractStatus } = this.state;

        let url = contractStatus.id ? ApiRoutes.contractStatus.update(contractStatus.id) : ApiRoutes.contractStatus.create();
        let fetch_stat = contractStatus.id ? util.fetch.put : util.fetch.post

        let response = await fetch_stat(url, contractStatus).catch((err) => {
            if (err && err.validationMessages && (Object.keys(err.validationMessages) ?? []).length)
                this.setState((state) => { return { errors: err.validationMessages } });
        });

        if (response.ok) {

            var id = await response.json();

            //if we got this far, redirect to employee edit page.
            this.redirect(id);
        }
    }

    // #region [ Status Flyout for Add/Edit ]

    //Add New Status
    onAddContractStatus() {
        let { contractStatus } = this.state;
        let new_status = new ContractStatus();
        //This is a new status, embed the Id and description.
        new_status.id = contractStatus.id ?? 0;
        new_status.description = contractStatus.description;
        this.setState({
            selectedContractStatus: new_status,
            showContractStatusForm: true
        });
    }

    //Status table edit click handler
    onEditContractStatus(status) {
        this.setState({ selectedContractStatus: JSON.parse(JSON.stringify(status)), showContractStatusForm: true });
    }

    //After status save callback
    onSaveContractStatus(status) {
        if (!!status) {
            this.setState({ showContractStatusForm: false });
            //refresh the grid
        }
    }

    //Close status window
    onCloseContractStatus(response) {
        this.resetForm();
        this.props.onClose(response);
    }

    //Delete status shown
    onDeleteContractStatus = async e => {
        const { contractStatus } = this.state;
        let response = await util.fetch.delete(ApiRoutes.contractStatus.delete(contractStatus.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    // #endregion 

    redirect(id) {
        this.props.history.push(`${ApplicationPaths.contractStatus}/${id}`);
    }

    onInputChange = event => {
        let { contractStatus } = this.state;
        contractStatus.description = event.target.value;

        this.setState({
            contractStatus: contractStatus
        });
    }

    // #region [Render and Render Functions]



    render() {
        if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)

        let { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faScrewdriver} className="mr-2 text-muted" />
                    <span>Contract Status</span>
                </PageHeading>
                <Row>
                    <Col>
                        <FlexRow>
                            <FlexStartRow>
                            </FlexStartRow>
                            <FlexEndRow>
                                <Can I="create,view,edit" a="equipment">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    hidden={!this.state.selectedContractStatus}
                                                    onClick={this.onEditContractStatus}
                                                >
                                                    <i className="fa fa-edit fa-md mr-2" />View Selected
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                                <Can I="create" a="equipment">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    onClick={this.onAddContractStatus}
                                                >
                                                    <i className="fa fa-plus-circle fa-md mr-2" />Add Contract Status
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                            </FlexEndRow>
                        </FlexRow>
                    </Col>
                </Row>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}>
                </DataGrid>
                <StatusForm
                    status={this.state.selectedContractStatus}
                    show={this.state.showContractStatusForm}
                    onClose={() => this.setState({ showContractStatusForm: false })}
                    onSaveCallback={this.onSaveContractStatus}
                    statusName='Contract Status'
                    route={"contractStatus"}
                />
            </PageWrap>
        );

    }

    // #endregion
}
export default withRouter(ContractStatusIndex);