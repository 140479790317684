import React from 'react';
import './DispatchBoard.scss';
import { Weekdays, LocalizationKeys as l } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Alert } from 'reactstrap';
/*import { FlexCenterRow } from '../common/forms/FormElements';*/

export default class DispatchCancellationJobAssignments extends React.Component {

    render = () => {

        const { job, selectedWeekRangeDays, jobIndex, strings } = { ...this.props };

        const crewLead = strings[l.crewLead];

        return (
            <>
                <table key={jobIndex} className="table table-sm table-bordered border-top-0 jobAssignmentsTable mb-0">
                    <thead>
                        <tr>
                            {
                                (selectedWeekRangeDays ?? []).map(day =>
                                    <th key={day}>
                                        <div className="w-100 job-assignment-table-header d-flex flex-row flex-nowrap">                                           
                                            <span className="text-left flex-fill">{Weekdays.find(x => x.id === day.getDay()).abbreviation}</span>
                                            <span className="text-muted">{`${day.getMonth() + 1}/${day.getDate()}`}</span>                                           
                                        </div>
                                    </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                (selectedWeekRangeDays ?? []).map(day => {

                                    const hasCancels =
                                        !!(job.assignments[day.getDay()] ?? []).cancelledJobs.length ||
                                        !!(job.assignments[day.getDay()] ?? []).cancelledAssignments.length;

                                    return (
                                        <td key={day}>                                           
                                            <div className="assignmentsWrapper">
                                                <div className="assignments">
                                                    {
                                                        !!hasCancels &&
                                                        job.assignments[day.getDay()].cancelledAssignments.map(a => {
                                                            return (
                                                                <div className="jobCancellationBadge" key={a.id}>
                                                                    <div className="jobCancellationBadgeDetails">
                                                                        <div className="jobCancellationBadgeAsn">
                                                                            {
                                                                                !!a.isCrewLead &&
                                                                                <FontAwesomeIcon
                                                                                    icon={faHardHat}
                                                                                    className={"mr-1 crewLeadBadge"}
                                                                                    style={{ color: "#ff8d00" }}
                                                                                    title={`Employee was ${crewLead}`}
                                                                                />
                                                                            }
                                                                            <span className="font-weight-bold text-danger mr-1">
                                                                                {
                                                                                    (!!a.assignedEmployee ? a.assignedEmployee : a.assignedEquipment)
                                                                                }
                                                                            </span>
                                                                            <span className="font-weight-bold text-muted">
                                                                                {`- ${a.jobAssignmentCancellationType}`}
                                                                            </span>
                                                                        </div>
                                                                        <div className="jobCancellationBadgeAsnCancelType">
                                                                            <div className="mr-2">
                                                                                <span title="Cancelling User" className="cursor-pointer fa fa-lg fa-user mr-1  text-danger"></span>
                                                                                <span>{`${a.cancelledByEmployee}`}</span>
                                                                            </div>
                                                                            <div className="mr-2">
                                                                                <span title="Cancellation Date and Time" className="cursor-pointer fa fa-lg fa-calendar-times mr-1 text-danger"></span><span>{`${moment(new Date(a.cancelledOn)).format("M/D/YY, h:mm A")}`}</span>
                                                                            </div>
                                                                            {
                                                                                !!a.note &&
                                                                                <div className="d-flex flex-row justify-content-start align-items-center mr-2">
                                                                                    <span style={{ textAlign: 'left !important' }} title="Cancellation note" className="cursor-pointer fa fa-lg fa-sticky-note mr-1 text-danger"></span><small>{a.note}</small>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !!a.confirmedOn &&
                                                                                <Alert size="sm" className="cancelConfirmAlert" color="info">
                                                                                    <span className="fa fa-lg fa-check-circle mr-1"></span>
                                                                                    {`Confirmed - ${moment(new Date(a.confirmedOn)).format("M/D/YY, h:mm A")}`}
                                                                                </Alert>
                                                                            }
                                                                        </div>
    
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }                                                    
                                                </div>
                                            </div>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }
}