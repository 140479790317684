import { ApiRoutes, ApplicationPermissions } from "../Common";
import { util } from "../Util";

export const getTenantUserProfile = async () => {
    const [currentUser, userPermissions, tenant] = await Promise.all([
        util.fetch.js(ApiRoutes.auth.profile()),
        util.fetch.js(ApiRoutes.auth.permissions()),
        util.fetch.js(ApiRoutes.tenant.settings()),
    ]);

    const permissions = userPermissions.map(x => `${x.permission}.${x.action}`);

    currentUser.isAdmin = !!((currentUser.roles ?? []).filter(x => x.displayName === 'Administrator') ?? []).length;

    currentUser.canCreateNonFlagTimesheet = Boolean(currentUser.employeeId
        && permissions.includes(ApplicationPermissions.nonFlagging_timesheet_create));

    currentUser.canCreateSelfDispatchTimesheet = Boolean(currentUser.employeeId
        && permissions.includes(ApplicationPermissions.self_dispatch_timesheet_create));

    return { currentUser: currentUser, userTenant: tenant, userPermissions: permissions }
}