import React, { Fragment } from 'react';
import {
    ChargeType,
    ChargeTypeConfiguration,
    ChargeTypeViewModel,
    ChargeTypeBillingRate,
} from './ChargeType';
import ChargeTypeConfigurationForm from './ChargeTypeConfigurationForm';
import BillingRateForm from '../billing/BillingRateForm';
import BillingRateHistoryForm from '../billing/BillingRateHistoryForm';
import { BillingRateGrid } from '../billing/BillingRateGrid';
import { ChargeTypeConfigurationInfo } from './ChargeTypeConfigurationInfo';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faFileInvoiceDollar,
    faCheckCircle,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
    AppPageForm,
    FlexCenterRow,
    FlexRow,
    FormCheckbox,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    onFieldChange,
    onReactSelectChanged,
    SubHeading,
    ToastMessage,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { Button, FormGroup, Input } from 'reactstrap';
import { LinearProgress } from '@material-ui/core';
import Select from 'react-select';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import _ from 'lodash-es';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { toast } from 'react-toastify';
import cls from 'classnames';
import NumericInput from '../common/forms/NumericInput';

class ChargeTypeForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.minPayRef = React.createRef();
        this.maxPayRef = React.createRef();
        this.exceptionFormRef = React.createRef();
        this.rateFormRef = React.createRef();
        this.rateHistoryFormRef = React.createRef();
        this.state = new ChargeTypeViewModel();
        this.handlers.change = this.handlers.change.bind(this);
        this.handlers.select = this.handlers.select.bind(this);
        this.handlers.baseConfig.canBreak =
            this.handlers.baseConfig.canBreak.bind(this);
        this.handlers.selectedException.canBreak =
            this.handlers.selectedException.canBreak.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps &&
            this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    };

    async populateState() {
        const id = this.props.match.params.id;

        var [
            setupSurcharges,
            resGroups,
            groups,
            units,
            statuses,
            equipmentTypes,
            customers,
            chargeType,
            chargeTypeApplicability,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.chargeType.setupSurcharges()),
            util.fetch.js(ApiRoutes.userGroup.all()),
            util.fetch.js(ApiRoutes.auth.groups()),
            util.fetch.js(ApiRoutes.chargeTypeUnit.all()),
            util.fetch.js(ApiRoutes.chargeTypeStatus.all()),
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypes()),
            util.fetch.js(ApiRoutes.typeAheads.customers()),
            !!id
                ? util.fetch.js(ApiRoutes.chargeType.byId(id))
                : new ChargeType(),
            util.fetch.js(ApiRoutes.typeAheads.chargeTypeApplicability()),
        ]);

        let originalData = util.object.clone(chargeType);

        this.setState((state) => {
            return {
                originalData: originalData,
                chargeType: chargeType,
                customers: customers,
                chargeTypeApplicability: chargeTypeApplicability,
                loading: false,
                setupSurcharges: setupSurcharges.map((x) => {
                    return { label: x.description, value: x.id };
                }),
                resGroups: (resGroups = resGroups.map((x) => {
                    return { label: x.groupName, value: x.id };
                })),
                groups: groups.map((x) => {
                    return { label: x.groupName, value: x.id };
                }),
                units: units.map((x) => {
                    return { label: x.description, value: x.id };
                }),
                equipmentTypes: (equipmentTypes ?? []).map((x) => {                    
                    return { label: x.description, value: x.id, isAFAD: x.equipmentTypeAFADType.isAFAD };
                }),
                statuses: (statuses ?? []).map((x) => {
                    return { label: x.description, value: x.id };
                }),
                saving: false,
            };
        });
    }

    onExceptionFormClosed = () => this.setState({ showExceptionForm: false });
    onRateFormClosed = () => this.setState({ showRateForm: false });
    onClearErrors = () =>
        this.setState((state) => {
            return { errors: {} };
        });
    setSaving = (b) => this.setState({ saving: b });
    handleSaveError = (err) => handleFormSaveError(this, err);

    //If breaks are turned off, we should clear whether or not they are paid,
    //as well as clearing out the description.
    processCanBreak = (entity, checked) => {
        entity.canBreak = checked;
        entity.paidBreak = !checked ? false : entity.paidBreak;
        entity.breakDescription = !checked ? '' : entity.breakDescription;
        entity.breakHoursExceededThreshold = !checked
            ? 0
            : entity.breakHoursExceededThreshold;
        return entity;
    };

    handlers = {
        baseConfig: {
            canBreak: (e) => {
                let checked = e.target.checked;
                this.setState(
                    (state) => (
                        (state.chargeType.baseConfig = this.processCanBreak(
                            state.chargeType.baseConfig,
                            checked
                        )),
                        state
                    )
                );
            },
        },
        selectedException: {
            canBreak: (e) => {
                let checked = e.target.checked;
                this.setState(
                    (state) => (
                        (state.selectedException = this.processCanBreak(
                            state.selectedException,
                            checked
                        )),
                        state
                    )
                );
            },
            DNA: (e) => {
                let checked = e.target.checked,
                    selectedException = { ...this.state.selectedException };
                if (!checked) {
                    selectedException.increment = '';
                    selectedException.canBreak = false;
                    selectedException.paidBreak = false;
                    selectedException.breakDescription = '';
                    selectedException.minimumPayUnits = '';
                    selectedException.maximumPayUnits = '';
                }
                selectedException.doesNotApply = checked;
                this.setState({ selectedException: selectedException });
            },
        },
        change: onFieldChange,
        select: onReactSelectChanged,
        onChargeUnitsChanged: (s, e) => {
            //baseconfig only
            var { chargeType } = this.state;
            let val = (s ?? {}).value;
            chargeType.unitsId = val;
            //if flat clear out non flat fields and set to 1
            if (!!val && val == 2) {
                chargeType.baseConfig.minimumPayUnits = '';
                chargeType.baseConfig.maximumPayUnits = '';
                chargeType.baseConfig.breakDescription = '';
                chargeType.baseConfig.canBreak = false;
                chargeType.baseConfig.paidBreak = false;
            }

            chargeType.baseConfig.increment = '';
            chargeType.baseConfig.defaultValue = '';

            this.setState({
                chargeType: chargeType,
            });
        },
    };

    onAddException = () => {
        this.exceptionFormRef.current.resetForm(); //clear validation
        this.setState((state) => {
            state.selectedException = {
                ...new ChargeTypeConfiguration(),
                chargeTypeId: this.state.chargeType.id ?? null,
                chargeTypeConfigurationTypeId: 2,
            };
            state.showExceptionForm = true;
            return state;
        });
        this.context.setFormOpened(true); //lock all other page fields while form is open
    };

    onEditException = (exception) => {
        let cloned = util.object.clone(exception);
        this.exceptionFormRef.current.resetForm();
        this.setState({ selectedException: cloned, showExceptionForm: true });
        this.context.setFormOpened(true);
    };

    onExceptionSaved = () => {
        let { selectedException, chargeType } = this.state;

        if (chargeType.unitsId == 2) {
            selectedException.paidBreak = null;
            selectedException.canBreak = null;
            selectedException.breakDescription = null;
            selectedException.minimumPayUnits = null;
            selectedException.maximumPayUnits = null;
        }

        if (!!selectedException.doesNotApply) {
            selectedException.increment = null;
            selectedException.defaultValue = null;
            selectedException.paidBreak = null;
            selectedException.canBreak = null;
            selectedException.breakDescription = null;
            selectedException.minimumPayUnits = null;
            selectedException.maximumPayUnits = null;
        }

        if (!selectedException.breakHoursExceededThreshold) {
            selectedException.breakHoursExceededThreshold = 0;
        }

        chargeType.exceptions = util.array.upsert(
            selectedException,
            chargeType.exceptions,
            'id'
        );
        this.exceptionFormRef.current.resetForm();
        this.setState({
            chargeType: chargeType,
            showExceptionForm: false,
        });
    };

    onRateFormClosed = () => {
        this.rateFormRef.current.resetForm(); //clear validation
        this.context.setFormOpened(false); //lock all other page fields while form is open
        this.setState({
            showRateForm: false,
            showRateFormDelete: false,
        });
    };

    onAddRate = () => {
        this.rateFormRef.current.resetForm(); //clear validation
        this.setState((state) => {
            state.selectedRate = { ...new ChargeTypeBillingRate() };
            state.showRateForm = true;
            return state;
        });
        this.context.setFormOpened(true); //lock all other page fields while form is open
    };

    onEditRate = (rate) => {
        let cloned = util.object.clone(rate);
        this.rateFormRef.current.resetForm();
        this.setState({
            selectedRate: cloned,
            showRateForm: true,
            showRateFormDelete: true,
        });
        this.context.setFormOpened(true);
    };

    onRateSaved = () => {
        let { selectedRate, chargeType } = this.state;

        let rate = chargeType.billingRates.find(
            (x) =>
                !x.billingRate.expirationDate ||
                x.billingRate.expirationDate >=
                    selectedRate.billingRate.effectiveDate
        );
        if (!!rate) {
            //set the expiration date
            var effective = new Date(
                util.object.clone(selectedRate.billingRate.effectiveDate)
            );

            //see if any have same effective date
            if (selectedRate.billingRateId.constructor === String) {
                if (
                    new Date(rate.billingRate.effectiveDate).getDate() ==
                        effective.getDate() &&
                    rate.billingRateId !== selectedRate.billingRateId
                ) {
                    //pop notification?
                    this.rateFormRef.current.resetForm();
                    this.setState({
                        showRateForm: false,
                    });
                    return;
                } else {
                    //set it back one day.
                    effective.setDate(effective.getDate() - 1);
                    rate.billingRate.expirationDate = effective;
                }
            }
        }

        chargeType.billingRates = util.array.upsert(
            selectedRate,
            chargeType.billingRates ?? [],
            'billingRateId'
        );
        this.rateFormRef.current.resetForm();
        this.setState({
            chargeType: chargeType,
            showRateForm: false,
        });
    };

    onRateRemoved = () => {
        let { selectedRate, chargeType } = this.state;

        chargeType.billingRates = chargeType.billingRates.filter(
            (x) => x.billingRateId !== selectedRate.billingRateId
        );
        this.rateFormRef.current.resetForm();
        this.setState({
            chargeType: chargeType,
            showRateForm: false,
        });
    };

    onShowRateHistory = async (rate) => {
        //
        var history = await util.fetch.js(
            ApiRoutes.billingRate.chargeTypeBillingRateHistory(
                rate.chargeTypeId
            )
        );

        this.rateHistoryFormRef.current.resetForm();
        this.setState({
            selectedRateHistory: history,
            showRateHistoryForm: true,
        });
        this.context.setFormOpened(true); //lock all other page fields while form is open
    };

    onRateHistoryFormClosed = () => {
        this.rateFormRef.current.resetForm(); //clear validation
        this.context.setFormOpened(false); //lock all other page fields while form is open
        this.setState({
            showRateHistoryForm: false,
        });
    };

    additionalValidation = () => {
        const { chargeType } = this.state;
        let valid = true;

        if (
            (chargeType.baseConfig.maximumPayUnits ?? 0) <
            (chargeType.baseConfig.minimumPayUnits ?? 0)
        ) {
            document
                .getElementsByName(this.minPayRef.current.props.name)[0]
                .setCustomValidity('Invalid field.');
            document
                .getElementsByName(this.maxPayRef.current.props.name)[0]
                .setCustomValidity('Invalid field.');
            valid = false;
        }
        return valid;
    };

    onSubmit = (e) => {
        this.onClearErrors();
        this.setSaving(true);

        if (this.state.chargeType.baseConfig.isPermit && !this.state.chargeType.baseConfig.isSurcharge) {
            toast.error('Permit Fees must be configured as a surcharge.');
            this.setSaving(false);
            return;
        }

        //if (!this.additionalValidation()) {
        //    this.setSaving(false);
        //    return false;
        //}

        let cloned = util.object.clone(this.state.chargeType);

        /** Revert temporary hashes used in row keys.  Need this to get around
            fluent API.
        */
        if (cloned.baseConfig.id.constructor === String)
            cloned.baseConfig.id = null;

        for (let exception of cloned.exceptions) {
            exception.id =
                exception.id.constructor === String ? null : exception.id;
        }

        for (let rate of cloned.billingRates) {
            rate.billingRateId =
                rate.billingRateId.constructor === String
                    ? null
                    : rate.billingRateId;
            rate.billingRate.id =
                rate.billingRate.id.constructor === String
                    ? null
                    : rate.billingRate.id;
            rate.chargeTypeId =
                rate.chargeTypeId.constructor === String
                    ? null
                    : rate.chargeTypeId;
        }

        this.props.location.pathname == AppNavPaths.ChargeTypeNew
            ? this.create(cloned)
            : this.update(cloned);
    };

    update = async (chargeType) => {
        let response = await util.fetch
            .put(ApiRoutes.chargeType.byId(chargeType.id), chargeType)
            .catch(this.handleSaveError);

        if (response && parseInt(response.id ?? 0) >= 0) {
            this.setState({
                chargeType: response,
                originalData: response /* also restore this to reset Prompt */,
                saving: false,
                formValidated: false,
            });

            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header={'Save Successful'}
                    message={'Charge Type saved.'}
                />
            );
        }

        this.setSaving(false);
    };

    create = async (chargeType) => {
        chargeType.id = null;

        let response = await util.fetch
            .post(ApiRoutes.chargeType.create(), chargeType)
            .catch(this.handleSaveError);

        if (response && !isNaN(response)) {
            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header={'Save Successful'}
                    message={`Saved.`}
                />
            );
            util.navigation.localRedirect(
                this,
                `${AppNavPaths.ChargeType}/${response}`
            );
        }

        this.setSaving(false);
    };

    hasAFADEquipment = () => {
        const { chargeType, equipmentTypes } = this.state;

        if (!chargeType?.equipment || chargeType.equipment.length < 1 || chargeType.resourceTypeId !== 2) {
            return false;
        }

        let i = 0;
        const len = chargeType.equipment.length;

        for (; i < len; i += 1) {
            const equipmentTypeId = chargeType.equipment[i];
            const equipment = equipmentTypes.find(type => type.value === equipmentTypeId);

            if (!equipment?.isAFAD) {
                return false;
            }
        }

        return true;
    };

    renderForm() {
        //reducers
        const {
            billableOptions,
            chargeType,
            increments,
            units,
            resTypes,
            resGroups,
            setupSurcharges,
            groups,
            statuses,
            equipmentTypes,
            customers,
            chargeTypeApplicability,
            originalData,
            saving,
            showRateFormDelete,
        } = this.state;
        let newChargeType = parseInt(chargeType.id ?? 0) <= 0;

        if (!chargeType) return '';

        let baseConfig = chargeType.baseConfig,
            unitsName = chargeType.unitsId
                ? chargeType.unitsId === 1
                    ? 'Hours'
                    : 'Units'
                : '';

        const tenantSettings = this.context?.tenant?.tenantSettings || {};

        const {
            billingEnabled,
            billingRatesEnabled,
            useLimitedApprovals,
            allowDefaultChargeTypeValues,
            enableAFADChargeTypeGrouping,
        } = tenantSettings;

        return (
            <Fragment>
                <Prompt
                    when={!saving && !isEqual(originalData, chargeType)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={'chargeTypeForm'}
                    formHeadingIcon={faFileInvoiceDollar}
                    formHeading={
                        newChargeType ? 'New Charge Type' : 'Edit Charge Type'
                    }
                    formName={'chargeTypeForm'}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={this.state.formValidated}
                    saving={this.state.saving}
                    errors={this.state.errors}
                    loading={this.state.loading}
                >
                    <SubHeading first={true}>Details</SubHeading>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="description"
                                    text="Charge Type Description"
                                    required={true}
                                />
                                <Input
                                    id="description"
                                    name="chargeType.description"
                                    value={chargeType.description ?? ''}
                                    onChange={this.handlers.change}
                                    placeholder="Enter description"
                                    type="text"
                                    required
                                />
                                <ValidationErrorMessage>
                                    Description is required.
                                </ValidationErrorMessage>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="isBillable"
                                    text="Billable"
                                    required={true}
                                />
                                <ValidatedSelect
                                    id="isBillable"
                                    name="chargeType.isBillable"
                                    required
                                    options={billableOptions}
                                    value={
                                        billableOptions.find(
                                            (bo) =>
                                                bo.value ===
                                                chargeType.isBillable
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.select}
                                    validationMessage="Choose whether or not this charge type is billable."
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="units"
                                    text="Charge Units"
                                    required={true}
                                />
                                <ValidatedSelect
                                    id="units"
                                    name="chargeType.unitsId"
                                    required
                                    isDisabled={
                                        (chargeType.exceptions ?? []).length > 0
                                    }
                                    options={units}
                                    value={
                                        (units ?? []).find(
                                            (s) =>
                                                s.value === chargeType.unitsId
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.onChargeUnitsChanged.bind(
                                        this
                                    )}
                                    validationMessage="A units selection is required."
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="information"
                                    text="Info Description"
                                    required={true}
                                />
                                <textarea
                                    id="information"
                                    name="chargeType.information"
                                    className="form-control"
                                    value={chargeType.information ?? ''}
                                    onChange={this.handlers.change}
                                    placeholder="Enter text"
                                    type="text"
                                    rows="5"
                                    required
                                />
                                <ValidationErrorMessage>
                                    Description is required.
                                </ValidationErrorMessage>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel htmlFor="status" text="Status" />
                                <Select
                                    id="status"
                                    name="chargeType.statusId"
                                    required
                                    options={statuses}
                                    value={
                                        (statuses ?? []).find(
                                            (x) =>
                                                x.value === chargeType.statusId
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.select}
                                />
                                <ValidationErrorMessage>
                                    At least one equipment type is required.
                                </ValidationErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="applicability"
                                    text="Charge Usage"
                                />
                                <Select
                                    id="applicability"
                                    name="chargeType.applicabilityId"
                                    options={chargeTypeApplicability}
                                    value={
                                        (chargeTypeApplicability ?? []).find(
                                            (x) =>
                                                x.value ===
                                                chargeType.applicabilityId
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.select}
                                />
                            </FormGroup>
                        </FormGroupColumn>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="resType"
                                    text="Resource Type"
                                    required={true}
                                />
                                <ValidatedSelect
                                    id="resType"
                                    name="chargeType.resourceTypeId"
                                    required
                                    options={resTypes}
                                    isDisabled={!newChargeType}
                                    value={
                                        (resTypes ?? []).find(
                                            (x) =>
                                                x.value ===
                                                chargeType.resourceTypeId
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.select}
                                    validationMessage="A type is required"
                                />
                            </FormGroup>

                            {chargeType.resourceTypeId != null && <hr />}

                            {chargeType.resourceTypeId == 5 && (
                                <>
                                    <FormGroup>
                                        <FormLabel text="Resource Type Options" />
                                   
                                        <FormCheckbox
                                            id="chargeType.baseConfig.isPermit"
                                            name="chargeType.baseConfig.isPermit"
                                            checked={
                                                baseConfig.isPermit ?? ''
                                            }
                                            onChange={this.handlers.change}
                                            labelText={
                                                'Is Permit Fee'
                                            }
                                        />
                                    </FormGroup>

                                    {!baseConfig.isPermit && !baseConfig.isSurcharge &&

                                        <FormGroup>
                                            <FormLabel text="Setup Surcharge"/>
                                            <Select
                                                id="chargeType.baseConfig.childChargeTypeId"
                                                name="chargeType.baseConfig.childChargeTypeId"
                                                isClearable={true}
                                                options={setupSurcharges}
                                                value={
                                                    (setupSurcharges ?? []).find(
                                                        (x) =>
                                                            x.value ===
                                                            baseConfig.childChargeTypeId
                                                    ) ?? ''
                                                }
                                                onChange={this.handlers.select}
                                            />

                                        </FormGroup>
                                    }
                                </>
                            )}

                            {chargeType.resourceTypeId == 1 && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="groups"
                                        text="Eligible Resource Groups"
                                        required={
                                            chargeType.resourceTypeId === 1 &&
                                            (chargeType.groups ?? []).length ===
                                                0
                                        }
                                    />
                                    <Select
                                        id="resGroups"
                                        name="chargeType.resourceGroupId"
                                        isClearable={true}
                                        isDisabled={
                                            (chargeType.groups ?? []).length > 0
                                        }
                                        required={
                                            chargeType.resourceTypeId === 1 &&
                                            (chargeType.groups ?? []).length ===
                                                0
                                        }
                                        options={resGroups}
                                        value={
                                            (resGroups ?? []).find(
                                                (x) =>
                                                    x.value ===
                                                    chargeType.resourceGroupId
                                            ) ?? ''
                                        }
                                        onChange={this.handlers.select}
                                    />
                                    <ValidationErrorMessage>
                                        A group selection is required.
                                    </ValidationErrorMessage>
                                </FormGroup>
                            )}

                            {chargeType.resourceTypeId == 1 && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="groups"
                                        text="Eligible Employee Roles"
                                        required={
                                            chargeType.resourceTypeId === 1 &&
                                            (chargeType.resourceGroupId ===
                                                null ||
                                                chargeType.resourceGroupId ===
                                                    0 ||
                                                chargeType.resourceGroupId ===
                                                    undefined)
                                        }
                                    />
                                    <Select
                                        id="groups"
                                        name="chargeType.groups"
                                        isDisabled={
                                            chargeType.resourceGroupId !==
                                                null &&
                                            chargeType.resourceGroupId !== 0 &&
                                            chargeType.resourceGroupId !==
                                                undefined
                                        }
                                        isMulti
                                        required={
                                            chargeType.resourceTypeId === 1 &&
                                            (chargeType.resourceGroupId ===
                                                null ||
                                                chargeType.resourceGroupId ===
                                                    0 ||
                                                chargeType.resourceGroupId ===
                                                    undefined)
                                        }
                                        options={groups}
                                        value={
                                            (groups ?? []).filter((x) =>
                                                (
                                                    chargeType.groups ?? []
                                                ).includes(x.value)
                                            ) ?? ''
                                        }
                                        onChange={this.handlers.select}
                                    />
                                    <ValidationErrorMessage>
                                        At least one role selection is required.
                                    </ValidationErrorMessage>
                                </FormGroup>
                            )}

                            {chargeType.resourceTypeId == 2 && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="equipmentTypes"
                                        text="Eligible Equipment Types"
                                        required={
                                            chargeType.resourceTypeId == 2
                                        }
                                    />
                                    <Select
                                        id="equipment"
                                        name="chargeType.equipment"
                                        isMulti
                                        required={
                                            chargeType.resourceTypeId == 2
                                        }
                                        options={equipmentTypes}
                                        value={
                                            (equipmentTypes ?? []).filter((x) =>
                                                (
                                                    chargeType.equipment ?? []
                                                ).includes(x.value)
                                            ) ?? ''
                                        }
                                        onChange={this.handlers.select}
                                    />
                                    <ValidationErrorMessage>
                                        At least one equipment type is required.
                                    </ValidationErrorMessage>
                                </FormGroup>
                            )}

                            {enableAFADChargeTypeGrouping === true && this.hasAFADEquipment() && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="numAFADsInSet"
                                        text="# of AFADs in a set"
                                        required={
                                            chargeType.resourceTypeId == 2
                                        }
                                    />
                                    <Input
                                        className="ml-3 w-auto"
                                        id="chargeType.baseConfig.numAFADsInSet"
                                        name="chargeType.baseConfig.numAFADsInSet"
                                        value={
                                            baseConfig.numAFADsInSet ??
                                            1
                                        }
                                        onChange={
                                            this.handlers.change
                                        }
                                        placeholder="Enter 1 if AFADs are billed individually"
                                        type="number"
                                        min={1}
                                        required={
                                            chargeType.resourceTypeId == 2
                                        }
                                    />
                                    <ValidationErrorMessage>
                                        A value of at least 1 must be entered.
                                    </ValidationErrorMessage>
                                </FormGroup>
                            )}
                        </FormGroupColumn>
                    </GroupedRow>

                    {chargeType.resourceTypeId == 1 &&
                        (tenantSettings ?? {}).useLimitedApprovals && (
                            <>
                                <SubHeading>
                                    Timesheet Security Groups
                                </SubHeading>
                                <GroupedRow>
                                    <FormGroupColumn>
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="groups"
                                                text="Approver Groups"
                                            />
                                            <Select
                                                id="approverGroups"
                                                name="chargeType.approveLimitedApprovalGroups"
                                                isMulti
                                                options={groups}
                                                value={
                                                    (groups ?? []).filter((x) =>
                                                        (
                                                            chargeType.approveLimitedApprovalGroups ??
                                                            []
                                                        ).includes(x.value)
                                                    ) ?? ''
                                                }
                                                onChange={this.handlers.select}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="groups"
                                                text="Rejection Groups"
                                            />
                                            <Select
                                                id="rejectionGroups"
                                                name="chargeType.rejectionLimitedApprovalGroups"
                                                isMulti
                                                options={groups}
                                                value={
                                                    (groups ?? []).filter((x) =>
                                                        (
                                                            chargeType.rejectionLimitedApprovalGroups ??
                                                            []
                                                        ).includes(x.value)
                                                    ) ?? ''
                                                }
                                                onChange={this.handlers.select}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="groups"
                                                text="Editor Groups"
                                            />
                                            <Select
                                                id="rejectionGroups"
                                                name="chargeType.editorLimitedApprovalGroups"
                                                isMulti
                                                options={groups}
                                                value={
                                                    (groups ?? []).filter((x) =>
                                                        (
                                                            chargeType.editorLimitedApprovalGroups ??
                                                            []
                                                        ).includes(x.value)
                                                    ) ?? ''
                                                }
                                                onChange={this.handlers.select}
                                            />
                                        </FormGroup>
                                    </FormGroupColumn>
                                </GroupedRow>
                            </>
                        )}

                    <SubHeading>Configuration</SubHeading>
                    <GroupedRow>
                        {chargeType.unitsId == 1 && (
                            <>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel text="Surcharge Options" />
                                        <FlexRow className="ml-3">
                                            <FormCheckbox
                                                id="chargeType.baseConfig.isSurcharge"
                                                name="chargeType.baseConfig.isSurcharge"
                                                checked={
                                                    baseConfig.isSurcharge ?? ''
                                                }
                                                onChange={this.handlers.change}
                                                labelText={
                                                    'Surcharge Charge Type'
                                                }
                                            />
                                        </FlexRow>
                                    </FormGroup>
                                    {baseConfig.isSurcharge && (
                                        <>
                                            <FormGroup>
                                                <FormLabel
                                                    className="ml-3"
                                                    text="Bill Value"
                                                    required={true}
                                                />
                                                <Input
                                                    className="ml-3 w-auto"
                                                    id="chargeType.baseConfig.surchargeBillAmount"
                                                    name="chargeType.baseConfig.surchargeBillAmount"
                                                    value={
                                                        baseConfig.surchargeBillAmount ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Bill Value"
                                                    type="number"
                                                    required={
                                                        baseConfig.surchargeBillAmount >=
                                                        0
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Bill Value is required.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    className="ml-3"
                                                    text="Pay Value"
                                                    required={true}
                                                />
                                                <Input
                                                    className="ml-3 w-auto"
                                                    id="chargeType.baseConfig.surchargePayAmount"
                                                    name="chargeType.baseConfig.surchargePayAmount"
                                                    value={
                                                        baseConfig.surchargePayAmount ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Pay Value"
                                                    type="number"
                                                    required={
                                                        !!baseConfig.surchargePayAmount >=
                                                        0
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Pay Value is required.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                        </>
                                    )}
                                    {!baseConfig.isSurcharge && (
                                        <>
                                            <FormGroup>
                                                <FormLabel text="Increment" />
                                                <ValidatedSelect
                                                    name="chargeType.baseConfig.increment"
                                                    required
                                                    options={increments}
                                                    value={
                                                        (increments ?? []).find(
                                                            (i) =>
                                                                parseFloat(
                                                                    i.value
                                                                ) ===
                                                                parseFloat(
                                                                    baseConfig.increment ??
                                                                        '0'
                                                                )
                                                        ) ?? ''
                                                    }
                                                    onChange={
                                                        this.handlers.select
                                                    }
                                                    validationMessage="Increment is required."
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel text="Hours Warning Threshold" />
                                                <Input
                                                    name="chargeType.baseConfig.hoursExceededThreshold"
                                                    value={
                                                        baseConfig.hoursExceededThreshold ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Hours Warning Threshold"
                                                    type="number"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel text="Break Options" />
                                                <FlexRow className="ml-3">
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.canBreak"
                                                        checked={
                                                            baseConfig.canBreak ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers
                                                                .baseConfig
                                                                .canBreak
                                                        }
                                                        labelText={
                                                            'Enable Breaks'
                                                        }
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        className="ml-2 mr-2"
                                                    />
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.paidBreak"
                                                        name="chargeType.baseConfig.paidBreak"
                                                        disabled={
                                                            !baseConfig.canBreak
                                                        }
                                                        checked={
                                                            baseConfig.paidBreak ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={'Paid Break'}
                                                    />
                                                </FlexRow>
                                            </FormGroup>
                                            <FormGroup
                                                className={cls('ml-3', {
                                                    'opacity-half':
                                                        !baseConfig.canBreak,
                                                })}
                                            >
                                                <FormLabel
                                                    text="Break Description"
                                                    required={
                                                        !!baseConfig.canBreak
                                                    }
                                                />
                                                <Input
                                                    name="chargeType.baseConfig.breakDescription"
                                                    value={
                                                        baseConfig.breakDescription ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Break description"
                                                    type="text"
                                                    required={
                                                        !!baseConfig.canBreak
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Description is required.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup
                                                className={cls('ml-3', {
                                                    'opacity-half':
                                                        !baseConfig.canBreak,
                                                })}
                                            >
                                                <FormLabel text="Break Warning Threshold" />
                                                <Input
                                                    name="chargeType.baseConfig.breakHoursExceededThreshold"
                                                    value={
                                                        baseConfig.breakHoursExceededThreshold ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Break Warning Threshold"
                                                    type="number"
                                                />
                                            </FormGroup>
                                        </>
                                    )}
                                    <FormGroup>
                                        <FormLabel text="Cancellation Options" />
                                        {(tenantSettings ?? {})
                                            .blockBillingCancelEnabled && (
                                            <FormGroup>
                                                <FlexRow className="ml-3">
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.blockBillingCancel"
                                                        name="chargeType.baseConfig.blockBillingCancel"
                                                        checked={
                                                            baseConfig.blockBillingCancel ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={
                                                            'Block Cancellation Min Bill Qty if Charge > 0 for Billing'
                                                        }
                                                    />
                                                </FlexRow>
                                            </FormGroup>
                                        )}
                                        {(tenantSettings ?? {})
                                            .blockPayrollCancelEnabled && (
                                            <FormGroup>
                                                <FlexRow className="ml-3">
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.blockPayrollCancel"
                                                        name="chargeType.baseConfig.blockPayrollCancel"
                                                        checked={
                                                            baseConfig.blockPayrollCancel ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={
                                                            'Block Cancellation Min Pay Qty if Charge > 0 for Payroll'
                                                        }
                                                    />
                                                </FlexRow>
                                            </FormGroup>
                                        )}
                                        <FormLabel
                                            className="ml-3"
                                            text="Cancellation Time Threshold (Min)"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <Input
                                            className="ml-3 w-auto"
                                            name="chargeType.baseConfig.cancellationTimeThreshold"
                                            value={
                                                baseConfig.cancellationTimeThreshold ??
                                                ''
                                            }
                                            onChange={this.handlers.change}
                                            placeholder="Enter in Minutes"
                                            type="number"
                                            step="1"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <ValidationErrorMessage>
                                            Description is required.
                                        </ValidationErrorMessage>
                                    </FormGroup>
                                    <FormGroup className="ml-3">
                                        <FormLabel
                                            text="Minimum Cancellation Bill Qty (Hours)"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <Input
                                            name="chargeType.baseConfig.cancellationBillQty"
                                            value={
                                                baseConfig.cancellationBillQty ??
                                                ''
                                            }
                                            onChange={this.handlers.change}
                                            placeholder="Enter Qty in Hours"
                                            type="number"
                                            step=".025"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <ValidationErrorMessage>
                                            Description is required.
                                        </ValidationErrorMessage>
                                    </FormGroup>
                                </FormGroupColumn>
                                {!baseConfig.isSurcharge && (
                                    <>
                                        <FormGroupColumn>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Minimum Pay`}
                                                    required={
                                                        !!baseConfig.minimumPayUnits
                                                    }
                                                />
                                                <NumericInput
                                                    ref={this.minPayRef}
                                                    name="chargeType.baseConfig.minimumPayUnits"
                                                    value={
                                                        baseConfig.minimumPayUnits ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                    max={
                                                        baseConfig.maximumPayUnits ??
                                                        ''
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Min pay must be less than or
                                                    equal to max pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Maximum Pay`}
                                                    required={
                                                        !!baseConfig.maximumPayUnits
                                                    }
                                                />
                                                <NumericInput
                                                    ref={this.maxPayRef}
                                                    name="chargeType.baseConfig.maximumPayUnits"
                                                    value={
                                                        baseConfig.maximumPayUnits ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                />
                                                <ValidationErrorMessage>
                                                    Max pay must be greater than
                                                    or equal to min pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Minimum Bill`}
                                                    required={
                                                        !!baseConfig.minimumBillUnits
                                                    }
                                                />
                                                <NumericInput
                                                    ref={this.minPayRef}
                                                    name="chargeType.baseConfig.minimumBillUnits"
                                                    value={
                                                        baseConfig.minimumBillUnits ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                    max={
                                                        baseConfig.maximumBillUnits ??
                                                        ''
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Min bill must be less than
                                                    or equal to max pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Maximum Bill`}
                                                    required={
                                                        !!baseConfig.maximumBillUnits
                                                    }
                                                />
                                                <NumericInput
                                                    ref={this.maxPayRef}
                                                    name="chargeType.baseConfig.maximumBillUnits"
                                                    value={
                                                        baseConfig.maximumBillUnits ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                />
                                                <ValidationErrorMessage>
                                                    Max bill must be greater
                                                    than or equal to min pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            {(tenantSettings ?? {})
                                                .useStateMinimum && (
                                                <FormGroup>
                                                    <FormLabel text="State Minimum" />
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.isStateMinimum"
                                                        name="chargeType.baseConfig.isStateMinimum"
                                                        checked={
                                                            baseConfig.isStateMinimum ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={
                                                            'Use State Minimum Pay Category'
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                            <FormGroup>
                                                <FormLabel text="Timesheet Options" />
                                                <FormCheckbox
                                                    id="chargeType.baseConfig.disallowNA"
                                                    name="chargeType.baseConfig.disallowNA"
                                                    checked={
                                                        baseConfig.disallowNA ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    labelText={
                                                        'Require Data Entry on Timesheet (Do not allow N/A reply)'
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel text="Invoicing" />
                                                <FormCheckbox
                                                    id="chargeType.baseConfig.omitFromInvoice"
                                                    name="chargeType.baseConfig.omitFromInvoice"
                                                    checked={
                                                        baseConfig.omitFromInvoice ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    labelText={
                                                        'Omit From Charge Details On Invoice'
                                                    }
                                                />
                                            </FormGroup>
                                        </FormGroupColumn>
                                    </>
                                )}
                            </>
                        )}

                        {chargeType.unitsId == 2 && (
                            <>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel text="Surcharge Options" />
                                        <FlexRow className="ml-3">
                                            <FormCheckbox
                                                id="chargeType.baseConfig.isSurcharge"
                                                name="chargeType.baseConfig.isSurcharge"
                                                checked={
                                                    baseConfig.isSurcharge ?? ''
                                                }
                                                onChange={this.handlers.change}
                                                labelText={
                                                    'Surcharge Charge Type'
                                                }
                                            />
                                        </FlexRow>
                                    </FormGroup>
                                    {baseConfig.isSurcharge && (
                                        <>
                                            <FormGroup>
                                                <FormLabel
                                                    className="ml-3"
                                                    text="Bill Value"
                                                    required={true}
                                                />
                                                <Input
                                                    className="ml-3 w-auto"
                                                    id="chargeType.baseConfig.surchargeBillAmount"
                                                    name="chargeType.baseConfig.surchargeBillAmount"
                                                    value={
                                                        baseConfig.surchargeBillAmount ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Bill Value"
                                                    type="number"
                                                    required={
                                                        !!baseConfig.surchargeBillAmount >=
                                                        0
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Bill Value is required.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    className="ml-3"
                                                    text="Pay Value"
                                                    required={true}
                                                />
                                                <Input
                                                    className="ml-3 w-auto"
                                                    id="chargeType.baseConfig.surchargePayAmount"
                                                    name="chargeType.baseConfig.surchargePayAmount"
                                                    value={
                                                        baseConfig.surchargePayAmount ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder="Pay Value"
                                                    type="number"
                                                    required={
                                                        !!baseConfig.surchargePayAmount >=
                                                        0
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Pay Value is required.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                        </>
                                    )}
                                    {!baseConfig.isSurcharge && (
                                        <>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Increment`}
                                                    required={
                                                        !!baseConfig.increment
                                                    }
                                                />
                                                <NumericInput
                                                    name="chargeType.baseConfig.increment"
                                                    value={
                                                        baseConfig.increment ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                    required
                                                />
                                                <ValidationErrorMessage>
                                                    Min pay must be less than or
                                                    equal to max pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                        </>
                                    )}

                                    {allowDefaultChargeTypeValues && (
                                        <FormGroup>
                                            <FormLabel text={`Default Value`} />
                                            <NumericInput
                                                name="chargeType.baseConfig.defaultValue"
                                                value={
                                                    baseConfig.defaultValue ??
                                                    ''
                                                }
                                                onChange={this.handlers.change}
                                                placeholder={`Enter ${unitsName}`}
                                                type="number"
                                                step="0.25"
                                                required
                                            />
                                        </FormGroup>
                                    )}

                                    <FormGroup>
                                        <FormLabel text="Cancellation Options" />
                                        {(tenantSettings ?? {})
                                            .blockBillingCancelEnabled && (
                                            <FormGroup>
                                                <FlexRow className="ml-3">
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.blockBillingCancel"
                                                        name="chargeType.baseConfig.blockBillingCancel"
                                                        checked={
                                                            baseConfig.blockBillingCancel ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={
                                                            'Block Cancellation Min Bill Qty if Charge > 0 for Billing'
                                                        }
                                                    />
                                                </FlexRow>
                                            </FormGroup>
                                        )}
                                        {(tenantSettings ?? {})
                                            .blockPayrollCancelEnabled && (
                                            <FormGroup>
                                                <FlexRow className="ml-3">
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.blockPayrollCancel"
                                                        name="chargeType.baseConfig.blockPayrollCancel"
                                                        checked={
                                                            baseConfig.blockPayrollCancel ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={
                                                            'Block Cancellation Min Pay Qty if Charge > 0 for Payroll'
                                                        }
                                                    />
                                                </FlexRow>
                                            </FormGroup>
                                        )}
                                        <FormLabel
                                            className="ml-3"
                                            text="Cancellation Time Threshold (Min)"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <Input
                                            className="ml-3 w-auto"
                                            name="chargeType.baseConfig.cancellationTimeThreshold"
                                            value={
                                                baseConfig.cancellationTimeThreshold ??
                                                ''
                                            }
                                            onChange={this.handlers.change}
                                            placeholder="Enter in Minutes"
                                            type="number"
                                            step="1"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <ValidationErrorMessage>
                                            Description is required.
                                        </ValidationErrorMessage>
                                    </FormGroup>
                                    <FormGroup className="ml-3">
                                        <FormLabel
                                            text={`Minimum Cancellation Bill Qty (Flat)`}
                                            required={!!baseConfig.canBreak}
                                        />
                                        <Input
                                            name="chargeType.baseConfig.cancellationBillQty"
                                            value={
                                                baseConfig.cancellationBillQty ??
                                                ''
                                            }
                                            onChange={this.handlers.change}
                                            placeholder="Enter Qty Flat"
                                            type="number"
                                            step=".025"
                                            required={!!baseConfig.canBreak}
                                        />
                                        <ValidationErrorMessage>
                                            Description is required.
                                        </ValidationErrorMessage>
                                    </FormGroup>
                                </FormGroupColumn>

                                {!baseConfig.isSurcharge && (
                                    <>
                                        <FormGroupColumn>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Minimum Bill`}
                                                    required={
                                                        !!baseConfig.minimumBillUnits
                                                    }
                                                />
                                                <NumericInput
                                                    ref={this.minPayRef}
                                                    name="chargeType.baseConfig.minimumBillUnits"
                                                    value={
                                                        baseConfig.minimumBillUnits ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                    max={
                                                        baseConfig.maximumBillUnits ??
                                                        ''
                                                    }
                                                />
                                                <ValidationErrorMessage>
                                                    Min bill must be less than
                                                    or equal to max pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    text={`Maximum Bill`}
                                                    required={
                                                        !!baseConfig.maximumBillUnits
                                                    }
                                                />
                                                <NumericInput
                                                    ref={this.maxPayRef}
                                                    name="chargeType.baseConfig.maximumBillUnits"
                                                    value={
                                                        baseConfig.maximumBillUnits ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    placeholder={`Enter  ${unitsName}`}
                                                    type="number"
                                                    step="0.25"
                                                />
                                                <ValidationErrorMessage>
                                                    Max bill must be greater
                                                    than or equal to min pay.
                                                </ValidationErrorMessage>
                                            </FormGroup>
                                            {(tenantSettings ?? {})
                                                .useStateMinimum && (
                                                <FormGroup>
                                                    <FormLabel text="State Minimum" />
                                                    <FormCheckbox
                                                        id="chargeType.baseConfig.isStateMinimum"
                                                        name="chargeType.baseConfig.isStateMinimum"
                                                        checked={
                                                            baseConfig.isStateMinimum ??
                                                            ''
                                                        }
                                                        onChange={
                                                            this.handlers.change
                                                        }
                                                        labelText={
                                                            'Use State Minimum'
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                            <FormGroup>
                                                <FormLabel text="Timesheet Options" />
                                                <FormCheckbox
                                                    id="chargeType.baseConfig.disallowNA"
                                                    name="chargeType.baseConfig.disallowNA"
                                                    checked={
                                                        baseConfig.disallowNA ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    labelText={
                                                        'Require Data Entry on Timesheet (Do not allow N/A reply)'
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel text="Invoicing" />
                                                <FormCheckbox
                                                    id="chargeType.baseConfig.omitFromInvoice"
                                                    name="chargeType.baseConfig.omitFromInvoice"
                                                    checked={
                                                        baseConfig.omitFromInvoice ??
                                                        ''
                                                    }
                                                    onChange={
                                                        this.handlers.change
                                                    }
                                                    labelText={
                                                        'Omit From Charge Details On Invoice'
                                                    }
                                                />
                                            </FormGroup>
                                        </FormGroupColumn>
                                    </>
                                )}
                            </>
                        )}
                    </GroupedRow>
                    {chargeType.id && (
                        <>
                            <SubHeading>Exceptions</SubHeading>
                            <GroupedRow>
                                <ChargeTypeConfigurationInfo
                                    chargeUnitId={chargeType.unitsId}
                                    increments={increments}
                                    incrementRequired={true}
                                    customers={customers}
                                    exceptions={chargeType.exceptions}
                                    onAddException={this.onAddException}
                                    onEditException={this.onEditException}
                                />
                            </GroupedRow>
                        </>
                    )}

                    {chargeType.id &&
                        chargeType.isBillable &&
                        billingEnabled &&
                        billingRatesEnabled && (
                            <>
                                <SubHeading>Billing Rates</SubHeading>
                                <GroupedRow>
                                    <BillingRateGrid
                                        rates={_.orderBy(
                                            chargeType.billingRates,
                                            (x) => [
                                                x.chargeTypeName,
                                                x.billingRate.effectiveDate,
                                            ]
                                        )}
                                        onAddRate={this.onAddRate}
                                        onEditRate={this.onEditRate}
                                        onShowRateHistory={
                                            this.onShowRateHistory
                                        }
                                    />
                                </GroupedRow>
                            </>
                        )}
                    <FlexCenterRow className="mb-3">
                        <Button
                            size="sm"
                            type="submit"
                            color="primary"
                            name="chargeTypeForm"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                            {!!newChargeType ? 'Save New Charge Type' : 'Save'}
                        </Button>
                    </FlexCenterRow>
                </AppPageForm>
                <ChargeTypeConfigurationForm
                    ref={this.exceptionFormRef}
                    id="chargeTypeConfigForm"
                    requireIncrement={true}
                    entity={this.state.selectedException}
                    entityName={'selectedException'}
                    customers={this.state.customers}
                    increments={increments}
                    chargeUnitId={chargeType.unitsId}
                    show={this.state.showExceptionForm}
                    onClose={this.onExceptionFormClosed}
                    onSaveCallback={this.onExceptionSaved}
                    onChange={this.handlers.change}
                    onSelectChange={this.handlers.select}
                    onCanBreakChanged={this.handlers.selectedException.canBreak}
                    onDoesNotApplyChanged={this.handlers.selectedException.DNA}
                    tenantSettings={tenantSettings}
                />

                {billingEnabled && billingRatesEnabled && (
                    <>
                        <BillingRateForm
                            ref={this.rateFormRef}
                            id="billingRateForm"
                            entity={this.state.selectedRate}
                            entityName={'selectedRate'}
                            show={this.state.showRateForm}
                            onClose={this.onRateFormClosed}
                            onSaveCallback={this.onRateSaved}
                            onRemoveCallback={this.onRateRemoved}
                            onChange={this.handlers.change}
                            onSelectChange={this.handlers.select}
                            showRemove={showRateFormDelete}
                        />
                        <BillingRateHistoryForm
                            ref={this.rateHistoryFormRef}
                            id="billingRateHistoryForm"
                            entity={this.state.selectedRateHistory}
                            show={this.state.showRateHistoryForm}
                            onClose={this.onRateHistoryFormClosed}
                        />
                    </>
                )}
            </Fragment>
        );
    }

    render() {
        if (!!this.state.loading)
            return <LinearProgress variant="indeterminate" color="secondary" />;
        return this.renderForm();
    }
}
export default withRouter(ChargeTypeForm);
