import React from 'react';
import { util } from '../Util';

const propertyShouldNotBeDisplayed = (propertyChange) =>
    propertyChange.property === 'Id' ||
    propertyChange.property === 'SystemAdded';

const renderValue = (propertyChange, jsPropertyName, renderOptions) => {
    const propertyValue = propertyChange[jsPropertyName];

    if (propertyValue && propertyValue.startsWith('1/1/0001')) {
        return '';
    }

    if (!renderOptions) {
        return propertyValue;
    }

    if (
        renderOptions.simpleDate &&
        propertyValue &&
        propertyChange.propertyType.includes('DateTime')
    ) {
        const propertyDate = new Date(propertyValue);

        return propertyDate.toLocaleDateString();
    }

    return propertyValue;
};

export default function PropertyChange(props) {
    const { propertyChange, renderOptions } = props;

    if (!propertyChange || propertyShouldNotBeDisplayed(propertyChange)) {
        return <></>;
    }

    return (
        <tr>
            <td>
                {util.string.addSpacesToProperCase(propertyChange.property)}
            </td>
            <td>
                {renderValue(propertyChange, 'previousValue', renderOptions)}
            </td>
            <td>{renderValue(propertyChange, 'newValue', renderOptions)}</td>
        </tr>
    );
}
