import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Can } from '../Can'
import { SmallButton } from '../common/forms/FormElements';
import CommonContext from '../Common';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { util } from '../Util';


export class BillingRateGrid extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showForm: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false
        });
    }
       
    render() {
        const { tenantSettings } = ((this.context ?? {}).tenant ?? {});

        if (!tenantSettings.billingRatesEnabled) return "";

        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />)
        } else {

            let { rates, history } = this.props;

            return (
                <Container fluid>
                    <Row className="pb-1">
                        <Col className="ml-0 mr-0">
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <Can I="edit" a="chargetype">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    onClick={this.props.onAddRate}
                                                >
                                                    <i className="fa fa-plus-circle fa-md mr-2" />Add Rate
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>

                                </Can>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-0 mr-0">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                        {(this.props.showChargeType ?? {}) == true &&
                                            <th style={{ width: '15%' }}>Charge Type</th>
                                        }
                                        <th style={{ width: '15%' }}>Rate</th>
                                        <th style={{ width: '15%' }}>Overtime Rate</th>
                                        <th style={{ width: '15%' }}>Alternative Rate</th>
                                        <th style={{ width: '20%' }}>Effective Date</th>
                                        <th style={{ width: '20%' }}>Expiration Date</th>
                                        <th style={{ width: '5%' }}>Active</th>
                                        {/*<th style={{ width: '5%' }}>History</th>*/}
                                        <Can I="edit" a="chargetype">
                                            <th className="text-center" style={{ width: '5%' }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </th>
                                        </Can>
                                    </tr>                                 
                                </thead>
                                <tbody>
                                    {!(rates ?? []).length
                                        ? <tr><td colSpan="9" className="text-center">No rates found.</td></tr>
                                        : this.props.rates.map((rate, i) => {
                                            return (
                                                <tr key={rate.billingRateId}>
                                                    {(this.props.showChargeType ?? {}) == true &&
                                                        <td>
                                                        {rate.chargeTypeName}
                                                        </td>
                                                    }
                                                    <td>
                                                        {!util.isEmpty(rate.billingRate.rate) && util.number.formatCurrency(rate.billingRate.rate)}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(rate.billingRate.otRate) && util.number.formatCurrency(rate.billingRate.otRate)}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(rate.billingRate.altRate) && util.number.formatCurrency(rate.billingRate.altRate)}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(rate.billingRate.effectiveDate) && util.date.getShortUTC(rate.billingRate.effectiveDate)}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(rate.billingRate.expirationDate) && util.date.getShortUTC(rate.billingRate.expirationDate)}
                                                    </td>
                                                    <td>
                                                        {(rate.billingRate.current ? 'Active' : 'Inactive')}
                                                    </td>
                                                    {/*<td>*/}
                                                    {/*    <SmallButton*/}
                                                    {/*        type="button"*/}
                                                    {/*        onClick={() => this.props.onShowRateHistory(rate)}*/}
                                                    {/*    >*/}
                                                    {/*        <i className="fa fa-history fa-md" />*/}
                                                    {/*    </SmallButton>*/}
                                                    {/*</td>*/}
                                                    <Can I="edit" a="chargetype">
                                                        <td className="text-center" style={{ width: '30px' }}>                                                        
                                                            <CommonContext.Consumer>
                                                                {(value) => {
                                                                    return (<Button
                                                                        disabled={!!value.formIsOpen}
                                                                        type="button"
                                                                        color="outline-primary"
                                                                        onClick={() => this.props.onEditRate(rate)}
                                                                        size="sm">Edit</Button>);
                                                                }}
                                                            </CommonContext.Consumer>                                                        
                                                        </td>
                                                    </Can>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>                    
                </Container>
            );
        }
    }
}