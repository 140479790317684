import React from 'react';
//Contexts
import { Can } from '../Can'
import CommonContext, { ApiRoutes } from '../Common';
import { StatusForm } from '../common/Generic Status/StatusForm';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { createDataSource, createGridOptions, DataGrid, indexCellRenderer } from '../common/dataGrid/DataGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import { FlexRow, FlexStartRow, FlexEndRow, PageHeading, SmallButton, PageWrap } from '../common/forms/FormElements';
import { Row, Col } from 'reactstrap';
import { util } from '../Util';
import { LocationType } from './LocationType';
import { withRouter } from 'react-router-dom';

class LocationTypeIndex extends React.Component {

    static contextType = CommonContext;

    // #region [ Constructor and Overrides ]

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: true,
            locationType: null,
            locationTypees: [],
            readOnly: true,
            formValidated: false,
            errors: {},
            showLocationTypeForm: false,
            selectedLocationType: null,
            rowData: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onAddLocationType = this.onAddLocationType.bind(this);
        this.onEditLocationType = this.onEditLocationType.bind(this);
        this.onSaveLocationType = this.onSaveLocationType.bind(this);
        this.onLocationTypeSelectionChanged = this.onLocationTypeSelectionChanged.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    componentWillUnmount() {
    }

    async populateState() {
        const locationTypeId = this.props.match.params.id;

        var data = await Promise.all([
            !!locationTypeId ? util.fetch.js(ApiRoutes.locationType.byId(locationTypeId)) : new LocationType()
        ]);

        let gridOptions = createGridOptions();

        gridOptions.components = {
            statusFilter: DataGridSelectFilter,
            equipmentTypeFilter: DataGridSelectFilter,
        };

        gridOptions.columnDefs = [
            { headerName: "", valueGetter: "node.id", sortable: false, cellRenderer: indexCellRenderer },          
            { colId: 'LocationType.Description', sortable: true, headerName: 'Description', field: 'description', sort: { direction: 'asc', priority: 0 } }            
        ];

        gridOptions.onSelectionChanged = this.onLocationTypeSelectionChanged;

        gridOptions.onGridReady = this.onGridReady;

        this.setState(state => {
            return {
                loading: false,
                locationType: data[0],
                readOnly: false,
                gridOptions: gridOptions
            }
        });
    }

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState((state) => { return { errors: {} }; });
    }

    // #endregion

    //#region [ Grid ]

    onGridReady(params) {
        let url = ApiRoutes.locationType.search();
        params.api.setDatasource(createDataSource(url, this.state.gridOptions));
    }

    onDataSuccess(rows, lastRowIndex) {
        this.setState({ rowData: rows });
    }

    onDataFailure() {
        alert('Unable to fetch table data.');
    }

    onLocationTypeSelectionChanged = event => {
        var selection = event.api.getSelectedRows();
        this.setState({ selectedLocationType: !!selection.length ? selection[0] : null });
    }
    //#endregion

    async onSubmit() {

        //Clear any fluent api errors
        this.setState((state) => { return { errors: {} }; });

        let { locationType } = this.state;

        let url = locationType.id ? ApiRoutes.locationType.update(locationType.id) : ApiRoutes.locationType.create();
        let fetch_stat = locationType.id ? util.fetch.put : util.fetch.post

        let response = await fetch_stat(url, locationType).catch((err) => {
            if (err && err.validationMessages && (Object.keys(err.validationMessages) ?? []).length)
                this.setState((state) => { return { errors: err.validationMessages } });
        });

        if (response.ok) {

            var id = await response.json();

            //if we got this far, redirect to employee edit page.
            this.redirect(id);
        }
    }

    async onSearch() {
        alert('search');
    }

    // #region [ Status Flyout for Add/Edit ]

    //Add New Status
    onAddLocationType() {
        let { locationType } = this.state;
        let new_status = new LocationType();
        //This is a new status, embed the Id and description.
        new_status.id = locationType.id ?? 0;
        new_status.description = locationType.description;        
        this.setState({
            selectedLocationType: new_status,
            showLocationTypeForm: true
        });
    }

    //Status table edit click handler
    onEditLocationType(status) {
        this.setState({ selectedLocationType: JSON.parse(JSON.stringify(status)), showLocationTypeForm: true });
    }

    //After status save callback
    onSaveLocationType(status) {
        if (!!status) {
            this.setState({ showLocationTypeForm: false });
            //refresh the grid
        }
    }

    //Close status window
    onCloseLocationType(response) {
        this.resetForm();
        this.props.onClose(response);
    }

    //Delete status shown
    onDeleteLocationType = async e => {
        const { locationType } = this.state;
        let response = await util.fetch.delete(ApiRoutes.locationType.delete(locationType.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    // #endregion 

    redirect(id) {
        this.props.history.push(`${ApplicationPaths.LocationType}/${id}`);
    }

    onInputChange = event => {
        let { locationType } = this.state;
        locationType.description = event.target.value;

        this.setState({
            locationType: locationType
        });
    }

    // #region [Render and Render Functions]



    render() {
        if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)

        let { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faScrewdriver} className="mr-2 text-muted" />
                    <span>Location Type</span>
                </PageHeading>
                <Row>
                    <Col>
                        <FlexRow>
                            <FlexStartRow>
                            </FlexStartRow>
                            <FlexEndRow>
                                <Can I="create,view,edit" a="equipment">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    hidden={!this.state.selectedLocationType}
                                                    onClick={this.onEditLocationType}
                                                >
                                                    <i className="fa fa-edit fa-md mr-2" />View Selected
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                                <Can I="create" a="equipment">
                                    <CommonContext.Consumer>
                                        {(value) => {
                                            return (
                                                <SmallButton
                                                    type="button"
                                                    disabled={!!value.formIsOpen}
                                                    onClick={this.onAddLocationType}
                                                >
                                                    <i className="fa fa-plus-circle fa-md mr-2" />Add Location Type
                                                </SmallButton>);
                                        }}
                                    </CommonContext.Consumer>
                                </Can>
                            </FlexEndRow>
                        </FlexRow>
                    </Col>
                </Row>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}>
                </DataGrid>
                <StatusForm
                    status={this.state.selectedLocationType}
                    show={this.state.showLocationTypeForm}
                    onClose={() => this.setState({ showLocationTypeForm: false })}
                    onSaveCallback={this.onSaveLocationType}
                    statusName='Location Type'
                    route={"locationType"}
                />
            </PageWrap>
        );
       
    }

    // #endregion
}
export default withRouter(LocationTypeIndex);