import React from 'react';
import CommonContext, { ApiRoutes } from '../Common';
import { withRouter } from 'react-router-dom';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import { ListGroup, ListGroupItem } from 'reactstrap';

import CustomCircularProgress from '../common/CustomCircularProgress';

class AdminToolIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = { loading: true }
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        if (!!isAuthenticated) {
            await this.setState({ loading: true });
            this.getAdminTool();
        }
    }

    getAdminTool = async () => {
        await this.setState({ loading: true });

        var [adminToolList] = await Promise.all([
            util.fetch.js(ApiRoutes.adminTool.search())
        ]);

        await this.setState(state => {
            return {
                loading: false,
                toolList: adminToolList
            }
        });
    }

    render() {
        if (!!this.state.loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            !!this.props.match.params.id
                                ? 'Getting timesheet...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user)
            return null;

        return (
            <div className="p-5">
                <h3>
                    Admin Tools
                </h3>

                <ListGroup>
                    {this.state.toolList.map((item) => (
                        <ListGroupItem href={`/admintool/${item.id}`} tag='a' action key={item.id}>
                            <b>{item.toolName}</b>   :  {item.description}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
        );
    }
}

export default withRouter(AdminToolIndex);