import React from "react";
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * Allows management of selectable date ranges.
 * See: https://reactdatepicker.com/#example-date-range
 * 
 * Added: MFN 11/16/2021
 * */
export default class DateRange extends React.Component {

    constructor(props) {

        super(props);

        const sd = this.props.startDate ? moment.utc(this.props.startDate) : moment().utc().startOf("month");
        const ed = this.props.endDate ? moment.utc(this.props.endDate) : moment().utc().endOf("month");

        this.state = {
            startDate: new Date(sd.year(), sd.month(), sd.date()),
            endDate: new Date(ed.year(), ed.month(), ed.date())
        };

        this.props.onSetStartDate(this.state.startDate);
        this.props.onSetEndDate(this.state.endDate);

    }

    render() {

        const { startDate, endDate } = { ...this.state };
        const { onSetStartDate, onSetEndDate, showPopperArrow } = { ...this.props };

        return (
            <>
                <div className="col-sm-3">
                    <label className="control-label">Start</label>
                    <DatePicker
                        className=" css-yk16xz-control"
                        selected={startDate}
                        onChange={date => {
                            this.setState({ startDate: date });
                            if(onSetStartDate) onSetStartDate(date);
                        }}
                        showPopperArrow={showPopperArrow}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                <div className="col-sm-3">
                    <label className="control-label">End</label>
                    <DatePicker
                        className=" css-yk16xz-control"
                        selected={endDate}
                        onChange={date => {                    
                            this.setState({ endDate: date });
                            if (onSetEndDate) onSetEndDate(date);
                        }}
                        showPopperArrow={showPopperArrow}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />
            </div>
        </>)
    }
}