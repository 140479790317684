import React from 'react';
import { createDataSource, createGridOptions, DataGrid, indexCellRenderer, LinkCellRenderer, TextFilterDefaults } from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { agGridConstants, ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';

class CompanyBillingGroupIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () => this.setState = (state, callback) => { return };
    onViewGroup = (id) => this.props.history.push(`${AppNavPaths.CompanyBillingGroupNew}/${id}`);

    populateState = async () => {

        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 70,
                minWidth: 70,
                maxWidth: 70,
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                cellRenderer: indexCellRenderer
            },
            {
                colId: 'GroupName',
                sortable: true,
                headerName: 'Billing Group Name',
                field: 'description',
                flex: 0,
                width: 300,
                minWidth: 300,
                maxWidth: 300,
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,   
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onViewGroup,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this Group'
                }
            },
            {
                colId: 'Companies',
                sortable: false,
                headerName: 'Companies',
                field: 'companies',
                flex: 0,
                width: 500,
                minWidth: 300,
                maxWidth: 500
            }
        ];

        let ds = createDataSource(ApiRoutes.companyBillingGroups.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: ds
        });
    }

    render() {
        if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)
        let { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faWarehouse} className="mr-2 text-muted" />
                    <span>Company Billing Groups</span>
                </PageHeading>
                <DataGridToolbar
                    entity="group"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={() => this.props.history.push(`${AppNavPaths.CompanyBillingGroupNew}`)}
                    addLabel="Add Group"
                    serverExportDisabled={false}
                />
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                >
                </DataGrid>
            </PageWrap>
        );
    }
}
export default withRouter(CompanyBillingGroupIndex);