import React from 'react';
import { faCheckCircle, faScrewdriver } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import Select from 'react-select';
import { FormLabel, onFieldChange, onReactSelectChanged, ToastMessage, ValidationErrorMessage } from '../common/forms/FormElements';
import { EquipmentType } from './Equipment';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { toast } from 'react-toastify';
import { handleFormSaveError } from '../common/forms/ValidationError';

export class EquipmentTypeForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {
                equipmentType: this.props.equipmentType ?? new EquipmentType(),
                groups: [],
                locations: [],
                show: false,
                statuses: [],
                requiredEquipment: [],
                afadTypes: [],
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        var [groups, afadTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.employeeGroups()),
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypeAFADTypes()),
        ]);

        this.setState(state => {
            return {
                loading: false,
                groups: groups,
                afadTypes: afadTypes,
            }
        });
    }

    open = async (id) => {
        this.resetForm();
        this.setState({ show: true, loading:true });
        this.context.setFormOpened(true);
    
        let [equipmentType, requiredEquipmentOptions] = await Promise.all([
                (!!id ? util.fetch.js(ApiRoutes.equipmentType.byId(id)) : new EquipmentType()),
                util.fetch.js(ApiRoutes.equipmentType.all())
            ]);   
        
        if (!!equipmentType.id) {
            //Cannot assign dependent type as itself
            requiredEquipmentOptions = (requiredEquipmentOptions ?? [])
                .filter(x => x.id !== equipmentType.id);                
        }

        requiredEquipmentOptions = requiredEquipmentOptions.map(x => { return { value: x.id, label: x.description } });

        this.setState({
            equipmentType: equipmentType,
            requiredEquipment: requiredEquipmentOptions,
            loading: false
        });
    }

    onChange = onFieldChange.bind(this);
    onSelectChange = onReactSelectChanged.bind(this);
    handleSaveError = handleFormSaveError.bind(this);

    onSubmit = async e => {
        const { equipmentType } = this.state;

        //Clear any fluent api errors
        this.setState({ errors: {}, saving: true });

        //Is this POST or PUT?
        let url = equipmentType.id
            ? ApiRoutes.equipmentType.update(equipmentType.id)
            : ApiRoutes.equipmentType.create();
        let fetch_cte = equipmentType.id ? util.fetch.put : util.fetch.post

        let response = await fetch_cte(url, equipmentType).catch(this.handleSaveError);      

        if (response) {
            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`Equipment Type [${equipmentType.description}] saved.`}
            />);
            this.setState({ show: false, saving: false })
            this.context.setFormOpened(false);
            this.resetForm();
            this.context.setFormOpened(false);
            this.props.onSaveCallback(response);
        }
    }

    resetForm() {
        this.setState({ formValidated: false });
    }

    onClose(response) {
        this.setState({ show: false });
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async e => {
        const { equipmentType } = this.state;
        let response = await util.fetch.delete(ApiRoutes.equipmentType.delete(equipmentType.id));
        if (response)
            this.onClose(response);
    }

    render() {
        let { requiredEquipment, groups, formValidated, validationMessage, afadTypes } = this.state;
        let eq = this.state.equipmentType;
        return (
            <SlideForm
                loading={this.state.loading}
                show={this.state.show}
                id={"equipmentTypeForm"}
                formIcon={faScrewdriver}
                formTitle={!!parseInt(eq.id ?? 0) > 0 ? "Edit Equipment Type" : "Add Equipment Type"}
                ref={this.formRef}
                setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}            >

                <FormGroup>
                    <FormLabel
                        htmlFor="description"
                        text="Description"
                        required={true} />
                    <Input id="description"
                        name="equipmentType.description"
                        value={eq.description ?? ''}
                        onChange={this.onChange}
                        placeholder="Enter description"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>Description is required.</ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        id="groupsLabel"
                        htmlFor="groups"
                        text="Required User Groups"
                        helpMessage="Select any user groups that are required for this type of equipment."
                        required={!(eq.requiredEquipment ?? []).length}
                    />
                    <ValidatedSelect
                        id="groups"
                        name="equipmentType.groups"
                        isMulti
                        options={groups}
                        value={(groups ?? []).filter(x => (eq.groups ?? []).includes(x.value)) ?? ''}
                        onChange={this.onSelectChange}
                        validationMessage="At least one is required: required user group or required equipment."
                        required={!(eq.requiredEquipment ?? []).length}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        id="employeeAssignableQuantityLabel"
                        htmlFor="employeeAssignableQuantity"
                        text="Employee Assignable Quantity"
                        required={!!(eq.groups ?? []).length}
                        helpMessage="Enter the amount that can be assigned to any of the selected user groups."
                    />
                    <Input id="employeeAssignableQuantity"
                        name="equipmentType.employeeAssignableQuantity"
                        disabled={!(eq.groups ?? []).length}
                        value={eq.employeeAssignableQuantity ?? ''}
                        onChange={this.onChange}
                        placeholder="Enter Quantity"
                        type="number"
                        step="1"
                        required={!!(eq.groups ?? []).length}
                    />
                    <ValidationErrorMessage>This quantity is required.</ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        id="requiredEquipmentLabel"
                        htmlFor="requiredEquipment"
                        text="Required Equipment"
                        required={!(eq.groups ?? []).length}
                        helpMessage="Select other equipment types that will be required in addition to this equipment when it is assigned."
                    />
                    <Select
                        id="requiredEquipment"
                        name="equipmentType.requiredEquipment"
                        isMulti
                        options={requiredEquipment}
                        value={(requiredEquipment ?? []).filter(x => (eq.requiredEquipment ?? []).includes(x.value)) ?? ''}
                        onChange={this.onSelectChange}
                        required={!(eq.groups ?? []).length}
                        validationMessage="At least one is required: required user group or required equipment."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentAssignableQuantityLabel"
                        text="Equipment Assignable Quantity"
                        required={!!(eq.requiredEquipment ?? []).length}
                        helpMessage="Enter the amount that can be assigned to any of the selected equipment."
                    />
                    <Input id="equipmentAssignableQuantity"
                        name="equipmentType.equipmentAssignableQuantity"
                        disabled={!(eq.requiredEquipment ?? []).length}
                        value={eq.equipmentAssignableQuantity ?? ''}
                        onChange={this.onChange}
                        placeholder="Enter Quantity"
                        type="number"
                        step="1"
                        required={!!(eq.requiredEquipment ?? []).length}
                    />
                    <ValidationErrorMessage>This quantity is required.</ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        id="afadTypeLabel"
                        htmlFor="equipmentTypeAFADTypeId"
                        text="Is this a type of AFAD?"
                        required
                    />
                    <ValidatedSelect
                        id="equipmentTypeAFADTypeId"
                        name="equipmentType.equipmentTypeAFADTypeId"
                        options={afadTypes}
                        required
                        value={
                            (afadTypes ?? []).find(
                                (s) =>
                                    s.value === eq.equipmentTypeAFADTypeId
                            ) ?? 1
                        }
                        onChange={this.onSelectChange}
                        validationMessage="You must choose what type of equipment this is."
                    />
                </FormGroup>
            </SlideForm>
        );
    }
}