import {
    faCheckCircle,
    faToolbox,
    faTruck,
} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Container, FormGroup } from 'reactstrap';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    LocalizationKeys as l,
} from '../Common';
import {
    CollapseUnderlineHeader,
    FlexBetweenRow,
    FlexCenterRow,
    FlexColumnStart,
    FlexStartRow,
    FormLabel,
    toasty,
} from '../common/forms/FormElements';
import { util } from '../Util';
import './AssignmentConfirmation.scss';
import cls from 'classnames';
import {
    faCommentDots,
    faComments,
    faTimesCircle,
    faPhoneSquareAlt,
    faUser,
    faCircleNotch,
    faShuttleVan,
    faHardHat,
    faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash-es';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import {
    ContractTimesheetResponsibilityType,
    TimesheetResponsibilityType,
} from '../tenant/Tenant';
import authService from '../api-authorization/AuthorizeService';
import JobFileAttachments from '../job/JobFileAttachments';
import { JobDetails } from '../job/Job';

export const ConfirmationStatus = {
    Unfilled: 1,
    Scheduled: 2,
    NoResponse: 3,
    Confirmed: 4,
    CancelPending: 5,
    ThresholdCancel: 6,
};

export const JobConfirmationStatusOptions = [
    { value: 1, class: 'unfilled', label: 'Unfilled' },
    { value: 2, class: 'scheduled', label: 'Scheduled' },
    { value: 3, class: 'noresponse', label: 'No Response' },
    { value: 4, class: 'confirmed', label: 'Confirmed' },
    { value: 5, class: 'cancelpending', label: 'Cancel Pending' },
];

class AssignmentConfirmation extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            assignment: null,
            confirmedAssignments: [],
            crewAndEquipOpen: true,
            invalidChargeTypeIds: [],
            jobDetailsOpen: false,
            loading: true,
            saving: false,
            notFound: false,
            timesheetOpen: true,
            timesheetValid: false,
            timesheetValidated: false,
            enforceCrewLeadTimesheet: true,
        };
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        if (!!isAuthenticated) {
            const assignmentId = this.props.match.params.id;

            const strings = await util.l10n.getStrings([l.crewLead]);
            this.setState({ strings: strings });

            if (!assignmentId) {
                this.setState({ notFound: true, loading: false });
            } else {
                const response = await util.fetch.get(
                    ApiRoutes.jobAssignment.byId(assignmentId),
                    util.fetch.format.none
                );
                if (response.ok) {
                    const assignment = await response.json();

                    if (!assignment) {
                        this.setState({ notFound: true, loading: false });
                    } else {
                        /**
                         *  Determine who fills out the timesheets.  THis will be used to determine where to direct the user after
                         *  assignment confirmation.
                         * */
                        const enforceCrewLeadTS =
                            //Are we set for crew lead timesheets at the tenant level?
                            this.context.tenant.tenantSettings
                                .timesheetResponsibilityType ===
                                TimesheetResponsibilityType.CrewLead ||
                            //Are we set for timesheets per contract, and contract is set up for crew lead?
                            (this.context.tenant.tenantSettings
                                .timesheetResponsibilityType ===
                                TimesheetResponsibilityType.Contract &&
                                assignment.contractTimesheetResponsibilityTypeId ===
                                    ContractTimesheetResponsibilityType.CrewLead);

                        let confirmed =
                            (assignment ?? {}).confirmationStatusId ===
                            ConfirmationStatus.Confirmed;

                        this.setState({
                            assignment: assignment,
                            jobDetailsOpen: !confirmed,
                            loading: false,
                            enforceCrewLeadTimesheet: enforceCrewLeadTS,
                        });
                    }
                } else {
                    if (response.status === util.fetch.statusCodes.unauthorized)
                        util.navigation.localRedirect(
                            this,
                            AppNavPaths.Unauthorized
                        );
                    else
                        toasty.error(
                            'There was a problem retrieving the job assignment.'
                        );
                }
            }
        }
    };

    onAssignmentCheckedChanged = (id) => {
        let { confirmedAssignments } = { ...this.state };
        const inx = confirmedAssignments.indexOf(parseInt(id));
        if (inx < 0) confirmedAssignments.push(parseInt(id));
        else confirmedAssignments.splice(inx, 1);
        this.setState({ confirmedAssignments: confirmedAssignments });
    };

    confirmAssignment = async () => {
        let { assignment, confirmedAssignments, enforceCrewLeadTimesheet } = {
            ...this.state,
        };

        //What equipment does the confirming employee own of this list?
        const ownedEquipment = assignment.equipment.filter(
            (x) =>
                x.equipmentEmployeeAssignmentId ===
                assignment.assignedEmployeeId
        );
        const ownedEquipmentIds = ownedEquipment.map(
            (x) => x.assignedEquipmentId
        );

        //Of the equipment, which have been assigned to any of the equipment owned by the confirming employee?
        const dependentEquipment = assignment.equipment.filter((x) =>
            ownedEquipmentIds.includes(x.equipmentAssignedEquipmentId)
        );
        //const dependentEquipmentIds = dependentEquipment.map(x => x.assignedEquipmentId);

        //Get all assignments this emp needs to acknowledge
        let equipmentAssignmentsToConfirm = new Set([
            ...ownedEquipment.map((e) => {
                return e.id;
            }),
            ...dependentEquipment.map((e) => {
                return e.id;
            }),
        ]);

        let toConfirm = _.sortBy([...equipmentAssignmentsToConfirm]);
        let allConfirmed = _.sortBy(confirmedAssignments);
        let valid = _.isEqual(_.sortBy(toConfirm), _.sortBy(allConfirmed));

        if (!valid) {
            toasty.error('Please confirm all equipment assignments.');
        } else {
            this.setState({ saving: true });
            var timesheetId = await util.fetch.post(
                ApiRoutes.jobAssignment.confirm(assignment.jobAssignmentId),
                { notes: null }
            );
            this.setState({ saving: false });

            const timesheetUrl = `${AppNavPaths.Timesheet}/${timesheetId}`;

            /**
             * If we are enforcing crew leads as the timesheet masters, then make sure the user is a crew lead.
             * Otherwise, send them to their assignment calendar.
             **/
            if (!!enforceCrewLeadTimesheet)
                this.props.history.push(
                    assignment.isCrewLead
                        ? timesheetUrl
                        : AppNavPaths.AssignmentCalendar
                );
            else this.props.history.push(timesheetUrl);
        }
    };

    confirmCancelledAssignment = async () => {
        let { assignment } = { ...this.state };
        this.setState({ saving: true });
        var timesheetId = await util.fetch.put(
            ApiRoutes.jobAssignment.cancelConfirmed(assignment.jobAssignmentId)
        );

        this.setState({ saving: false });

        if (timesheetId !== 0)
            this.props.history.push(`${AppNavPaths.Timesheet}/${timesheetId}`);
        else this.props.history.push(`${AppNavPaths.AssignmentCalendar}`);
    };

    onAddChargeTypeToTimesheet = async () => {};

    submitTimesheet = async () => {
        await this.setState({ timesheetValidated: false });
        await this.validateTimesheet();
        if (this.state.timesheetValid) {
            //TODO: save
        }
    };

    validateTimesheet = async () => {
        let { assignment } = { ...this.state },
            timeFormat = 'HH:mm a';
        let invalidChargeTypeIds = [];
        for (let a of assignment.timesheet.assignments) {
            var valid =
                !!a.notApplicable ||
                //Start
                (!!a.startTime &&
                    !!moment(a.startTime, timeFormat, true).isValid()) ||
                // end
                !!a.endTime ||
                !!moment(a.endTime, timeFormat, true).isValid();
            if (!valid) invalidChargeTypeIds.push(a.id);
        }
        this.setState({
            timesheetValidated: true,
            timesheetValid:
                !invalidChargeTypeIds.length &&
                !!assignment.timesheet.workOrderNumber,
            invalidChargeTypeIds: invalidChargeTypeIds,
        });
    };

    render = () => {
        const {
            assignment,
            confirmedAssignments,
            jobDetailsOpen,
            loading,
            saving,
            notFound,
            strings,
        } = { ...this.state };

        const { tenantSettings } = { ...this.context.tenant };

        const jobDetailsHeader = Boolean(
            tenantSettings &&
                tenantSettings.showContractDescriptionInTimesheetView &&
                assignment &&
                assignment.jobName
        )
            ? `Job Details - ${assignment.jobName}`
            : 'Job Details';

        const confirmed =
            (assignment ?? {}).confirmationStatusId ===
            ConfirmationStatus.Confirmed;
        const crewLead = strings ? strings[l.crewLead] : '';

        if (!!loading || !(this.context.tenant ?? {}).tenantSettings) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CircularProgress
                        color="secondary"
                        variant="indeterminate"
                    />
                </div>
            );
        } else {
            const { jobUploadsEnabled } = {
                ...this.context.tenant.tenantSettings,
            };

            const allEquipment = assignment.equipment ?? [];

            //What equipment does the confirming employee own of this list?
            const ownedEquipment = allEquipment.filter(
                (x) =>
                    x.equipmentEmployeeAssignmentId ===
                    assignment.assignedEmployeeId
            );
            const ownedEquipmentIds = ownedEquipment.map(
                (x) => x.assignedEquipmentId
            );

            //Of the equipment, which have been assigned to any of the equipment owned by the confirming employee?
            const dependentEquipment = allEquipment.filter((x) =>
                ownedEquipmentIds.includes(x.equipmentAssignedEquipmentId)
            );
            const dependentEquipmentIds = dependentEquipment.map(
                (x) => x.assignedEquipmentId
            );

            return (
                <>
                    <div className="confirmationContainer h-100 pt-2">
                        <Container size="sm">
                            {!!notFound && (
                                <h4 className="text-center">
                                    Unable to locate the requested resource.
                                </h4>
                            )}
                            {!!assignment && (
                                <>
                                    {!!confirmed && (
                                        <Alert
                                            className="mb-3 p-1 text-center"
                                            color="success"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                size="lg"
                                                className="mr-2"
                                            />
                                            This assignment has been confirmed.
                                        </Alert>
                                    )}

                                    <CollapseUnderlineHeader
                                        headerText={jobDetailsHeader}
                                        isOpen={true} //jobDetailsOpen}
                                        toggleCollapse={() =>
                                            this.setState({
                                                jobDetailsOpen: !jobDetailsOpen,
                                            })
                                        }
                                    >
                                        {(this.state.assignment ?? {})
                                            .confirmationStatusId ===
                                            ConfirmationStatus.CancelPending && (
                                            <Alert
                                                className="mb-3 p-1 text-center"
                                                color="danger"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    size="lg"
                                                    className="mr-2"
                                                />
                                                This assignment has been
                                                cancelled.
                                            </Alert>
                                        )}
                                        {(this.state.assignment ?? {})
                                            .confirmationStatusId ===
                                            ConfirmationStatus.ThresholdCancel && (
                                            <Alert
                                                className="mb-3 p-1 text-center"
                                                color="danger"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    size="lg"
                                                    className="mr-2"
                                                />
                                                This assignment has been
                                                cancelled by the customer.
                                            </Alert>
                                        )}
                                        <JobDetails
                                            customerName={
                                                assignment?.customerName
                                            }
                                            subcontractorName={
                                                assignment?.subcontractorName
                                            }
                                            foremanName={
                                                assignment?.foremanName
                                            }
                                            foremanPhone={
                                                assignment?.foremanPhone
                                            }
                                            jobNotes={assignment?.jobNotes}
                                            jobNumber={assignment?.jobNumber}
                                            jobStartTime={
                                                assignment?.jobStartTime
                                            }
                                            workOrderNumber={
                                                assignment?.workOrderNumber
                                            }
                                            tenantSettings={tenantSettings}
                                            jobName={assignment.jobName}
                                        />
                                        <div className="locationDetails">
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="meetingLocation"
                                                    text="Meeting Location"
                                                    className="bottom-border"
                                                />
                                                <FlexStartRow>
                                                    {!!(assignment ?? {})
                                                        .meetingLocationUrlFormatted && (
                                                        <a
                                                            rel="noopener noreferrer"
                                                            className="text-white mr-2 btn btn-sm btn-success p-1"
                                                            title="Open in Google Maps"
                                                            href={`https://maps.google.com?q=${assignment.meetingLocationUrlFormatted}`}
                                                            target="_blank"
                                                        >
                                                            <i className="fa fa-map-marked-alt" />
                                                        </a>
                                                    )}
                                                    <span
                                                        id="meetingLocation"
                                                        name="meetingLocation"
                                                    >
                                                        {assignment.meetingLocation ??
                                                            '-'}
                                                    </span>
                                                </FlexStartRow>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="jobLocation"
                                                    text="Job Location"
                                                    className="bottom-border"
                                                />
                                                <FlexStartRow>
                                                    {!!(assignment ?? {})
                                                        .jobLocationUrlFormatted && (
                                                        <a
                                                            rel="noopener noreferrer"
                                                            className="text-white mr-2 btn btn-sm btn-success p-1"
                                                            title="Open in Google Maps"
                                                            href={`https://maps.google.com?q=${assignment.jobLocationUrlFormatted}`}
                                                            target="_blank"
                                                        >
                                                            <i className="fa fa-map-marked-alt" />
                                                        </a>
                                                    )}
                                                    <span
                                                        id="jobLocation"
                                                        name="jobLocation"
                                                    >
                                                        {assignment.jobLocation ??
                                                            '-'}
                                                    </span>
                                                </FlexStartRow>
                                            </FormGroup>
                                            {!!jobUploadsEnabled &&
                                                !!(assignment.jobUploads ?? [])
                                                    .length && (
                                                    <FormGroup className="mt-2">
                                                        <FormLabel
                                                            style={{
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                            text="File Attachments"
                                                            className="bottom-border"
                                                        />
                                                        <FlexStartRow className="pl-3">
                                                            <JobFileAttachments
                                                                viewOnly={true}
                                                                className="d-flex flex-row align-items-center flex-wrap"
                                                                job={{
                                                                    id: assignment.jobId,
                                                                    uploads:
                                                                        assignment.jobUploads,
                                                                }}
                                                            />
                                                        </FlexStartRow>
                                                    </FormGroup>
                                                )}
                                        </div>

                                        {(this.state.assignment ?? {})
                                            .confirmationStatusId !== 5 && (
                                            <div className="crewAndEquipmentDetails">
                                                <FormGroup>
                                                    <FormLabel
                                                        text="Crew And Equipment"
                                                        className="bottom-border"
                                                    />
                                                </FormGroup>
                                                {!!confirmed &&
                                                    !!assignment.crewSMS && (
                                                        <FlexCenterRow>
                                                            <a
                                                                className="mb-2 p-1 btn btn-success btn-sm groupSmsLink text-white"
                                                                href={`sms:${assignment.crewSMS}`}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faComments
                                                                    }
                                                                    size="lg"
                                                                    className="mr-2"
                                                                />
                                                                Send Group SMS
                                                                to Crew
                                                            </a>
                                                        </FlexCenterRow>
                                                    )}

                                                <div className="crewAndEquipmentDetails table-responsive">
                                                    <table className="table table-sm assignmentsTable">
                                                        <tbody>
                                                            {!!assignment.crew && (
                                                                <>
                                                                    {assignment.crew.map(
                                                                        (c) => {
                                                                            const ownedAssignment =
                                                                                c.assignedEmployeeId ===
                                                                                assignment.assignedEmployeeId;

                                                                            return (
                                                                                <tr
                                                                                    className={cls(
                                                                                        'assignmentRow',
                                                                                        {
                                                                                            owned: !!ownedAssignment,
                                                                                        }
                                                                                    )}
                                                                                    key={
                                                                                        c.id
                                                                                    }
                                                                                >
                                                                                    <td className="iconCell">
                                                                                        <FontAwesomeIcon
                                                                                            className={cls(
                                                                                                {
                                                                                                    'text-success font-weight-bold':
                                                                                                        !!ownedAssignment,
                                                                                                    'text-muted':
                                                                                                        !ownedAssignment,
                                                                                                    'fa-hard-hat-golden':
                                                                                                        !!c.isCrewLead,
                                                                                                }
                                                                                            )}
                                                                                            icon={
                                                                                                !!c.isCrewLead
                                                                                                    ? faHardHat
                                                                                                    : faUser
                                                                                            }
                                                                                            title={
                                                                                                !!c.isCrewLead
                                                                                                    ? crewLead
                                                                                                    : c.assignedEmployeeGroup
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <FlexBetweenRow>
                                                                                            <small
                                                                                                className={cls(
                                                                                                    {
                                                                                                        'text-success font-weight-bold':
                                                                                                            c.assignedEmployeeId ===
                                                                                                            assignment.assignedEmployeeId,
                                                                                                    }
                                                                                                )}
                                                                                            >
                                                                                                {`${
                                                                                                    c.assignedEmployeeName
                                                                                                } ${
                                                                                                    !!c.isCrewLead
                                                                                                        ? `(${crewLead})`
                                                                                                        : ''
                                                                                                }`}
                                                                                            </small>
                                                                                            {!!confirmed &&
                                                                                                !!c.assignedEmployeePhone && (
                                                                                                    <a
                                                                                                        title={`Send SMS to ${c.assignedEmployeeName}`}
                                                                                                        className="ml-2 p-1 btn btn-sm btn-success"
                                                                                                        href={`sms://+1${c.assignedEmployeePhone}`}
                                                                                                    >
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                faCommentDots
                                                                                                            }
                                                                                                            size="lg"
                                                                                                        />
                                                                                                    </a>
                                                                                                )}
                                                                                        </FlexBetweenRow>
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {!confirmed &&
                                                                                            c.confirmationStatusId ===
                                                                                                ConfirmationStatus.Confirmed && (
                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        opacity:
                                                                                                            !!ownedAssignment
                                                                                                                ? 1
                                                                                                                : 0.5,
                                                                                                    }}
                                                                                                    size="lg"
                                                                                                    title="Employee confirmed"
                                                                                                    className="icon-check checked"
                                                                                                    icon={
                                                                                                        faCheckCircle
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            )}
                                                            {!!assignment.equipment && (
                                                                <>
                                                                    {assignment.equipment.map(
                                                                        (e) => {
                                                                            const icon =
                                                                                e.assignedEquipmentType
                                                                                    .toLowerCase()
                                                                                    .includes(
                                                                                        'truck'
                                                                                    )
                                                                                    ? faTruck
                                                                                    : e.assignedEquipmentType
                                                                                          .toLowerCase()
                                                                                          .includes(
                                                                                              'van'
                                                                                          )
                                                                                    ? faShuttleVan
                                                                                    : faToolbox;

                                                                            const ownedAssignment =
                                                                                e.equipmentEmployeeAssignmentId ===
                                                                                    assignment.assignedEmployeeId ||
                                                                                dependentEquipmentIds.includes(
                                                                                    e.assignedEquipmentId
                                                                                );
                                                                            
                                                                            const clickable = !confirmed
                                                                                && e.confirmationStatusId !== ConfirmationStatus.Confirmed
                                                                                && ownedAssignment;

                                                                            return (
                                                                                <tr
                                                                                    className={cls(
                                                                                        'assignmentRow',
                                                                                        {
                                                                                            clickable,
                                                                                            owned: !!ownedAssignment,
                                                                                        }
                                                                                    )}
                                                                                    key={
                                                                                        e.id
                                                                                    }
                                                                                    role={clickable ? 'button' : 'row'}
                                                                                    onClick={() => {
                                                                                        if (clickable) {
                                                                                            this.onAssignmentCheckedChanged(
                                                                                                e.id
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <td className="iconCell">
                                                                                        <FontAwesomeIcon
                                                                                            className={cls(
                                                                                                {
                                                                                                    'text-success font-weight-bold':
                                                                                                        !!ownedAssignment,
                                                                                                    'text-muted':
                                                                                                        !ownedAssignment,
                                                                                                }
                                                                                            )}
                                                                                            icon={
                                                                                                icon
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <FlexColumnStart>
                                                                                            <small
                                                                                                className={cls(
                                                                                                    {
                                                                                                        'text-success font-weight-bold':
                                                                                                            !!ownedAssignment,
                                                                                                    }
                                                                                                )}
                                                                                            >
                                                                                                {`${e.assignedEquipmentType} ${e.assignedEquipmentName}`}
                                                                                            </small>
                                                                                            {e.equipmentEmployeeAssignmentName && (
                                                                                                <small>{`(Assigned to: ${e.equipmentEmployeeAssignmentName})`}</small>
                                                                                            )}
                                                                                            {e.equipmentAssignedEquipmentName && (
                                                                                                <small>{`(Assigned to: ${e.equipmentAssignedEquipmentType} ${e.equipmentAssignedEquipmentName})`}</small>
                                                                                            )}
                                                                                        </FlexColumnStart>
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {!confirmed &&
                                                                                            e.confirmationStatusId ===
                                                                                                ConfirmationStatus.Confirmed && (
                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        opacity:
                                                                                                            !!ownedAssignment
                                                                                                                ? 1
                                                                                                                : 0.7,
                                                                                                    }}
                                                                                                    size="lg"
                                                                                                    title="Equipment confirmed"
                                                                                                    className="icon-check checked"
                                                                                                    icon={
                                                                                                        faCheckCircle
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        {!confirmed &&
                                                                                            e.confirmationStatusId !==
                                                                                                ConfirmationStatus.Confirmed &&
                                                                                            ownedAssignment && (
                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        opacity:
                                                                                                            !!ownedAssignment
                                                                                                                ? 1
                                                                                                                : 0.7,
                                                                                                    }}
                                                                                                    size="lg"
                                                                                                    className={cls(
                                                                                                        'cursor-pointer icon-check',
                                                                                                        {
                                                                                                            checked:
                                                                                                                confirmedAssignments.indexOf(
                                                                                                                    e.id
                                                                                                                ) >
                                                                                                                -1,
                                                                                                        }
                                                                                                    )}
                                                                                                    title={
                                                                                                        confirmedAssignments.indexOf(
                                                                                                            e.id
                                                                                                        ) >
                                                                                                        -1
                                                                                                            ? 'Confirmed'
                                                                                                            : 'Click to confirm'
                                                                                                    }
                                                                                                    icon={
                                                                                                        faCheckCircle
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </CollapseUnderlineHeader>
                                </>
                            )}
                        </Container>
                    </div>
                    <FlexCenterRow className="userActions p-1">
                        {this.state?.assignment?.dispatchOfficeNumber && (
                            <Button
                                type="button"
                                id="officeCallButton"
                                href={`tel:${this.state?.assignment?.dispatchOfficeNumber}`}
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={faPhoneSquareAlt}
                                    className="mr-2"
                                />
                                {`Office - ${this.state?.assignment?.dispatchOfficeNumber}`}
                            </Button>
                        )}
                        {(this.state.assignment ?? {}).confirmationStatusId <=
                            3 && (
                            <Button
                                type="button"
                                color="primary"
                                disabled={saving}
                                onClick={() => this.confirmAssignment()}
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={
                                        saving ? faCircleNotch : faCheckCircle
                                    }
                                    className="mr-2"
                                />
                                Confirm
                            </Button>
                        )}
                        {(this.state.assignment ?? {}).confirmationStatusId ===
                            5 && (
                            <Button
                                type="button"
                                color="danger"
                                disabled={saving}
                                onClick={() =>
                                    this.confirmCancelledAssignment()
                                }
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={
                                        saving ? faCircleNotch : faCheckCircle
                                    }
                                    className="mr-2"
                                />
                                Confirm Cancellation
                            </Button>
                        )}
                        {!!this.state.assignment?.timesheetId && (
                            <Button
                                type="button"
                                color="primary"
                                onClick={() =>
                                    this.props.history.push(
                                        `${AppNavPaths.Timesheet}/${this.state.assignment.timesheetId}`
                                    )
                                }
                            >
                                <FontAwesomeIcon size="lg" icon={faUserClock} />
                                Go To Timesheet
                            </Button>
                        )}
                    </FlexCenterRow>
                </>
            );
        }
    };
}

export default withRouter(AssignmentConfirmation);
