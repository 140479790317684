import React from 'react';
import { Button, ButtonGroup, Badge } from 'reactstrap';
import moment from 'moment';

export default class DispatchEquipmentFilter extends React.Component {
    getAssignedEquipmentCount = () => {
        const { selectedWeekRangeDays, allJobs } = this.props;

        if (!allJobs || !allJobs.length) {
            return 0;
        }

        const selectedDate = selectedWeekRangeDays[0];

        let assignmentCount = 0;

        allJobs.forEach((j) =>
            Object.values(j.assignments).forEach((a) => {
                if (
                    a.isCancelled ||
                    // bugbug: intentional - assignment dates have a different format from selected week days
                    (a.date.endsWith('Z') &&
                        !moment(a.date)
                            .add(1, 'day')
                            .isSame(selectedDate, 'day')) ||
                    // bugbug: also intentional - date format shifts post-assignment
                    (!a.date.endsWith('Z') &&
                        !moment(a.date).isSame(selectedDate, 'day')) ||
                    !a.equipment
                ) {
                    return;
                }

                Object.values(a.equipment).forEach((equipmentOpening) => {
                    if (equipmentOpening.assignment) {
                        assignmentCount++;
                    }
                });
            })
        );

        return assignmentCount;
    };

    render() {
        const {
            selectedWeekRangeDays,
            loading,
            equipmentLoading,
            resourcesLoading,
            equipment,
            onUnassignedEquipmentClick,
            onlyShowUnassignedEquipment,
            equipmentTabActive,
            tenantSettings,
        } = this.props;

        if (
            !equipmentTabActive ||
            loading ||
            equipmentLoading ||
            resourcesLoading ||
            !tenantSettings ||
            !tenantSettings.dispatchStatusFilterEnabled
        ) {
            return null;
        }

        if (
            !selectedWeekRangeDays ||
            selectedWeekRangeDays.length != 1 ||
            !equipment ||
            !equipment.rows
        ) {
            return <div className="flex-spacing"></div>;
        }

        const assignedCount = this.getAssignedEquipmentCount();

        const unassignedCount = equipment.rows.length - assignedCount;

        const unassignedBadgeColor =
            unassignedCount <= 0 ? 'success' : 'danger';
        const unassignedButtonColor = onlyShowUnassignedEquipment
            ? 'primary'
            : 'secondary';

        return (
            <div className="status-selection ml-3">
                <div className="form-group">
                    <label>Status</label>
                    <ButtonGroup className="dispatchEquipmentFilter" size="sm">
                        <Button
                            type="button"
                            onClick={onUnassignedEquipmentClick}
                            active={onlyShowUnassignedEquipment}
                            color={unassignedButtonColor}
                            outline={true}
                        >
                            Unassigned{' '}
                            <Badge
                                color={unassignedBadgeColor}
                                className={'badge-bigger'}
                            >
                                {unassignedCount}
                            </Badge>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}
