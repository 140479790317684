import { util } from '../Util';
import { ApiRoutes } from '../Common';

export class ComplianceType {
    id = -1;
    name = '';
    active = false;
    appliesToAllDispatches = false;
    warnWhenMissing = false;

    tenantCompanyId = -1;
    tenantCompany = null;

    complianceApplicationUserGroups = [];
    complianceEquipmentTypes = [];
}

export class ComplianceApplicationUserGroups {
    complianceApplicationUserGroupId = -1;

    complianceTypeId = -1;
    applicationUserGroupId = -1;

    complianceType = null;
    applicationUserGroup = null;
}

export class ComplianceEquipmentTypes {
    id = -1;

    complianceTypeId = -1;
    equipmentTypeId = -1;

    complianceType = null;
    equipmentType = null;
}

export const getComplianceTypeData = async () => {
    const [
        complianceTypesForAllDispatches,
        allAvailableUserComplianceTypes,
        allAvailableEquipmentComplianceTypes,
    ] = await Promise.all([
        util.fetch
            .js(ApiRoutes.complianceTypes.forAllDispatches())
            .catch((error) =>
                console.log(
                    'Unable to get compliance types for all dispatches.'
                )
            ),
        util.fetch
            .js(ApiRoutes.complianceTypes.allEmployeeComplianceTypes())
            .catch((error) =>
                console.log('Unable to get employee compliance types.')
            ),
        util.fetch
            .js(ApiRoutes.complianceTypes.allEquipmentComplianceTypes())
            .catch((error) =>
                console.log('Unable to get employee compliance types.')
            ),
    ]);

    // bugbug: this return object isn't iterable, so destructuring assignments fail
    return {
        complianceTypesForAllDispatches,
        allAvailableUserComplianceTypes,
        allAvailableEquipmentComplianceTypes,
    };
};

export const getSelectListOfEquipmentComplianceTypesForAllDispatches = (
    complianceTypesForAllDispatches
) => {
    return complianceTypesForAllDispatches
        .filter((c) => c.complianceEquipmentTypes.length > 0)
        .map((c) => {
            return { label: c.name, value: c.id };
        });
};

export const getSelectListOfUserComplianceTypesForAllDispatches = (
    complianceTypesForAllDispatches
) => {
    return complianceTypesForAllDispatches
        .filter((c) => c.complianceApplicationUserGroups.length > 0)
        .map((c) => {
            return { label: c.name, value: c.id };
        });
};

export const getSelectListOfAvailableUserComplianceTypes = (
    allAvailableUserComplianceTypes
) => {
    return allAvailableUserComplianceTypes
        .filter((c) => !c.appliesToAllDispatches)
        .map((c) => {
            return { label: c.name, value: c.id };
        });
};

export const getSelectListOfAvailableEquipmentComplianceTypes = (
    allAvailableEquipmentComplianceTypes
) => {
    return allAvailableEquipmentComplianceTypes
        .filter((c) => !c.appliesToAllDispatches)
        .map((c) => {
            return { label: c.name, value: c.id };
        });
};

export const getSelectedEquipmentComplianceTypeIds = (
    entityCompliances,
    allAvailableEquipmentComplianceTypes
) => {
    const allAvailableEquipmentComplianceTypeIds =
        allAvailableEquipmentComplianceTypes.map((c) => c.id);

    return entityCompliances
        .filter((cc) =>
            allAvailableEquipmentComplianceTypeIds.includes(cc.complianceTypeId)
        )
        .map((cc) => cc.complianceTypeId);
};

export const getSelectedUserComplianceTypeIds = (
    entityCompliances,
    allAvailableUserComplianceTypes
) => {
    const allAvailableUserComplianceTypeIds =
        allAvailableUserComplianceTypes.map((c) => c.id);

    return entityCompliances
        .filter((cc) =>
            allAvailableUserComplianceTypeIds.includes(cc.complianceTypeId)
        )
        .map((cc) => cc.complianceTypeId);
};

export const isComplianceAssociatedWithEquipmentType = (compliance,
    equipmentTypeId) => {
    return compliance &&
        compliance.complianceEquipmentTypes &&
        compliance.complianceEquipmentTypes
            .some(cet => cet.equipmentTypeId === equipmentTypeId);
}

export const isComplianceAssociatedWithEmployee = (compliance,
    employee) => {
    if (!employee || !employee.groups) {
        return false;
    }

    const employeeGroupIds = employee.groups.map(g => g.id);

    return compliance &&
        compliance.complianceApplicationUserGroups &&
        compliance.complianceApplicationUserGroups
            .some(caug => employeeGroupIds.includes(caug.applicationUserGroupId));
}
