import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup,
    Button,
    Badge,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
} from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    CollapseUnderlineHeader,
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    FlexStartRow,
    toasty,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { DispatchResourceSearchParams } from '../dispatch/Dispatch';
import { BillingStatus } from '../billing/Billing';
import { BillingChargeCategoryEdit } from './BillingChargeCategoryEdit';
import * as moment from 'moment';
import { isEqual } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { JobDetails } from '../job/Job';
import TimeEntry from '../common/forms/TimeEntry';
import NumericInput from '../common/forms/NumericInput';

export default class BillingChargeCategorySlideout extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {
                originalDetails: '',
                originalAdjustments: JSON.stringify([]),
                billing: [],
                adjustments: [],
                jobDetailsOpen: true,
                billingChargeCategorySlideoutOpen: true,
                reviewDetailsOpen: true,
                isPrinting: false,
                notesOpen: true,
                showRejectTimesheetModal: false,
                isRejectingTimesheet: false,
                rejectionNotes: '',
                jobAssignmentNotesOpen: true,
                current: {},
                edit: new BillingChargeCategoryEdit(),
                originalEdit: new BillingChargeCategoryEdit(),
                billValid: true,
                relatedCurrent: {},
                relatedEdit: new BillingChargeCategoryEdit(),
                relatedOriginalEdit: new BillingChargeCategoryEdit(),
                billValid: true,
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount = () => this.populateState();

    async populateState() {
        await this.setState({
            edit: new BillingChargeCategoryEdit(),
            originalEdit: new BillingChargeCategoryEdit(),
            relatedEdit: new BillingChargeCategoryEdit(),
            relatedOriginalEdit: new BillingChargeCategoryEdit(),
        });
    }

    onChange = onFieldChange;
    onSelectChange = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (billingDetailId) => {
        //reset adjustments and validation on the way back in.
        this.props.toggleShow(true);
        this.context.setFormOpened(true);
        await this.setState({
            loading: true,
            adjustments: [],
            formValidated: false,
            rejectionNotes: '',
            showRejectTimesheetModal: false,
        });
        await this.populateState();
        let { edit, originalEdit, relatedEdit, relatedOriginalEdit } = {
            ...this.state,
        };

        var [billing] = await Promise.all([
            util.fetch.js(
                ApiRoutes.billingChargeCategory.slideout(billingDetailId)
            ),
        ]);

        let params = new DispatchResourceSearchParams();
        params.dispatchLocation = billing.dispatchCompanyId;

        this.resetForm();

        //apply filter on payroll
        //if (!!this.props.onlyBillable && this.props.onlyBillable === true) {
        //    payroll.payrollCalculations = payroll.payrollCalculations.filter(x => x.isBillable == true);
        //}
        let current = billing.billingDetails.filter(
            (x) => x.current == true
        )[0];
        if (!!current) {
            edit.currentId = current.id;
            edit.chargeTypeName = current.chargeTypeName;
            edit.employeeName = current.employeeName;
            edit.start = current.startRaw;
            edit.end = current.endRaw;
            edit.increment = current.increment;
            edit.chargeTypeUnitsId = current.chargeTypeUnitsId;
            edit.unitsName = current.unitsName;
            edit.billable = current.billable;
            edit.isBreak = current.isBreak;
            edit.paidBreak = current.paidBreak;
            edit.isSurcharge = current.isSurcharge;

            edit.value = current.value;
            edit.bill = current.bill;
            edit.reg = current.reg;
            edit.wtdReg = current.wtdReg;
            edit.ot = current.ot;
            edit.wtdot = current.wtdot;
            originalEdit = util.object.clone(edit);
        }

        let relatedCurrent = billing.relatedBreaks.filter(
            (x) => x.current == true
        )[0];
        if (!!relatedCurrent) {
            relatedEdit.currentId = relatedCurrent.id;
            relatedEdit.chargeTypeName = relatedCurrent.chargeTypeName;
            relatedEdit.employeeName = relatedCurrent.employeeName;
            relatedEdit.start = relatedCurrent.startRaw;
            relatedEdit.end = relatedCurrent.endRaw;
            relatedEdit.increment = relatedCurrent.increment;
            relatedEdit.chargeTypeUnitsId = relatedCurrent.chargeTypeUnitsId;
            relatedEdit.unitsName = relatedCurrent.unitsName;
            relatedEdit.billable = relatedCurrent.billable;
            relatedEdit.isBreak = relatedCurrent.isBreak;
            relatedEdit.paidBreak = relatedCurrent.paidBreak;

            relatedEdit.value = relatedCurrent.value;
            relatedEdit.bill = relatedCurrent.bill;
            relatedEdit.reg = relatedCurrent.reg;
            relatedEdit.wtdReg = relatedCurrent.wtdReg;
            relatedEdit.ot = relatedCurrent.ot;
            relatedEdit.wtdot = relatedCurrent.wtdot;
            relatedOriginalEdit = util.object.clone(relatedEdit);
        }

        await this.setState({
            id: billingDetailId,
            billing: billing,
            loading: false,
            current: current,
            originalEdit: originalEdit,
            edit: edit,
            relatedCurrent: relatedCurrent,
            relatedEdit: relatedEdit,
            relatedOriginalEdit: relatedOriginalEdit,
        });
    };

    onClose = () => {
        let { saving, originalEdit, edit } = { ...this.state };
        if (!saving && !isEqual({ ...originalEdit }, { ...edit })) {
            var confirmed = window.confirm(
                'You have unsaved changes, are you sure you want to leave?'
            );
            if (!confirmed) return;
        }

        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onPrint = () => {
        //this.setState({ isPrinting: true });
        window.open(ApiRoutes.report.timesheet(this.state.id), '_self');

        //this.setState({ isPrinting: false });
    };

    resetForm = () => {
        this.setState({
            formValidated: false,
        });
    };

    onRefresh = (event) => {
        this.open(this.state.id);
    };

    onTimeEntryChanged = (ev) => {
        let { edit } = { ...this.state },
            val = ev.target.value,
            name = ev.target.name;
        //We display the options in 12-hour format, but the db has 24.
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';
        edit[name] = timeValue;
        this.setState({ edit: edit });
        this.setQuantityForEdit();
    };

    setQuantityForEdit = () => {
        let { edit, billing } = { ...this.state };

        //Calculate the duration between Start and End.  Factor in if this enters into the next day.
        //Use the job start date/time as opposed to moment defaulting to the current day when getting duration.
        if (!!edit.start && !!edit.end) {
            const time_format = 'HH:mm';
            const date_format = 'YYYY-MM-DD';
            const mStartDate = moment(billing.jobStartTimeRaw);
            var mEndDate = moment(billing.jobEndTimeRaw);

            let mStartTime = moment(edit.start, time_format),
                mEndTime = moment(edit.end, time_format);
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
                mEndDate.add(1, 'day');
            }

            const dur = moment.duration(mEndTime.diff(mStartTime));

            edit.startDate = moment(
                `${mStartDate.format(date_format)} ${mStartTime.format(
                    time_format
                )}`
            ).valueOf();
            edit.endDate = moment(
                `${mEndDate.format(date_format)} ${mEndTime.format(
                    time_format
                )}`
            ).valueOf();
            edit.value = dur.asHours().toFixed(2);

            this.setState({ edit: edit });
        }
    };

    onRelatedTimeEntryChanged = (ev) => {
        let { relatedEdit } = { ...this.state },
            val = ev.target.value,
            name = ev.target.name;
        //We display the options in 12-hour format, but the db has 24.
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';
        relatedEdit[name] = timeValue;
        this.setState({ relatedEdit: relatedEdit });
        this.setRelatedQuantityForEdit();
    };

    setRelatedQuantityForEdit = () => {
        let { relatedEdit, billing } = { ...this.state };

        //Calculate the duration between Start and End.  Factor in if this enters into the next day.
        //Use the job start date/time as opposed to moment defaulting to the current day when getting duration.
        if (!!relatedEdit.start && !!relatedEdit.end) {
            const time_format = 'HH:mm';
            const date_format = 'YYYY-MM-DD';
            const mStartDate = moment(billing.jobStartTimeRaw);
            var mEndDate = moment(billing.jobEndTimeRaw);

            let mStartTime = moment(relatedEdit.start, time_format),
                mEndTime = moment(relatedEdit.end, time_format);
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
                mEndDate.add(1, 'day');
            }

            const dur = moment.duration(mEndTime.diff(mStartTime));

            relatedEdit.startDate = moment(
                `${mStartDate.format(date_format)} ${mStartTime.format(
                    time_format
                )}`
            ).valueOf();
            relatedEdit.endDate = moment(
                `${mEndDate.format(date_format)} ${mEndTime.format(
                    time_format
                )}`
            ).valueOf();
            relatedEdit.value = dur.asHours().toFixed(2);

            this.setState({ relatedEdit: relatedEdit });
        }
    };

    onSubmit = async () => {
        await this.setState({ saving: true });
        let {
            id,
            edit,
            originalEdit,
            relatedEdit,
            relatedOriginalEdit,
            billing,
        } = { ...this.state };
        if (!!(billing.billingStatusId == BillingStatus.Processed)) {
            if (!isEqual(edit, originalEdit)) {
                if (
                    !!edit.notes &&
                    edit.notes !== '' &&
                    edit.notes.length > 0 &&
                    this.billIsValid(edit)
                ) {
                    let data = {
                        currentId: edit.currentId,
                        start: edit.start,
                        end: edit.end,
                        value: parseFloat(edit.value),
                        bill: parseFloat(edit.bill),
                        reg: parseFloat(edit.reg),
                        ot: parseFloat(edit.ot),
                        notes: edit.notes,
                        chargeTypeUnitsId: edit.chargeTypeUnitsId,
                    };

                    try {
                        await util.fetch.post(
                            ApiRoutes.billingChargeCategory.edit(),
                            data
                        );
                        toasty.success('Edit saved.');
                        this.open(id);
                    } catch {
                        toasty.error(
                            'There was an issue saving the edited record.  Please try your request again or contact support for assistance.'
                        );
                    } finally {
                        this.setState({ saving: false });
                    }
                }
            }
        }

        if (
            billing.relatedBreaks.length &&
            !isEqual(relatedEdit, relatedOriginalEdit)
        ) {
            if (
                !!relatedEdit.notes &&
                relatedEdit.notes !== '' &&
                relatedEdit.notes.length > 0
            ) {
                let data = {
                    currentId: relatedEdit.currentId,
                    start: relatedEdit.start,
                    end: relatedEdit.end,
                    value: parseFloat(relatedEdit.value),
                    bill: parseFloat(relatedEdit.bill),
                    reg: parseFloat(relatedEdit.reg),
                    ot: parseFloat(relatedEdit.ot),
                    notes: relatedEdit.notes,
                    chargeTypeUnitsId: relatedEdit.chargeTypeUnitsId,
                };

                try {
                    await util.fetch.post(
                        ApiRoutes.billingChargeCategory.edit(),
                        data
                    );
                    toasty.success('Related Break Edit saved.');
                    this.open(id);
                } catch {
                    toasty.error(
                        'There was an issue saving the related edited record.  Please try your request again or contact support for assistance.'
                    );
                } finally {
                    this.setState({ saving: false });
                }
            }
        }

        this.resetForm();
        this.setState({ saving: false });
    };

    billIsValid = (edit) => {
        let valid =
            parseFloat(edit.reg) + parseFloat(edit.ot) ===
            parseFloat(edit.bill).toFixed(2);

        this.setState({ billValid: valid });

        return valid;
    };

    onUnapprove = async () => {
        this.setState({ saving: true });
        let { billing } = { ...this.state };

        try {
            await util.fetch.js(ApiRoutes.billingCharge.unapprove(billing.id));
            toasty.success('Charge Unapproved.');
        } catch {
            toasty.error(
                'There was an issue unapproving charge record.  Please try your request again or contact support for assistance.'
            );
        } finally {
            this.setState({ saving: false });
        }

        this.setState({ saving: false });
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    billIsValid = (edit) => {
        let valid =
            (parseFloat(edit.reg) + parseFloat(edit.ot)).toFixed(2) ===
            parseFloat(edit.bill).toFixed(2);

        this.setState({ billValid: valid });

        return valid;
    };

    render() {
        let {
            billing,
            formValidated,
            validationMessage,
            jobDetailsOpen,
            loading,
            billingChargeCategorySlideoutOpen,
            saving,
            showRejectTimesheetModal,
            isRejectingTimesheet,
            rejectionNotes,
            edit,
            originalEdit,
            relatedEdit,
            relatedOriginalEdit,
            current,
            relatedCurrent,
            billValid,
        } = this.state;

        const tenantSettings =
            ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};

        const jobDetailsHeader = Boolean(
            tenantSettings &&
                tenantSettings.showContractDescriptionInTimesheetView &&
                billing &&
                billing.jobName
        )
            ? `Job Details - ${billing.jobName}`
            : 'Job Details';

        return (
            tenantSettings.billingEnabled &&
            tenantSettings.invoicingEnabled && (
                <>
                    <SlideForm
                        size="col-xl-12 col-md-12 col-xs-12"
                        loading={loading || !tenantSettings}
                        show={this.props.show}
                        id={'billingChargeSlideout'}
                        formIcon={faUserClock}
                        formTitle="Billing Charge Category"
                        ref={this.formRef}
                        setIsValidated={(value) => {
                            this.setState({ formValidated: value });
                        }}
                        isValidated={formValidated}
                        className="pb-2 w-100"
                        onSubmit={this.onSubmit}
                        onClose={this.onClose}
                        errors={this.state.errors}
                        onClearErrors={this.onClearErrors}
                        validationMessage={validationMessage}
                        readOnly={!!saving}
                        hideToolbar={
                            (billing.billingStatusId ?? {}) ==
                            BillingStatus.Approved
                        }
                    >
                        <CollapseUnderlineHeader
                            headerText={jobDetailsHeader}
                            isOpen={jobDetailsOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    jobDetailsOpen: !jobDetailsOpen,
                                })
                            }
                        >
                            <JobDetails
                                isSelfDispatching={billing.isSelfDispatching}
                                isNonFlagging={billing.isNonFlagging}
                                customerName={billing?.customerName}
                                foremanName={billing?.foremanName}
                                jobNotes={billing?.jobNotes}
                                jobNumber={billing?.jobNumber}
                                jobStartTime={billing?.jobStartTime}
                                timesheetId={billing?.timesheetId}
                                timesheetNumber={billing?.timesheetNumber}
                                timesheetStatus={billing?.billingStatus}
                                workOrderNumber={billing?.workOrderNumber}
                                permissions={this.context?.permissions}
                                payrollEmergencyEmployee={
                                    billing?.payrollEmergencyEmployee
                                }
                                isPayrollEmergency={billing?.isPayrollEmergency}
                                tenantSettings={tenantSettings}
                                isPayroll={true}
                                payrollStatusId={billing?.payrollStatusId}
                                onRefresh={this.onRefresh}
                                canEmergency={billing?.canEmergency}
                                isClientCancelled={billing?.isClientCancelled}
                                jobName={billing?.jobName}
                            />

                            <div className="locationDetails">
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="meetingLocation"
                                        text="Meeting Location"
                                        className="bottom-border"
                                    />
                                    <FlexStartRow>
                                        {!!(billing ?? {})
                                            .meetingLocationUrlFormatted && (
                                            <a
                                                rel="noopener noreferrer"
                                                className="text-white mr-2 btn btn-sm btn-success p-1"
                                                title="Open in Google Maps"
                                                href={`https://maps.google.com?q=${
                                                    (billing ?? {})
                                                        .meetingLocationUrlFormatted
                                                }`}
                                                target="_blank"
                                            >
                                                <i class="fa fa-map-marked-alt" />
                                            </a>
                                        )}
                                        <span
                                            id="meetingLocation"
                                            name="meetingLocation"
                                        >
                                            {(billing ?? {}).meetingLocation ??
                                                '-'}
                                        </span>
                                    </FlexStartRow>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="jobLocation"
                                        text="Job Location"
                                        className="bottom-border"
                                    />
                                    <FlexStartRow>
                                        {!!(billing ?? {})
                                            .jobLocationUrlFormatted && (
                                            <a
                                                rel="noopener noreferrer"
                                                className="text-white mr-2 btn btn-sm btn-success p-1"
                                                title="Open in Google Maps"
                                                href={`https://maps.google.com?q=${
                                                    (billing ?? {})
                                                        .jobLocationUrlFormatted
                                                }`}
                                                target="_blank"
                                            >
                                                <i class="fa fa-map-marked-alt" />
                                            </a>
                                        )}
                                        <span
                                            id="jobLocation"
                                            name="jobLocation"
                                        >
                                            {(billing ?? {}).jobLocation ?? '-'}
                                        </span>
                                    </FlexStartRow>
                                </FormGroup>
                            </div>
                        </CollapseUnderlineHeader>

                        <CollapseUnderlineHeader
                            headerText="Billing Charge Category Records"
                            isOpen={billingChargeCategorySlideoutOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    billingChargeCategorySlideoutOpen:
                                        !billingChargeCategorySlideoutOpen,
                                })
                            }
                        >
                            {(billing.billingStatusId ?? {}) ==
                                BillingStatus.Processed && (
                                <>
                                    <FormGroup>
                                        {(billing.billingStatusId ?? {}) ==
                                            BillingStatus.Processed && (
                                            <>
                                                <FormLabel
                                                    htmlFor="meetingLocation"
                                                    text="Edit Current Charge"
                                                    className="bottom-border"
                                                />
                                                <FlexStartRow>
                                                    <table className="table table-sm table-bordered table-striped adjustment-table">
                                                        <thead className="text-muted">
                                                            <tr>
                                                                <th className="isadjusted"></th>
                                                                <th className="payrollcharge">
                                                                    Charge
                                                                </th>
                                                                <th className="payrollname">
                                                                    Name
                                                                </th>
                                                                <th className="payrollnote">
                                                                    Notes
                                                                </th>
                                                                {edit.chargeTypeUnitsId ==
                                                                    1 &&
                                                                    !edit.isSurcharge && (
                                                                        <>
                                                                            <th className="payrollstart">
                                                                                Start
                                                                            </th>
                                                                            <th className="payrollend">
                                                                                End
                                                                            </th>
                                                                        </>
                                                                    )}
                                                                <th className="payrollquantity">
                                                                    Value
                                                                </th>
                                                                <th className="payrollpay">
                                                                    Bill
                                                                </th>
                                                                <th className="payrollpay">
                                                                    Reg
                                                                </th>
                                                                <th className="payrollpay">
                                                                    OT
                                                                </th>
                                                                <th className="payrollunits">
                                                                    Units
                                                                </th>
                                                                <th className="payrollbillable">
                                                                    Billable
                                                                </th>
                                                                <th className="payrollbreak">
                                                                    Break
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="adjustments-payroll">
                                                                <td className="isadjusted"></td>
                                                                <td className="payrollcharge">
                                                                    {
                                                                        edit.chargeTypeName
                                                                    }
                                                                </td>
                                                                <td className="payrollname">
                                                                    {
                                                                        edit.employeeName
                                                                    }
                                                                </td>
                                                                <td className="payrollnotes">
                                                                    <FormGroup className="mb-0">
                                                                        <Input
                                                                            required={
                                                                                !isEqual(
                                                                                    edit,
                                                                                    originalEdit
                                                                                )
                                                                            }
                                                                            type="textarea"
                                                                            name="notes"
                                                                            value={
                                                                                edit.notes ??
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                evt
                                                                            ) => {
                                                                                let val =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    edit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                edit.notes =
                                                                                    val;
                                                                                this.setState(
                                                                                    {
                                                                                        edit: edit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                        <small className="invalid-feedback text-danger">
                                                                            Adjustment
                                                                            notes
                                                                            are
                                                                            required.
                                                                        </small>
                                                                    </FormGroup>
                                                                </td>
                                                                {edit.chargeTypeUnitsId ==
                                                                    1 &&
                                                                    !edit.isSurcharge && (
                                                                        <>
                                                                            <td className="payrollstart">
                                                                                <TimeEntry
                                                                                    required
                                                                                    name="start"
                                                                                    value={
                                                                                        edit.start
                                                                                    }
                                                                                    increment={
                                                                                        edit.increment
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onTimeEntryChanged(
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="payrollend">
                                                                                <TimeEntry
                                                                                    required
                                                                                    name="end"
                                                                                    value={
                                                                                        edit.end
                                                                                    }
                                                                                    increment={
                                                                                        edit.increment
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onTimeEntryChanged(
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                <td
                                                                    className="payrollqty"
                                                                    style={{
                                                                        width: '35%',
                                                                    }}
                                                                >
                                                                    {edit.chargeTypeUnitsId ==
                                                                    2 ? (
                                                                        <>
                                                                            <NumericInput
                                                                                className="form-control-sm text-right"
                                                                                name="quantity"
                                                                                required
                                                                                value={
                                                                                    edit.value ??
                                                                                    ''
                                                                                }
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    let val =
                                                                                            event
                                                                                                .target
                                                                                                .value,
                                                                                        {
                                                                                            edit,
                                                                                        } =
                                                                                            {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                    edit.value =
                                                                                        val;
                                                                                    this.setState(
                                                                                        {
                                                                                            edit: edit,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                type="number"
                                                                                min="0"
                                                                                step="0.5"
                                                                                preventNegative={
                                                                                    true
                                                                                }
                                                                            />
                                                                            <small className="invalid-feedback text-danger">
                                                                                Adjustment
                                                                                Value
                                                                                is
                                                                                required.
                                                                            </small>
                                                                        </>
                                                                    ) : (
                                                                        parseFloat(
                                                                            edit.value
                                                                        ).toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </td>
                                                                <td className="payrollbill">
                                                                    <Input
                                                                        required
                                                                        type="number"
                                                                        step="0.01"
                                                                        name="bill"
                                                                        value={
                                                                            edit.bill ??
                                                                            ''
                                                                        }
                                                                        onChange={(
                                                                            evt
                                                                        ) => {
                                                                            let value =
                                                                                evt
                                                                                    .target
                                                                                    .value;
                                                                            let {
                                                                                edit,
                                                                            } =
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                            edit.bill =
                                                                                value; //parseFloat(value).toFixed(2);
                                                                            this.setState(
                                                                                {
                                                                                    edit: edit,
                                                                                }
                                                                            );
                                                                        }}
                                                                        onBlur={(
                                                                            evt
                                                                        ) => {
                                                                            let value =
                                                                                evt
                                                                                    .target
                                                                                    .value;
                                                                            let {
                                                                                edit,
                                                                            } =
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                            edit.bill =
                                                                                parseFloat(
                                                                                    value
                                                                                ).toFixed(
                                                                                    2
                                                                                );
                                                                            this.setState(
                                                                                {
                                                                                    edit: edit,
                                                                                }
                                                                            );
                                                                        }}
                                                                    />

                                                                    {(!!billValid ??
                                                                        {}) ==
                                                                        false && (
                                                                        <small className="text-danger">
                                                                            Category
                                                                            sum
                                                                            does
                                                                            not
                                                                            match
                                                                            the
                                                                            Bill.
                                                                        </small>
                                                                    )}
                                                                </td>
                                                                <td className="payrollreg">
                                                                    <Input
                                                                        required
                                                                        type="number"
                                                                        step="0.01"
                                                                        name="reg"
                                                                        value={
                                                                            edit.reg ??
                                                                            ''
                                                                        }
                                                                        onChange={(
                                                                            evt
                                                                        ) => {
                                                                            let value =
                                                                                evt
                                                                                    .target
                                                                                    .value;
                                                                            let {
                                                                                edit,
                                                                            } =
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                            edit.reg =
                                                                                value; //parseFloat(value).toFixed(2);
                                                                            this.setState(
                                                                                {
                                                                                    edit: edit,
                                                                                }
                                                                            );
                                                                        }}
                                                                        onBlur={(
                                                                            evt
                                                                        ) => {
                                                                            let value =
                                                                                evt
                                                                                    .target
                                                                                    .value;
                                                                            let {
                                                                                edit,
                                                                            } =
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                            edit.reg =
                                                                                parseFloat(
                                                                                    value
                                                                                ).toFixed(
                                                                                    2
                                                                                );
                                                                            this.setState(
                                                                                {
                                                                                    edit: edit,
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="payrollot">
                                                                    <Input
                                                                        required
                                                                        type="number"
                                                                        step="0.01"
                                                                        name="ot"
                                                                        value={
                                                                            edit.ot ??
                                                                            ''
                                                                        }
                                                                        onChange={(
                                                                            evt
                                                                        ) => {
                                                                            let value =
                                                                                evt
                                                                                    .target
                                                                                    .value;
                                                                            let {
                                                                                edit,
                                                                            } =
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                            edit.ot =
                                                                                value; //parseFloat(value).toFixed(2);
                                                                            this.setState(
                                                                                {
                                                                                    edit: edit,
                                                                                }
                                                                            );
                                                                        }}
                                                                        onBlur={(
                                                                            evt
                                                                        ) => {
                                                                            let value =
                                                                                evt
                                                                                    .target
                                                                                    .value;
                                                                            let {
                                                                                edit,
                                                                            } =
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                            edit.ot =
                                                                                parseFloat(
                                                                                    value
                                                                                ).toFixed(
                                                                                    2
                                                                                );
                                                                            this.setState(
                                                                                {
                                                                                    edit: edit,
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="payrollunits">
                                                                    {
                                                                        edit.unitsName
                                                                    }
                                                                </td>
                                                                <td className="payrollbillable">
                                                                    {
                                                                        edit.billable
                                                                    }
                                                                </td>
                                                                <td className="payrollbreak">
                                                                    {edit.isBreak
                                                                        ? edit.isPaidBreak
                                                                            ? 'Paid'
                                                                            : 'Unpaid'
                                                                        : 'No'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </FlexStartRow>
                                            </>
                                        )}

                                        {(billing.billingStatusId ?? {}) ==
                                            BillingStatus.Approved && (
                                            <>
                                                <FormLabel
                                                    htmlFor="meetingLocation"
                                                    text="Current Charge"
                                                    className="bottom-border"
                                                />
                                                <FlexStartRow>
                                                    <table className="table table-sm table-bordered table-striped payroll-table">
                                                        <thead className="text-muted">
                                                            <tr>
                                                                {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                    <th className="isadjusted"></th>
                                                                )}
                                                                <th className="payrollcharge">
                                                                    Charge
                                                                </th>
                                                                <th className="payrollname">
                                                                    Name
                                                                </th>
                                                                <th className="payrollnote">
                                                                    Notes
                                                                </th>
                                                                <th className="payrollstart">
                                                                    Start
                                                                </th>
                                                                <th className="payrollend">
                                                                    End
                                                                </th>
                                                                <th className="payrollqty">
                                                                    Value
                                                                </th>
                                                                <th className="payrollqty">
                                                                    Bill
                                                                </th>
                                                                <th className="payrollqty">
                                                                    Reg
                                                                </th>
                                                                <th className="payrollqty">
                                                                    OT
                                                                </th>
                                                                <th className="payrollunits">
                                                                    Units
                                                                </th>
                                                                <th className="payrollbillable">
                                                                    Billable
                                                                </th>
                                                                <th className="payrollbreak">
                                                                    Break
                                                                </th>
                                                                <th className="payrollCurrent">
                                                                    Current
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                className={`timesheet-payroll-table-row${
                                                                    !!current.removed
                                                                        ? ' strikeout'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                    <td className="isadjusted">
                                                                        {!!current.isAdjustment && (
                                                                            <Badge className="p-1">
                                                                                ADJ
                                                                            </Badge>
                                                                        )}
                                                                    </td>
                                                                )}
                                                                <td className="payrollcharge">
                                                                    {
                                                                        current.chargeTypeName
                                                                    }
                                                                </td>
                                                                <td className="payrollname">
                                                                    {
                                                                        current.employeeName
                                                                    }
                                                                </td>
                                                                <td className="payrollnote">
                                                                    {!!current.notes && (
                                                                        <div className="userNote">
                                                                            <div className="noteContent">
                                                                                {
                                                                                    current.notes
                                                                                }
                                                                            </div>
                                                                            <div className="noteContentByLine">
                                                                                {`${
                                                                                    current.modifiedBy
                                                                                }, ${moment(
                                                                                    current.modifiedOn
                                                                                ).fromNow()}`}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td className="payrollstart">
                                                                    {
                                                                        current.start
                                                                    }
                                                                </td>
                                                                <td className="payrollend">
                                                                    {
                                                                        current.end
                                                                    }
                                                                </td>
                                                                <td className="payrollqty">
                                                                    {parseFloat(
                                                                        current.value
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollpay">
                                                                    {parseFloat(
                                                                        current.bill
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollreg">
                                                                    {parseFloat(
                                                                        current.reg
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollot">
                                                                    {parseFloat(
                                                                        current.ot
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollunits">
                                                                    {
                                                                        current.unitsName
                                                                    }
                                                                </td>
                                                                <td className="payrollbillable">
                                                                    {
                                                                        current.billable
                                                                    }
                                                                </td>
                                                                <td className="payrollbreak">
                                                                    {current.isBreak
                                                                        ? current.isPaidBreak
                                                                            ? 'Paid'
                                                                            : 'Unpaid'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="payrollcurrent">
                                                                    {current.current
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </FlexStartRow>
                                            </>
                                        )}
                                    </FormGroup>
                                </>
                            )}
                            <div className="billing">
                                <FormLabel
                                    htmlFor="meetingLocation"
                                    text="Charge History"
                                    className="bottom-border"
                                />
                                {(billing.billingStatusId ?? {}) ==
                                    BillingStatus.Processed && (
                                    <table className="table table-sm table-bordered table-striped payroll-table">
                                        <thead className="text-muted">
                                            <tr>
                                                {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                    <th className="isadjusted"></th>
                                                )}
                                                <th className="payrollcharge">
                                                    Charge
                                                </th>
                                                <th className="payrollname">
                                                    Name
                                                </th>
                                                <th className="payrollnote">
                                                    Notes
                                                </th>
                                                <th className="payrollstart">
                                                    Start
                                                </th>
                                                <th className="payrollend">
                                                    End
                                                </th>
                                                <th className="payrollqty">
                                                    Value
                                                </th>
                                                <th className="payrollqty">
                                                    Bill
                                                </th>
                                                <th className="payrollqty">
                                                    Reg
                                                </th>
                                                <th className="payrollqty">
                                                    OT
                                                </th>
                                                <th className="payrollunits">
                                                    Units
                                                </th>
                                                <th className="payrollbillable">
                                                    Billable
                                                </th>
                                                <th className="payrollbreak">
                                                    Break
                                                </th>
                                                <th className="payrollCurrent">
                                                    Current
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!(billing.billingDetails ?? [])
                                                .length ? (
                                                <tr>
                                                    <td
                                                        colSpan="5"
                                                        className="text-center"
                                                    >
                                                        No billing charges
                                                        found.
                                                    </td>
                                                </tr>
                                            ) : (
                                                billing.billingDetails.map(
                                                    (detail, detail_index) => {
                                                        return (
                                                            <tr
                                                                key={detail.id}
                                                                className={`timesheet-payroll-table-row${
                                                                    !!detail.removed
                                                                        ? ' strikeout'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                    <td className="isadjusted">
                                                                        {!!detail.isAdjustment && (
                                                                            <Badge className="p-1">
                                                                                ADJ
                                                                            </Badge>
                                                                        )}
                                                                    </td>
                                                                )}
                                                                <td className="payrollcharge">
                                                                    {
                                                                        detail.chargeTypeName
                                                                    }
                                                                </td>
                                                                <td className="payrollname">
                                                                    {
                                                                        detail.employeeName
                                                                    }
                                                                </td>
                                                                <td className="payrollnote">
                                                                    {!!detail.notes && (
                                                                        <div className="userNote">
                                                                            <div className="noteContent">
                                                                                {
                                                                                    detail.notes
                                                                                }
                                                                            </div>
                                                                            <div className="noteContentByLine">
                                                                                {`${
                                                                                    detail.modifiedBy
                                                                                }, ${moment(
                                                                                    detail.modifiedOn
                                                                                ).fromNow()}`}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td className="payrollstart">
                                                                    {
                                                                        detail.start
                                                                    }
                                                                </td>
                                                                <td className="payrollend">
                                                                    {detail.end}
                                                                </td>
                                                                <td className="payrollqty">
                                                                    {parseFloat(
                                                                        detail.value
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollpay">
                                                                    {parseFloat(
                                                                        detail.bill
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollreg">
                                                                    {parseFloat(
                                                                        detail.reg
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollot">
                                                                    {parseFloat(
                                                                        detail.ot
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                                <td className="payrollunits">
                                                                    {
                                                                        detail.unitsName
                                                                    }
                                                                </td>
                                                                <td className="payrollbillable">
                                                                    {
                                                                        detail.billable
                                                                    }
                                                                </td>
                                                                <td className="payrollbreak">
                                                                    {detail.isBreak
                                                                        ? detail.isPaidBreak
                                                                            ? 'Paid'
                                                                            : 'Unpaid'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="payrollcurrent">
                                                                    {detail.current
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </CollapseUnderlineHeader>

                        {!!(billing.relatedBreaks ?? []).length && (
                            <>
                                <CollapseUnderlineHeader
                                    headerText="Related Break Charge Records"
                                    isOpen={billingChargeCategorySlideoutOpen}
                                    toggleCollapse={() =>
                                        this.setState({
                                            billingChargeCategorySlideoutOpen:
                                                !billingChargeCategorySlideoutOpen,
                                        })
                                    }
                                >
                                    {(billing.billingStatusId ?? {}) ==
                                        BillingStatus.Processed && (
                                        <>
                                            <FormGroup>
                                                <>
                                                    <FormLabel
                                                        htmlFor="meetingLocation"
                                                        text="Edit Current Break"
                                                        className="bottom-border"
                                                    />
                                                    <FlexStartRow>
                                                        <table className="table table-sm table-bordered table-striped adjustment-table">
                                                            <thead className="text-muted">
                                                                <tr>
                                                                    <th className="isadjusted"></th>
                                                                    <th className="payrollcharge">
                                                                        Charge
                                                                    </th>
                                                                    <th className="payrollname">
                                                                        Name
                                                                    </th>
                                                                    <th className="payrollnote">
                                                                        Notes
                                                                    </th>
                                                                    {relatedEdit.chargeTypeUnitsId ==
                                                                        1 && (
                                                                        <>
                                                                            <th className="payrollstart">
                                                                                Start
                                                                            </th>
                                                                            <th className="payrollend">
                                                                                End
                                                                            </th>
                                                                        </>
                                                                    )}
                                                                    <th className="payrollquantity">
                                                                        Value
                                                                    </th>
                                                                    <th className="payrollpay">
                                                                        Bill
                                                                    </th>
                                                                    <th className="payrollpay">
                                                                        Reg
                                                                    </th>
                                                                    <th className="payrollpay">
                                                                        OT
                                                                    </th>
                                                                    <th className="payrollunits">
                                                                        Units
                                                                    </th>
                                                                    <th className="payrollbillable">
                                                                        Billable
                                                                    </th>
                                                                    <th className="payrollbreak">
                                                                        Break
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="adjustments-payroll">
                                                                    <td className="isadjusted"></td>
                                                                    <td className="payrollcharge">
                                                                        {
                                                                            relatedEdit.chargeTypeName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollname">
                                                                        {
                                                                            relatedEdit.employeeName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollnotes">
                                                                        <FormGroup className="mb-0">
                                                                            <Input
                                                                                required={
                                                                                    !isEqual(
                                                                                        relatedEdit,
                                                                                        relatedOriginalEdit
                                                                                    )
                                                                                }
                                                                                type="textarea"
                                                                                name="notes"
                                                                                value={
                                                                                    relatedEdit.notes ??
                                                                                    ''
                                                                                }
                                                                                onChange={(
                                                                                    evt
                                                                                ) => {
                                                                                    let val =
                                                                                        evt
                                                                                            .target
                                                                                            .value;
                                                                                    let {
                                                                                        relatedEdit,
                                                                                    } =
                                                                                        {
                                                                                            ...this
                                                                                                .state,
                                                                                        };
                                                                                    relatedEdit.notes =
                                                                                        val;
                                                                                    this.setState(
                                                                                        {
                                                                                            relatedEdit:
                                                                                                relatedEdit,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <small className="invalid-feedback text-danger">
                                                                                Adjustment
                                                                                notes
                                                                                are
                                                                                required.
                                                                            </small>
                                                                        </FormGroup>
                                                                    </td>
                                                                    {relatedEdit.chargeTypeUnitsId ==
                                                                        1 && (
                                                                        <>
                                                                            <td className="payrollstart">
                                                                                <TimeEntry
                                                                                    required
                                                                                    name="start"
                                                                                    value={
                                                                                        relatedEdit.start
                                                                                    }
                                                                                    increment={
                                                                                        relatedEdit.increment
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onRelatedTimeEntryChanged(
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="payrollend">
                                                                                <TimeEntry
                                                                                    required
                                                                                    name="end"
                                                                                    value={
                                                                                        relatedEdit.end
                                                                                    }
                                                                                    increment={
                                                                                        relatedEdit.increment
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onRelatedTimeEntryChanged(
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                    <td
                                                                        className="payrollqty"
                                                                        style={{
                                                                            width: '35%',
                                                                        }}
                                                                    >
                                                                        {relatedEdit.chargeTypeUnitsId ==
                                                                        2 ? (
                                                                            <>
                                                                                <NumericInput
                                                                                    className="form-control-sm text-right"
                                                                                    name="quantity"
                                                                                    required
                                                                                    value={
                                                                                        relatedEdit.value ??
                                                                                        ''
                                                                                    }
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        let val =
                                                                                                event
                                                                                                    .target
                                                                                                    .value,
                                                                                            {
                                                                                                relatedEdit,
                                                                                            } =
                                                                                                {
                                                                                                    ...this
                                                                                                        .state,
                                                                                                };
                                                                                        relatedEdit.value =
                                                                                            val;
                                                                                        this.setState(
                                                                                            {
                                                                                                relatedEdit:
                                                                                                    relatedEdit,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    type="number"
                                                                                    min="0"
                                                                                    step="0.5"
                                                                                    preventNegative={
                                                                                        true
                                                                                    }
                                                                                />
                                                                                <small className="invalid-feedback text-danger">
                                                                                    Adjustment
                                                                                    Value
                                                                                    is
                                                                                    required.
                                                                                </small>
                                                                            </>
                                                                        ) : (
                                                                            parseFloat(
                                                                                relatedEdit.value
                                                                            ).toFixed(
                                                                                2
                                                                            )
                                                                        )}
                                                                    </td>
                                                                    <td className="payrollbill">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="bill"
                                                                            value={
                                                                                relatedEdit.bill ??
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                evt
                                                                            ) => {
                                                                                let value =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    relatedEdit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                relatedEdit.bill =
                                                                                    value; //parseFloat(value).toFixed(2);
                                                                                this.setState(
                                                                                    {
                                                                                        relatedEdit:
                                                                                            relatedEdit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onBlur={(
                                                                                evt
                                                                            ) => {
                                                                                let value =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    relatedEdit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                relatedEdit.bill =
                                                                                    parseFloat(
                                                                                        value
                                                                                    ).toFixed(
                                                                                        2
                                                                                    );
                                                                                this.setState(
                                                                                    {
                                                                                        relatedEdit:
                                                                                            relatedEdit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="payrollreg">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="reg"
                                                                            value={
                                                                                relatedEdit.reg ??
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                evt
                                                                            ) => {
                                                                                let value =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    relatedEdit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                relatedEdit.reg =
                                                                                    value; //parseFloat(value).toFixed(2);
                                                                                this.setState(
                                                                                    {
                                                                                        relatedEdit:
                                                                                            relatedEdit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onBlur={(
                                                                                evt
                                                                            ) => {
                                                                                let value =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    relatedEdit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                relatedEdit.reg =
                                                                                    parseFloat(
                                                                                        value
                                                                                    ).toFixed(
                                                                                        2
                                                                                    );
                                                                                this.setState(
                                                                                    {
                                                                                        relatedEdit:
                                                                                            relatedEdit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="payrollot">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="ot"
                                                                            value={
                                                                                relatedEdit.ot ??
                                                                                ''
                                                                            }
                                                                            onChange={(
                                                                                evt
                                                                            ) => {
                                                                                let value =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    relatedEdit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                relatedEdit.ot =
                                                                                    value; //parseFloat(value).toFixed(2);
                                                                                this.setState(
                                                                                    {
                                                                                        relatedEdit:
                                                                                            relatedEdit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onBlur={(
                                                                                evt
                                                                            ) => {
                                                                                let value =
                                                                                    evt
                                                                                        .target
                                                                                        .value;
                                                                                let {
                                                                                    relatedEdit,
                                                                                } =
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    };
                                                                                relatedEdit.ot =
                                                                                    parseFloat(
                                                                                        value
                                                                                    ).toFixed(
                                                                                        2
                                                                                    );
                                                                                this.setState(
                                                                                    {
                                                                                        relatedEdit:
                                                                                            relatedEdit,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="payrollunits">
                                                                        {
                                                                            relatedEdit.unitsName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollbillable">
                                                                        {
                                                                            relatedEdit.billable
                                                                        }
                                                                    </td>
                                                                    <td className="payrollbreak">
                                                                        {relatedEdit.isBreak
                                                                            ? relatedEdit.isPaidBreak
                                                                                ? 'Paid'
                                                                                : 'Unpaid'
                                                                            : 'No'}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </FlexStartRow>
                                                </>
                                            </FormGroup>
                                        </>
                                    )}
                                    <div className="billing">
                                        <FormLabel
                                            htmlFor="meetingLocation"
                                            text="Break History"
                                            className="bottom-border"
                                        />
                                        {(billing.billingStatusId ?? {}) ==
                                            BillingStatus.Processed && (
                                            <table className="table table-sm table-bordered table-striped payroll-table">
                                                <thead className="text-muted">
                                                    <tr>
                                                        {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                            <th className="isadjusted"></th>
                                                        )}
                                                        <th className="payrollcharge">
                                                            Charge
                                                        </th>
                                                        <th className="payrollname">
                                                            Name
                                                        </th>
                                                        <th className="payrollnote">
                                                            Notes
                                                        </th>
                                                        <th className="payrollstart">
                                                            Start
                                                        </th>
                                                        <th className="payrollend">
                                                            End
                                                        </th>
                                                        <th className="payrollqty">
                                                            Value
                                                        </th>
                                                        <th className="payrollpay">
                                                            Bill
                                                        </th>
                                                        <th className="payrollpay">
                                                            Reg
                                                        </th>
                                                        <th className="payrollpay">
                                                            OT
                                                        </th>
                                                        <th className="payrollunits">
                                                            Units
                                                        </th>
                                                        <th className="payrollbillable">
                                                            Billable
                                                        </th>
                                                        <th className="payrollbreak">
                                                            Break
                                                        </th>
                                                        <th className="payrollCurrent">
                                                            Current
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!(
                                                        billing.relatedBreaks ??
                                                        []
                                                    ).length ? (
                                                        <tr>
                                                            <td
                                                                colSpan="5"
                                                                className="text-center"
                                                            >
                                                                No billing
                                                                charges found.
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        billing.relatedBreaks.map(
                                                            (
                                                                detail,
                                                                detail_index
                                                            ) => {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            detail.id
                                                                        }
                                                                        className={`timesheet-payroll-table-row${
                                                                            !!detail.removed
                                                                                ? ' strikeout'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                            <td className="isadjusted">
                                                                                {!!detail.isAdjustment && (
                                                                                    <Badge className="p-1">
                                                                                        ADJ
                                                                                    </Badge>
                                                                                )}
                                                                            </td>
                                                                        )}
                                                                        <td className="payrollcharge">
                                                                            {
                                                                                detail.chargeTypeName
                                                                            }
                                                                        </td>
                                                                        <td className="payrollname">
                                                                            {
                                                                                detail.employeeName
                                                                            }
                                                                        </td>
                                                                        <td className="payrollnote">
                                                                            {!!detail.notes && (
                                                                                <div className="userNote">
                                                                                    <div className="noteContent">
                                                                                        {
                                                                                            detail.notes
                                                                                        }
                                                                                    </div>
                                                                                    <div className="noteContentByLine">
                                                                                        {`${
                                                                                            detail.modifiedBy
                                                                                        }, ${moment(
                                                                                            detail.modifiedOn
                                                                                        ).fromNow()}`}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="payrollstart">
                                                                            {
                                                                                detail.start
                                                                            }
                                                                        </td>
                                                                        <td className="payrollend">
                                                                            {
                                                                                detail.end
                                                                            }
                                                                        </td>
                                                                        <td className="payrollqty">
                                                                            {parseFloat(
                                                                                detail.value
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                        <td className="payrollpay">
                                                                            {parseFloat(
                                                                                detail.bill
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                        <td className="payrollreg">
                                                                            {parseFloat(
                                                                                detail.reg
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                        <td className="payrollot">
                                                                            {parseFloat(
                                                                                detail.ot
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                        <td className="payrollunits">
                                                                            {
                                                                                detail.unitsName
                                                                            }
                                                                        </td>
                                                                        <td className="payrollbillable">
                                                                            {
                                                                                detail.billable
                                                                            }
                                                                        </td>
                                                                        <td className="payrollbreak">
                                                                            {detail.isBreak
                                                                                ? detail.isPaidBreak
                                                                                    ? 'Paid'
                                                                                    : 'Unpaid'
                                                                                : 'No'}
                                                                        </td>
                                                                        <td className="payrollcurrent">
                                                                            {detail.current
                                                                                ? 'Yes'
                                                                                : 'No'}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </CollapseUnderlineHeader>
                            </>
                        )}

                        {/*<FlexCenterRow className="pb-3">*/}
                        {/*    {(billing.billingStatusId ?? {}) == BillingStatus.Approved &&*/}
                        {/*        <Button*/}
                        {/*            className="mr-3"*/}
                        {/*            color="danger"*/}
                        {/*            disabled={!!saving}*/}
                        {/*            onClick={() => this.onUnapprove()}*/}
                        {/*        >*/}
                        {/*            <FontAwesomeIcon size="lg" icon={saving ? faCircleNotch : faTimes} className="mr-2" />*/}
                        {/*            {saving ? 'Saving, Please Wait...' : 'Unapprove'}*/}
                        {/*        </Button>*/}
                        {/*    }*/}
                        {/*</FlexCenterRow>*/}
                    </SlideForm>
                    <Modal
                        backdrop="static"
                        keyboard={false}
                        isOpen={showRejectTimesheetModal}
                    >
                        <ModalHeader>Reject Timesheet</ModalHeader>
                        <ModalBody>
                            <Form id="timeheetRejectionForm">
                                <FormGroup>
                                    <FormLabel
                                        required={true}
                                        text="Notes"
                                    ></FormLabel>
                                    <textarea
                                        disabled={!!saving}
                                        id="timesheetRejectionNotes"
                                        name="timesheetRejectionNotes"
                                        className="form-control"
                                        defaultValue={rejectionNotes ?? ''}
                                        onChange={this.onChange}
                                        required
                                        placeholder="Enter notes regarding the rejection."
                                        type="text"
                                        maxLength="500"
                                        rows="5"
                                    />
                                    <small className="text-danger">
                                        Notes are required.
                                    </small>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            {isRejectingTimesheet && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                className="d-flex flex-row flex-nowrap align-items-center"
                                color={!!saving ? 'secondary' : 'primary'}
                                disabled={
                                    !!saving ||
                                    !!loading ||
                                    !!isRejectingTimesheet
                                }
                                onClick={this.rejectTimesheet}
                            >
                                {!!this.state.saving && (
                                    <>
                                        <CircularProgress
                                            size={24}
                                            className="saving-button-progress text-success mr-2"
                                        />
                                        <span>Saving, please wait...</span>
                                    </>
                                )}
                                {!this.state.saving && (
                                    <span>Save and Reject Timesheet</span>
                                )}
                            </Button>
                            <Button
                                disabled={!!saving}
                                color="secondary"
                                onClick={() => {
                                    if (!saving) {
                                        this.setState({
                                            selectedRowRejectionNotes: '',
                                            showRejectTimesheetModal: false,
                                        });
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )
        );
    }
}
