export class BillingChargeEdit {
    currentId = null;
    chargeTypeName = '';
    employeeName = '';
    start = null;
    end = null;
    value = 0.00;
    bill = 0.00;
    notes = '';
    increment = null; 
    unitsName = '';
    billable = null;
    isBreak = null;
    paidBreak = null;
    isOverride = false;
    isCustomOverride = false;
    isMinMaxBill = false;
    isCancel = false;
}