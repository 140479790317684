import { faQuestionCircle } from '@fortawesome/fontawesome-free-solid';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Badge } from 'reactstrap';
import { FlexCenterRow } from '../common/forms/FormElements';

export default class ReorderListWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entities: [],
            originalData: [],
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onSave = () => {
        this.props.onSaveCallback();
    }

    onDragEnd = (result) => {
        let entities = [...this.props.entities];
        if (!result.destination) return;
        const [reorderedItem] = entities.splice(result.source.index, 1);
        entities.splice(result.destination.index, 0, reorderedItem);
        this.props.setUpdatedEntities(entities);
    }

    render() {

        return (
            <>
                <div className="reorder-list-widget mb-3">
                    <header>
                        <div className="w-100 d-flex flex-row align-items-center pt-1 pb-1">
                            <div className="flex-fill d-flex flex-row align-items-center">
                                <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
                                <small>You may reorder the list by dragging and dropping the items in the list.</small>
                            </div>
                        </div>
                    </header>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="entities" >
                            {(provided) => (
                                <ul className="reorder-list-widget-droppable list-group" {...provided.droppableProps} ref={provided.innerRef}>
                                    {this.props.entities.map((entity, index) => {

                                        if (this.props.customColors) {

                                            var draggableLine = "";
                                            for (var i = 0; i < this.props.entityName.length; i++) {

                                                if (i == 0)
                                                    continue;

                                                if (draggableLine.length > 0) {
                                                    draggableLine += " - "
                                                }

                                                var str = entity[this.props.entityName[i]];
                                                if (str != null && str.length > 0)
                                                    draggableLine += str;
                                            }

                                            var id = "id_" + entity[this.props.entityName[0]] + "_" + index;

                                            return (
                                                <Draggable key={id} draggableId={id} index={index}>
                                                    {(provided) => (

                                                        <li className="list-group-item d-flex flex-row flex-nowrap align-items-center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {
                                                                <>
                                                                    <span className="lead mr-2">
                                                                        <Badge color={this.props.entityColorMap[entity[this.props.entityColorIndex] - 1]} size="lg">
                                                                            {`${index + 1}`}
                                                                        </Badge>
                                                                    </span>
                                                                    { draggableLine }
                                                                </>
                                                            }
                                                        </li>

                                                    )}
                                                </Draggable>
                                            );
                                        }

                                        else {
                                            return (
                                                <Draggable key={entity[this.props.entityName]} draggableId={entity[this.props.entityName]} index={index}>
                                                    {(provided) => (

                                                        <li className="list-group-item d-flex flex-row flex-nowrap align-items-center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {
                                                                <>
                                                                    <span className="lead mr-2">
                                                                        <Badge color="success" size="lg">
                                                                            {`${index + 1}`}
                                                                        </Badge>
                                                                    </span>
                                                                    {`${entity[this.props.entityName]}`}
                                                                </>
                                                            }
                                                        </li>

                                                    )}
                                                </Draggable>
                                            );
                                        }
                                    })}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>                    
                </div>
                <FlexCenterRow className="mb-3">
                    <Button
                        size="sm"
                        name="sawdwad"
                        type="button"
                        color="primary"
                        onClick={this.onSave}>
                        <FontAwesomeIcon
                            className="mr-2"
                            icon={faSave} />
                            {"Save"}
                    </Button>
                </FlexCenterRow>
            </>
        )
    }
}