import * as moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import { TextFilterDefaults } from '../common/dataGrid/DataGrid';
import { FlexCenterRow, FlexStartRow } from '../common/forms/FormElements';
import { TimesheetNumberCellRenderer } from '../common/dataGrid/DataGrid';

export const TimesheetStatus = {
    New: 1,
    ClientReview: 2,
    EmployeeReview: 3,
    Submitted: 4,
    Rejected: 5,
    CancelPending: 6,
    Approved: 7,
    BillingPayrollRejected: 8,
    Exported: 9,
};

export const TimesheetAuditType = {
    Created: 1,
    Submitted: 2,
    Rejected: 3,
    Approved: 4,
    Deleted: 5,
    DispatchCancelled: 6,
    ClientCancelApplied: 7,
    ClientCancelRemoved: 8,
};

export class Timesheet {
    id = null;
    companyId = null;
    description = '';
    groups = [];
    employeeAssignableQuantity = null;
    resourceAssignableQuantity = null;
    requiredEquipment = [];
}

export class TimesheetRejectionNote {
    /*long*/ timesheetId = null;
    /*string*/ note = null;
    /*string*/ createdBy = '';
    /*string*/ createdByName = '';
    /*DateTime*/ createdOn = null;
}

export class RejectionNote extends React.Component {
    render() {
        //expects TimesheetRejectionNote
        const { note } = { ...this.props };
        if (!note) return null;
        return (
            <div
                className="m-0 mt-1 mb-1 site-alert site-alert-danger d-flex flex-column"
                role="alert"
            >
                <FlexCenterRow className="pb-1 mb-1 rejection-note-header">
                    <i className="fa fa-times-circle mr-1"></i>
                    <strong>REJECTED</strong>
                </FlexCenterRow>
                <FlexStartRow>
                    <small>
                        <strong className="mr-1">{note?.createdByName}</strong>
                    </small>
                    <small>{`(${moment(note?.createdOn).fromNow()})`}</small>
                </FlexStartRow>
                <span>{note?.note}</span>
                <span className="mt-1"></span>
            </div>
        );
    }
}

export class ClientCancelled extends React.Component {
    render() {
        return (
            <div
                className="m-0 mt-1 mb-1 site-alert site-alert-danger d-flex flex-column"
                role="alert"
            >
                <FlexStartRow>
                    <small>
                        <strong className="mr-1">
                            NOTICE: This job has been cancelled by the customer.
                            Please complete the timesheet as appropriate and
                            submit.
                        </strong>
                    </small>
                </FlexStartRow>
                <span className="mt-1"></span>
            </div>
        );
    }
}

export const colTimesheetNumber = {
    colId: 'TimesheetNumber', //leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
    headerName: 'Timesheet #',
    field: 'timesheetNumber',
    sortable: true,
    flex: 1.5,
    filter: 'agTextColumnFilter',
    filterParams: TextFilterDefaults,
    floatingFilterComponentParams: {
        suppressFilterButton: true,
    },
    cellRenderer: TimesheetNumberCellRenderer,
};

export const colTimesheetWorkOrderNumber = {
    colId: 'WorkOrderNumber',
    headerName: 'Work Order',
    field: 'workOrderNumber',
    sortable: true,
    flex: 1.5,
    filter: 'agTextColumnFilter',
    filterParams: TextFilterDefaults,
    floatingFilterComponentParams: {
        suppressFilterButton: true,
    },
};

export function getTimesheetStatusColumn(
    columnId,
    fieldName,
    timesheetStatuses
) {
    return {
        colId: columnId,
        headerName: 'Status',
        field: fieldName,
        sortable: true,
        flex: 2,
        tooltipField: fieldName,
        filter: 'selectFilter',
        floatingFilter: true,
        filterParams: {
            suppressFilterButton: true,
            options: timesheetStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        },
        floatingFilterComponent: 'selectFloatingFilter',
        floatingFilterComponentParams: {
            suppressFilterButton: true,
            options: timesheetStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        },
        valueGetter: (row) => {
            if (!!row.data) {
                let isAddStatus =
                    !!row.data.isZeroHours ||
                    !!row.data.isEmergency ||
                    !!row.data.isClientCancelled;
                let addStatus = '';
                if (!!row.data.isEmergency) addStatus += ' - Emergency';
                if (!!row.data.isZeroHours) addStatus += ' - Zero Hours';
                if (!!row.data.isClientCancelled)
                    addStatus += ' - Client Cancel';

                let status = `${row.data.status}${
                    isAddStatus ? addStatus : ''
                }`;
                return status;
            }

            return '';
        },
    };
}

/**
 * @augments {Component<{ loadingMessage:string>}
 */
export class CustomMyTimesheetsLoadingOverlay extends Component {
    render() {
        return (
            <div
                className="my-timesheets-loading-overlay"
                style={{ backgroundColor: 'lightsteelblue', height: '9%' }}
            >
                <i className="fas fa-hourglass-half">
                    {' '}
                    {this.props.loadingMessage}{' '}
                </i>
            </div>
        );
    }
}

/**
 * @augments {Component<{ noRowsMessageFunc:function>}
 */
export class CustomMyTimesheetsNoRowsOverlay extends Component {
    render() {
        return (
            <div
                className="ag-overlay-loading-center"
                style={{ backgroundColor: 'lightcoral', height: '9%' }}
            >
                {this.props.noRowsMessageFunc()}
            </div>
        );
    }
}

/**
 * Calculates hours duration between a start time and end time for a given date.
 *
 * @param {string} start HH:mm
 * @param {string} end HH:mm
 * @param {string} date YYYY-MM-DD
 */
export function calculateDuration(start, end, date) {
    const time_format = 'HH:mm';
    const date_format = 'YYYY-MM-DD';
    const mStartDate = moment(date);
    var mEndDate = moment(date);

    let mStartTime = moment(start, time_format),
        mEndTime = moment(end, time_format);
    if (mEndTime.isBefore(mStartTime)) {
        mEndTime.add(1, 'day');
        mEndDate.add(1, 'day');
    }

    const dur = moment.duration(mEndTime.diff(mStartTime));

    let startDate = moment(
        `${mStartDate.format(date_format)} ${mStartTime.format(time_format)}`
    ).valueOf();
    let endDate = moment(
        `${mEndDate.format(date_format)} ${mEndTime.format(time_format)}`
    ).valueOf();
    let quantity = dur.asHours().toFixed(2);

    return { startDate, endDate, quantity };
}
