export class BillingChargeCategoryEdit {
    currentId = null;
    start = null;
    end = null;
    value = 0.00;
    bill = 0.00;
    reg = 0.00;
    wtdReg = 0.00;
    ot = 0.00;
    wtdot = 0.00;
    notes = '';
    increment = null;    
}