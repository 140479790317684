import { faMinusCircle } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CardBody, CardTitle, Input } from 'reactstrap';
import cls from 'classnames';

export default class EquipmentAssignmentCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        }
    }

    setRef = (ref) => {
        // keep a reference to the dom ref as an instance property
        this.ref = ref;
        // give the dom ref to react-beautiful-dnd
        this.props.innerRef(ref);
    };

    renderStaffAssignmentOptions = () => {
        const { dayAssignments, requiredEquipment, jobIndex, day, assignmentIndex } = { ...this.props };
        
        let assignment = dayAssignments.equipment[assignmentIndex];
        //does this have required groups?
        if (!!(requiredEquipment.equipmentType.groups ?? []).length) {

            //grab objects for all staff that's been assigned for the day
            let todayAssignedStaff = dayAssignments.staff.filter(a => !!a.assignment).map(a => a.assignment);

            //Get any employee groups required by the equipment
            let requiredEmpGroups = requiredEquipment.equipmentType.groups.map(eg => eg.id);

            let qualifiedStaff = (todayAssignedStaff ?? []).filter(s => {
                //filter the day's staff by the required emp groups.
                let empGroupIds = s.groups.map(grp => grp.id);
                let hasGroup = !!empGroupIds.filter(groupId => requiredEmpGroups.includes(groupId)).length;
                return hasGroup;
            });

            return (
                <div className="d-flex flex-column">
                    <strong className="text-muted text-right">Assigned To</strong>
                    <Input
                        type="select"
                        placeholder="Assign"
                        className={cls({ "text-success": !!assignment.assignedEmployee, "text-muted": !assignment.assignedEmployee })}
                        value={assignment?.assignedEmployee?.id ?? ""}
                        onChange={(event) => {
                            if (!event.target.value) {
                                event.stopPropagation();
                                return false;
                            }
                            const resourceId = parseInt(event.target.value);
                            this.props.onEquipmentAssignmentChange(jobIndex, day.getDay(), 'staff', assignmentIndex, parseInt(resourceId));
                        }}
                    >
                        <option className="text-muted" value="">{`Assign Staff`}</option>
                        {(qualifiedStaff ?? []).map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                    </Input>
                </div>
            )
        }
        return null;
    }

    renderEquipmentAssignmentOptions = () => {

        const {
            dayAssignments, //all assignments.  we need this to see what has been assigned thus far today.
            requiredEquipment, // the currently required equipment of the job resource.
            jobIndex, //index from the job, so we can look it up (BDnD doesnt do objects)
            day, //day of week 
            assignmentIndex //assignment index, so we can look it up (BDnD doesnt do objects).
        } = { ...this.props };

        //The current job assignment object
        let assignment = dayAssignments.equipment[assignmentIndex];

        //does this have required equipment?
        if (!!(requiredEquipment /* The type of equipment required by the job resource */
                .equipmentType /* The base equipment type */
                    .requiredEquipment /* Equipment that is required BY THE BASE equipment type....i.e. Arrow Board that requires a Work Truck to get around. */
            ?? []).length) /* Do we have any */ {


            //get all of this equipment's required equipment Ids.  
            const requiredEquipmentTypeIds = requiredEquipment.equipmentType.requiredEquipment.map(x => x.id);

            //get all equipment assignments for the day . 
            const assignedEquipmentToday = dayAssignments.equipment.filter(x =>
                x.id !== (assignment ?? {}).id /*(don't include the current assignment)*/
                    && !!x.assignment).map(e => e.assignment);
  
            //filter the day's equipment assignments by the required equipment type, so the user can assign the required dependency.
            let options = assignedEquipmentToday.filter(x => requiredEquipmentTypeIds.includes(x.equipmentTypeId));

            if (!!options) { //display the dropdown only when theres something to show.
                return (
                    <div className="d-flex flex-column">
                        <strong className="text-muted text-right">Assigned To</strong>
                        <Input
                            type="select"
                            placeholder="Assign Equipment"
                            className={cls({ "text-success": !!(assignment?.assignedEquipment?.id), "text-muted": !(assignment?.assignedEquipment?.id) })}
                            value={assignment?.assignedEquipment?.id ?? ""}
                            onChange={(event) => {
                                if (!event.target.value) {
                                    event.stopPropagation();
                                    return false;
                                }
                                const resourceId = parseInt(event.target.value);                                
                                this.props.onEquipmentAssignmentChange(jobIndex, day.getDay(), 'equipment', assignmentIndex, parseInt(resourceId));
                            }}
                        >
                            <option className="text-muted" value="">{`Assign Equipment`}</option>
                            {options.map(o => <option key={o.id} value={o.id}>{`${o.description} (${o.equipmentType.description})`}</option>)}
                        </Input>                        
                    </div>
                )
            }
        }
        return null;
    }

    render = () => {
        const { job, requiredEquipment, assignmentRequirements, day } = { ...this.props };
        const dayInt = day.getDay();
        let isEquipmentAssignment = (!!(requiredEquipment.equipmentType.requiredEquipment ?? []).length);
        return (
            <div className={cls("card equipmentCard compact")}>
                <CardBody>
                    <CardTitle className="text-left d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row flex-fill flex-nowrap justify-space-between align-items-center">
                            <span className="flex-fill d-flex flex-column">
                                <strong className="text-muted">{`${requiredEquipment.equipmentType.description}`}</strong>
                                {`${requiredEquipment.description}`}
                            </span>
                            <div className="assignmentDropdown">
                                {!isEquipmentAssignment && this.renderStaffAssignmentOptions()}
                                {!!isEquipmentAssignment && this.renderEquipmentAssignmentOptions()}
                                <span className={`text-right assignment-status-label ${requiredEquipment.confirmationStatusName.replace(' ', '').toLowerCase()}`}>{requiredEquipment.confirmationStatusName}</span>
                            </div>   
                        </div>  
                        {
                            //job.canEdit is backed by whether or not the crew has submitted a timesheet.
                            //if we have a timesheet for this crew for this day, we cannot remove the assignment.
                            !!this.props.removable && !!job.assignments[dayInt].canEdit &&
                            <FontAwesomeIcon
                                title="Remove this equipment"
                                icon={faMinusCircle}
                                size="lg"
                                className="text-danger cursor-pointer"
                                onClick={() => {
                                    this.props.onRemoveAssignment(job, assignmentRequirements, dayInt)
                                }} />
                        }
                    </CardTitle>
                </CardBody>
            </div>
        );
    }
}