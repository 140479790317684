import React from 'react';
import { createDataSource, createGridOptions, DataGrid, LinkCellRenderer, TextFilterDefaults, indexCellRenderer } from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';

class QuickBooksInventoryItemsIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
            selectedQuickBooksInventoryItem: {},
            selectedException: null,
            selectedRow: null
        }
        this.onAddQuickBooksInventoryItem = this.onAddItem.bind(this);
        this.onEditQuickBooksInventoryItem = this.onEditItem.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () => this.setState = (state, callback) => { return };

    async populateState() {
        let gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                cellRenderer: indexCellRenderer
            },
            {
                colId: 'Name',
                sortable: true,
                headerName: 'Name',
                field: 'name',
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditItem,
                    nameField: 'name',
                    idField: 'id',
                    title: 'View this Inventory Item'
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'ChargeTypes',
                sortable: true,
                headerName: 'Charge Types',
                field: 'chargeTypes',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Companies',
                sortable: true,
                headerName: 'Applied Companies',
                field: 'companies',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Contracts',
                sortable: true,
                headerName: 'Applied Contracts',
                field: 'contracts',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            }
        ];

        let dataSource = createDataSource(ApiRoutes.quickbooks.search(), gridOptions)

        this.setState(state => {
            return {
                loading: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            }
        });
    }

    onSelectionChanged = event => {
        var selection = event.api.getSelectedRows();
        this.setState({ selectedRow: !!selection.length ? selection[0] : null });
    }

    onAddItem = () => {
        this.props.history.push(`${AppNavPaths.QuickBooksInventoryItemNew}`);
    }

    onEditItem = (id) => {
        this.props.history.push(`${AppNavPaths.QuickBooksInventoryItem}/${id}`);
    }

    render() {
        if (!!this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />)

        let { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="mr-2 text-muted" />
                    <span>Inventory Items</span>
                </PageHeading>
                <DataGridToolbar
                    entity="quickBooksInventoryItem"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddItem}
                    addLabel="Add Inventory Item"
                    hideExcelButton={true}
                    serverExport={{ apiPath: ApiRoutes.quickbooks.excelExport(), filePrefix: 'BillingSearch' }}
                />
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(QuickBooksInventoryItemsIndex);