export const BillingStatus = {
    Pending: 1,
    Approved: 2,
    Rejected: 3,
    Invoiced: 4,
    Processed: 5,
    ReadyToInvoice: 6,
    Voided: 7,
    OnHold: 8,
    PendingReview: 9,
    IssueReject: 10
};

export const BillingFlags = {
    OTJobStartRange: 'OT Job Start Range',
    BillNotEqualToCharge: 'Bill is not equal to Charge',
    MultipleJobs: 'Multiple jobs same day',
    ChargeTypeThresholdExceeded: 'Charge type recommended hours exceeded',
    BreakThresholdExceeded: 'Break recommended hours exceeded',
    RegOutOfOrder: 'Charge is out of order',
    OTOutOfOrder: 'OT Charge is out of order'
}