import { v4 as uuid } from 'uuid';
import { BillingRate } from '../billing/BillingRate';

export const ScheduleTypes = [
    { id: 1, name: '5/8' },
    { id: 2, name: '4/10' },
];

export const LinkedWorkOrders = [
    { value: 1, label: 'Yes' },
    { value: 2, label: 'No' },
];

export const WorkOrderNumberOptions = [
    { value: 1, label: 'Required' },
    { value: 2, label: 'Optional' },
    { value: 3, label: 'N/A' },
];

export class ContractChargeTypeBillingRate {
    contractId = null;
    chargeTypeId = null;
    chargeTypeName = '';
    billingRateId = uuid();
    billingRate = new BillingRate();
}

export const ChargeTypeEligbility = {
    Required: 1,
    Optional: 2,
    DoNotUse: 3,
};

export const ChargeTypeEligibilities = [
    { name: 'Required', id: 1 },
    { name: 'Optional', id: 2 },
    { name: 'Do Not Use', id: 3 },
];

export const ContractStatus = [
    { id: 1, description: 'Active' },
    { id: 2, description: 'Inactive' },
];

export const _ContractStatuses = {
    Active: 1,
    Inactive: 2,
};

export class Contract {
    id = null;
    companyId = '';
    companyName = '';
    operationsCenterCompanyId = '';
    operationsCenterCompanyName = '';
    number = '';
    description = '';
    contractStatusId = 1;
    contractStatusName = 'Active';
    contractScheduleTypeId = '';
    contractScheduleTypeName = '';
    allowSelfDispatch = false;
    enableNonFlaggingTimesheets = false;
    requireSelfDispatchTimesheetsClientSignature = false;
    allowLinkedWorkOrders = false;
    individualCharge = false;
    workOrderTypeId = '';
    workOrderTypeName = '';
    useAlternativePayroll = false;
    timesheetResponsibilityTypeId = null;
    jobs = [];
    dispatchLocations = [];
    contractLocations = [];
    contractChargeTypes = [];
    userCompliances = [];
    equipmentCompliances = [];
    contractChargeTypeGroups = [];
    effectiveDate = '';
    expirationDate = '';
    warningThresholdInDays = 0;
    autoEscalatingRates = false;
    prevailingWage = false;
}
