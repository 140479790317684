import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { SmallButton } from '../common/forms/FormElements';
import CommonContext from '../Common';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { util } from '../Util';


export class MinimumWageRateGrid extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showForm: false
        }
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        this.setState({
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (<CircularProgress color="secondary" variant="indeterminate" />)
        } else {

            let { minWageRates } = this.props;

            return (
                <Container fluid>
                    <Row className="pb-1">
                        <Col className="ml-0 mr-0">
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <CommonContext.Consumer>
                                    {(value) => {
                                        return (
                                            <SmallButton
                                                type="button"
                                                disabled={!!value.formIsOpen}
                                                onClick={this.props.onAddMinWageRate}
                                            >
                                                <i className="fa fa-plus-circle fa-md mr-2" />Add Min. Wage Rate
                                            </SmallButton>);
                                    }}
                                </CommonContext.Consumer>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-0 mr-0">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr>                                       
                                        <th style={{ width: '15%' }}>Min. Wage Rate</th>                                        
                                        <th style={{ width: '20%' }}>Effective Date</th>
                                        <th style={{ width: '20%' }}>Expiration Date</th>
                                        <th className="text-center" style={{ width: '5%' }}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!(minWageRates ?? []).length
                                        ? <tr><td colSpan="9" className="text-center">No rates found.</td></tr>
                                        : this.props.minWageRates.map((rate, i) => {
                                            return (
                                                <tr key={rate.id}>                                                 
                                                    <td>
                                                        {!util.isEmpty(rate.payRate) && util.number.formatCurrency(rate.payRate)}
                                                    </td>                                                    
                                                    <td>
                                                        {!util.isEmpty(rate.startDate) && util.date.getShortUTC(rate.startDate)}
                                                    </td>
                                                    <td>
                                                        {!util.isEmpty(rate.endDate) && util.date.getShortUTC(rate.endDate)}
                                                    </td>
                                                    <td className="text-center" style={{ width: '30px' }}>
                                                        <CommonContext.Consumer>
                                                            {(value) => {
                                                                return (<Button
                                                                    disabled={!!value.formIsOpen}
                                                                    type="button"
                                                                    color="outline-primary"
                                                                    onClick={() => this.props.onEditMinWageRate(rate)}
                                                                    size="sm">Edit</Button>);
                                                            }}
                                                        </CommonContext.Consumer>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            );
        }
    }
}