import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';

import CommonContext, { ApiRoutes } from '../Common';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    SmallButton,
    SimpleTable,
    FormCheckbox,
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
} from '../common/forms/FormElements';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import InvoiceSlideout from '../invoice/InvoiceSlideout';
import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';
import SlideForm from '../common/forms/SlideForm';
import BillingIssuesWidget from '../widgets/BillingIssuesWidget';

class BillingIssuesIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();
        this.invoiceSlideoutRef = React.createRef();
        this.billingIssuesSlideoutRef = React.createRef();

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            timesheetDetailsOpen: false,
            invoiceSlideoutOpen: false,
            selectedDetails: [],
            showRejectTimesheetModal: false,
            showRejectBillingTimesheetModal: false,
            showMoveTimesheetModal: false,
            isMovingTimesheet: false,
            isRejectingTimesheet: false,
            isRejectingBillingTimesheet: false,
            adminRejecting: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            yesNo: yesNo,
            isApproving: false,
            isInvoicing: false,
            createJob: false,
            showBillingIssuesSlideout: false,
            billingIssueMessages: [],
            invoiceTimesheetIds: [],
            currentTimesheetIndex: 0
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    populateState = async () => {
        let [dispatchLocations, billingStatuses] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.billingStatuses()),
        ]);

        let initialListFilters = {
            initialDispatchId: '',
            initialCustomerCompanyId: 0,
            initialOperationsCenterId: 0,
            initialBillingStatusId: 10,
            initialPayrollStatusId: 0,
            initialHasOverride: null,
        };

        billingStatuses = billingStatuses.filter(
            (x) => x.value == 10 || x.value == 9
        );

        billingStatuses.push({ label: 'Accepted', value: -99 });

        const gridOptions = createGridOptions(this);
        let context = this;

        //https://www.ag-grid.com/documentation/javascript/row-selections#checkbox-selection
        //One column must have "checkboxSelection" set to true.
        //Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'single';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {

        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        let dispatchFilterParams = {
                suppressFilterButton: true,
                options: dispatchLocations,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.dispatchId,
        }

        let billingStatusFilterParams = {
                suppressFilterButton: true,
                options: billingStatuses,
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: initialListFilters.initialBillingStatusId,
            }

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                //floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                //}
            },
            {
                colId: 'customerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'ContractNumber', //leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                headerName: 'Contract #',
                field: 'contractNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'DispatchLocationId',
                headerName: 'Dispatching',
                field: 'dispatchOfficeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'Invoice.InvoiceNumber',
                headerName: 'Invoice',
                field: 'invoiceNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Timesheet.TimesheetNumber',
                headerName: 'Timesheets',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Timesheet.WorkOrderNumber',
                headerName: 'Work Order',
                field: 'workOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'BillingStatusId',
                headerName: 'Status',
                field: 'billingStatus',
                sortable: true,
                flex: 1,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
            },
            {
                colId: 'Id',
                flex: 2,
                maxWidth: 150,
                headerName: 'Billing Issue',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        return (
                            <SmallButton
                                title="Billing Issue"
                                type="button"
                                onClick={() => {
                                    this.onBillingIssuesSlideout(params.data.timesheetId)
                                }}
                            >
                                <i className={`fa fa-comments fa-md mr-2`} />
                            </SmallButton>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 2,
                maxWidth: 150,
                headerName: 'View Timesheets',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        return (
                            <SmallButton
                                title="View Timesheet"
                                type="button"
                                onClick={() => {
                                    this.openTimesheetDetails(params.data)
                                }}
                            >
                                <i className={`fa fa-eye fa-md mr-2`} />
                            </SmallButton>
                        );
                    }

                    return null;
                },
            }
        ];

        //////////////////////////////////////////////////////////
        // RJP - Cache and reload filter and sort changes
        gridOptions.onFilterChanged = (params) => {

        };

        gridOptions.onSortChanged = (params) => {

        };

        gridOptions.onFirstDataRendered = (params) => {

        };

        gridOptions.onGridReady = (params) => {
            params.api.setDatasource(context.state.dataSource);
            context.setState({ gridApi: params.api });
        };

        // END - Cache and reload filter and sort changes
        /////////////////////////////////////////////////////////

        const dataSource = createDataSource(
            ApiRoutes.billingIssues.search(),
            gridOptions
        );

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource,
            isRejectingTimesheet: false,
        });
    };

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs,
        });
    }

    onChange = onFieldChange;
    handleSaveError = (err) => handleFormSaveError(this, err);

    async toggleExportModal() {
        const { showExportModal } = this.state;
        this.setState({ showExportModal: !showExportModal });
    }

    onCellClicked = (data) => {
        //test
        //alert(data);
    };

    onRowClicked = (event) => {
        //let selection = event.api.getSelectedRows();
        //let row = !!selection.length ? selection[0] : null;

        //if (row) {
        //    this.setState({ selectedRow: row });
        //    this.showBillingIssuesSlideout(row.id);
        //}
    };

    openTimesheetDetails = async (row) => {
        if (!!row) {

            if (row.invoiceId != null) {

                var ids = []
                row.invoiceTimesheetIds.split(",").map(function (item) {
                    if (item.trim() != '') { ids.push(item.trim()) }
                })

                this.setState({ invoiceTimesheetIds: ids });

                await this.context.setFormOpened(true);
                this.timesheetDetailsRef.current.open(ids[0]);

                return;
                
            }

            this.setState({ timesheetList: [], currentTimesheetIndex: 0 });

            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(row.timesheetId);
        }
    };

    onNavForward = async () => {
        let { invoiceTimesheetIds, currentTimesheetIndex } = this.state;

        if (!!invoiceTimesheetIds && invoiceTimesheetIds.length > 0) {
            await this.context.setFormOpened(true);

            if (currentTimesheetIndex + 1 >= invoiceTimesheetIds.length)
                return;

            currentTimesheetIndex = currentTimesheetIndex + 1;

            var timesheetId = invoiceTimesheetIds[currentTimesheetIndex];
            this.timesheetDetailsRef.current.open(timesheetId);

            this.setState({ currentTimesheetIndex: currentTimesheetIndex });
        }
    };

    onNavBack = async () => {
        let { invoiceTimesheetIds, currentTimesheetIndex } = this.state;

        if (currentTimesheetIndex == 0)
            return;

        if (!!invoiceTimesheetIds && invoiceTimesheetIds.length > 0) {
            await this.context.setFormOpened(true);

            currentTimesheetIndex = currentTimesheetIndex - 1;
            if (currentTimesheetIndex < 0)
                currentTimesheetIndex = 0;

            var timesheetId = invoiceTimesheetIds[currentTimesheetIndex];
            this.timesheetDetailsRef.current.open(timesheetId);

            this.setState({ currentTimesheetIndex: currentTimesheetIndex });
        }
    };

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    onBillingIssuesSlideout = async (id) => {
        
        var messages = await util.fetch.js(ApiRoutes.billingIssues.messages(id));

        this.setState({ billingIssueMessages: messages, showBillingIssuesSlideout: true });
        this.billingIssuesSlideoutRef.current.open();
    };

    onReplyToBillingIssues = async (id, message, updateStatus) => {

        if (message == null || message.length == 0) {
            toasty.error('Please supply reply notes');
            return;
        }

        var model = {
            timesheetId: id,
            notes: message,
            updateStatus: updateStatus
        };

        await util.fetch.post(
            ApiRoutes.billingIssues.reply(),
            model
        );

        toasty.success('Billing issue reply successful');

        this.setState({
            showBillingIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    }


    renderTimesheetByjobList = () => {

        let { allTimesheetsByJob } = this.state;

        var groupContainsInvoice = allTimesheetsByJob.filter((e) => e.invoiceStatusId > 0).length > 0;
        var groupMixSelected = allTimesheetsByJob.filter((e) => e.convert == false).length > 0;

        return allTimesheetsByJob.map((timesheet, timesheetIndex) => {

            var tsHasInvoice = timesheet.invoiceStatusId > 0;

            return (
                <tr key={allTimesheetsByJob}>
                    <td>
                        {tsHasInvoice &&
                            <FormCheckbox
                                id={timesheet.id}
                                checked={false}
                                labelText={timesheet.timesheetNumber}
                                disabled={true}
                            />
                        }
                        {!tsHasInvoice &&
                            <FormCheckbox
                                id={timesheet.id}
                                checked={timesheet.convert}
                                onChange={(e) => {
                                    let { allTimesheetsByJob } = this.state;
                                    timesheet.convert = e.target.checked;
                                    this.setState({ allTimesheetsByJob: allTimesheetsByJob });
                                }}
                                labelText={timesheet.timesheetNumber}
                            />
                        }
                    </td>
                    <td>
                        {timesheet.convert &&
                            <>
                            {!tsHasInvoice && (groupContainsInvoice || groupMixSelected)  &&
                                <span>
                                    <ul style={{paddingLeft: '18px', marginBottom: '0px'}}>
                                        <li>New job</li>
                                        <li>New timesheet number</li>
                                    </ul>                 
                                </span>
                            }
                            {!tsHasInvoice && !groupContainsInvoice && !groupMixSelected &&
                                <span>
                                    <ul style={{ paddingLeft: '18px', marginBottom: '0px' }}>
                                        <li>Same job</li>
                                        <li>New timesheet number</li>
                                    </ul>
                                </span>
                            }
                            </>
                        }
                        {!timesheet.convert &&
                            <>
                                {tsHasInvoice &&
                                    <span>(unavailable)</span>
                                }
                                {!tsHasInvoice &&
                                    <span>(none)</span>
                                }
                            </>
                        }
                    </td>
                    <td>
                        {timesheet.timesheetStatus}
                    </td>
                    <td>
                        {timesheet.billingStatus}
                    </td>
                    <td>
                        {timesheet.invoiceStatus}
                    </td>
                </tr>
            )
        });
    }

    render() {
        const {
            rowData,
            gridOptions,
            showRejectTimesheetModal,
            showRejectBillingTimesheetModal,
            showMoveTimesheetModal,
            isRejectingTimesheet,
            isRejectingBillingTimesheet,
            isMovingTimesheet,
            isInvoicing,
            selectedRowRejectionNotes,
            rowsSelected,
            isExporting,
            showExportModal,
            adminRejecting,
            contracts,
            allTimesheetsByJob,
            selectedContract,
            selectedRow,
            billingIssueMessages,
            invoice
        } = this.state;

        //TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings)
                        return (
                            <LinearProgress
                                variant="indeterminate"
                                color="secondary"
                            />
                        );

                    if (
                        !tenantSettings.billingEnabled ||
                        !tenantSettings.invoicingEnabled
                    )
                        return <NotAuthorizedPage />;

                    const isAdmin = this.context?.user?.isAdmin;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faComments}
                                    className="mr-2 text-muted"
                                />
                                <span>Billing Issues</span>
                                
                            </PageHeading>

                            <DataGridToolbar
                                entity="BillingIssues"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd={true}
                                hideExcelButton={true}
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath:
                                        ApiRoutes.billingIssues.excelExport(),
                                    filePrefix: 'BillingIssues',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving ||
                                    !!this.state.loading ||
                                    !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <>
                                    <DataGrid
                                        domLayout={'normal'}
                                        rowData={rowData}
                                        gridOptions={gridOptions}
                                        gridStatus={this.state.gridStatus}
                                    />
                                </>
                            )}
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) =>
                                    this.setState({
                                        timesheetDetailsOpen: open,
                                    })
                                }
                                onClose={this.onTimesheetDetailsClosed}
                                onAccept={this.onAcceptTimesheet}
                                onReject={this.onRejectTimesheet}
                                onlyBillable={true}
                                isBilling={true}
                                showNav={true}
                                onNavBack={this.onNavBack}
                                onNavForward={this.onNavForward}
                            />
                            <InvoiceSlideout
                                ref={this.invoiceSlideoutRef}
                                show={this.state.invoiceSlideoutOpen}
                                toggleShow={(open) =>
                                    this.setState({ invoiceSlideoutOpen: open })
                                }
                                onClose={this.onInvoiceSlideoutClosed}
                            />
                            <Modal
                                isOpen={showRejectTimesheetModal}
                                toggle={this.toggleRejectTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectTimesheetModal}
                                >
                                    Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                                        This administrative action will delete
                                        any approved payroll or billing records,
                                        and reset the timesheet. Please take
                                        note of Payroll and Billing
                                        implications.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required={true}
                                            text="Notes"
                                        ></FormLabel>
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                                            Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingTimesheet}
                                        onClick={this.rejectTimesheet}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showRejectBillingTimesheetModal}
                                toggle={this.toggleRejectBillingTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={
                                        this.toggleRejectBillingTimesheetModal
                                    }
                                >
                                    Reject Timesheet To Billing: Charges
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                                        This action will reset all charges to
                                        associated with these timesheets back to
                                        Billing: Charges.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required={true}
                                            text="Notes"
                                        ></FormLabel>
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                                            Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingBillingTimesheet}
                                        onClick={this.rejectBillingTimesheet}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                                    Export Billing
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                                        Export will be generated based upon
                                        search criteria entered on the billing
                                        screen. If the result set is too large
                                        the export may timeout.
                                    </p>
                                    {/*<FormGroup>*/}
                                    {/*    <label>Dispatching</label>*/}
                                    {/*    <Select*/}
                                    {/*        placeholder={'Select Dispatch Location'}*/}
                                    {/*        id="selectedDispatchLocation"*/}
                                    {/*        name="selectedDispatchLocation"*/}
                                    {/*        styles={CompactSelectStyles}*/}
                                    {/*        isClearable={false}*/}
                                    {/*        className="react-select"*/}
                                    {/*        options={dispatchLocations}*/}
                                    {/*        value={(dispatchLocations ?? []).find(x => x.value === selectedDispatchLocation) ?? ''}*/}
                                    {/*        onChange={this.onSelectedDispatchLocationChanged}*/}
                                    {/*    />*/}
                                    {/*</FormGroup>*/}
                                    {/*<FormGroup>*/}
                                    {/*    <label>Week Of</label>*/}
                                    {/*    <input*/}
                                    {/*        id="weekOf"*/}
                                    {/*        name="selectedWeekOf"*/}
                                    {/*        className="form-control"*/}
                                    {/*        defaultValue={selectedWeekOf ?? ''}*/}
                                    {/*        onChange={this.onChange}*/}
                                    {/*        type="date"*/}
                                    {/*    />*/}
                                    {/*</FormGroup>*/}
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => this.onExportClick()}
                                    >
                                        Ok
                                    </Button>{' '}
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({
                                                showExportModal: false,
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal
                                size="lg"
                                isOpen={showMoveTimesheetModal}
                                toggle={this.toggleMovingTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleMovingTimesheetModal}
                                >
                                    Move Timesheets to Contract / OPS Center
                                </ModalHeader>
                                <ModalBody>

                                    <FormGroup>
                                        <label>Job Timesheets</label>

                                        <SimpleTable
                                            className="mb-0"
                                            id={'timesheetJobsTable'}
                                            noTopBorder={true}
                                            permission={'contract'}
                                            tableHeaderLabels={[
                                                {
                                                    name: 'Timesheet Number'
                                                },
                                                {
                                                    name: 'Action(s)'
                                                },
                                                {
                                                    name: 'Timesheet Status'
                                                },
                                                {
                                                    name: 'Billing Status '
                                                },
                                                {
                                                    name: 'Invoice Status '
                                                }

                                            ]}
                                            editable={false}
                                            entities={allTimesheetsByJob}
                                            rowRenderer={
                                                this.renderTimesheetByjobList
                                            }
                                            noDataText={'No timesheets found.'}
                                        />
                                    </FormGroup>
                                    
                                    <FormGroup style={{ marginTop: '15px' }}>
                                        <label>Contract / OPS Center</label>
                                        <Select
                                            placeholder={'Select Contract'}
                                            id="selectedContract"
                                            name="selectedContract"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            options={contracts}
                                            value={(contracts ?? []).find(x => x.value === selectedContract) ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.value;
                                                this.setState({ selectedContract: value });
                                            }}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isMovingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={selectedContract == null || selectedContract == 0 || isMovingTimesheet || allTimesheetsByJob.filter(x => x.convert == true).length == 0}
                                        onClick={this.moveTimesheets}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>

                            <SlideForm
                                loading={false}
                                show={this.state.showBillingIssuesSlideout}
                                id="billingIssuesSlideout"
                                formIcon={faComments}
                                formTitle="Billing Issues"
                                onClose={() => {
                                    this.setState({
                                        showBillingIssuesSlideout: false,
                                    });
                                }}
                            >
                                <BillingIssuesWidget
                                    ref={this.billingIssuesSlideoutRef}
                                    billing={selectedRow}
                                    messages={billingIssueMessages}
                                    context={'replying'}
                                    onReplyCallback={(id, message, updateStatus) => {
                                        this.onReplyToBillingIssues(id, message, updateStatus);
                                    }}
                                    onCloseCallback={(id, message) => {
                                        this.setState({
                                            showBillingIssuesSlideout: false,
                                        });
                                    }}
                                >
                                </BillingIssuesWidget>
                            </SlideForm>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(BillingIssuesIndex);
