

export class EquipmentType {
    id = null;
    companyId = null;
    description = '';
    groups = [];    
    employeeAssignableQuantity = null;
    resourceAssignableQuantity = null;
    requiredEquipment = [];
    equipmentTypeAFADTypeId = 1;
}

export class Equipment {
    id = null;
    description = '';
    equipmentTypeId = '';
    identifier = '';
    description = '';
    equipmentStatusId = 1;
    employeeAssignedId = '';
    equipmentAssignedId = '';
    dispatchLocationId = '';
}